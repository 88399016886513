import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";
import nl from "date-fns/locale/nl-BE";

export default function HandleLocale(locale: string) {
	switch (locale) {
		case "nl":
			return nl;
		case "fr":
			return fr;
		default:
			return en;
	}
}
