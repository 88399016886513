import React from "react";
import { useTranslation } from "react-i18next";

interface VisioInfoProps {
	setMoreInfo: Function;
}

export default function VisioInfo({ setMoreInfo }: VisioInfoProps) {
	const { t } = useTranslation("common");

	return (
		<>
			<h4>{t("common.__toKnow__")} :</h4>
			<p>{t("link.__howToLiveMeetingStep2__")}</p>
			<div className="center">
				<br />
				<img src="/img/tuto/msg-vsio.png" alt="bouton pour démarrer la visio" />
			</div>

			<br />

			<h4>{t("link.__howToStartLiveMeeting__")}</h4>
			<ul className="visio-rules">
				<li>
					{t("link.__howToStartLiveMeetingAnswer__")}
					<div className="center">
						<img src="/img/tuto/bouton-visio.png" alt="bouton pour démarrer la visio" />
					</div>
				</li>
				<li>{t("link.__howToStartLiveMeetingWarning__")}</li>
			</ul>
			<br />

			<h4>{t("link.__howToHandleLiveMeeting__")}</h4>
			<ul className="visio-rules">
				<li>{t("link.__howToHandleLiveMeetingAnswer__")}</li>
				<li>{t("link.__howToHandleLiveMeetingWarning__")}</li>
				<li>{t("link.__howToHandleLiveMeetingAnswer2__")}</li>
			</ul>

			<div className="center">
				<br />
				<button type="button" className="dark-button" onClick={() => setMoreInfo(false)}>
					{t("mailbox.__askForVisio__")}
				</button>
			</div>
		</>
	);
}
