import React, { useState } from "react";
import { BsImage } from "react-icons/bs";
import * as API from "../../Services/API";
import { PostcardModal } from "./PostcardModal";
import { useHistory } from "react-router-dom";

export default function ButtonSendToPhotoframe({ canvasRef, seniorSelectedId, modal, setError }) {
	const [show, setShow] = useState(false);
	const history = useHistory();

	const sendToPhotoFrame = () => {
		setError(false);
		const canvas = canvasRef.current;
		const formData = new FormData();
		if (canvas.toBlob) {
			canvas.toBlob(function (blob) {
				const postcard = [new File([blob], "postcard.png", { type: "image/png" })];

				for (let i = 0; i < postcard.length; i++) {
					formData.append("items[]", postcard[i]);
				}

				API.sendPictures(
					seniorSelectedId,
					formData,
					() => {
						modal ? history.push("/profile/3") : setShow(true);
					},
					() => {
						setError(true);
					}
				);
			});
		}
	};

	return (
		<>
			<button className="dark-button" type="button" onClick={() => sendToPhotoFrame()}>
				<BsImage style={{ marginRight: "5px", verticalAlign: "middle" }} /> Cadre Photo
			</button>
			<PostcardModal
				show={show}
				setShow={setShow}
				format={"photoFrame"}
				seniorSelectedId={seniorSelectedId}
				canvasRef={canvasRef}
				setError={setError}
			/>
		</>
	);
}
