import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import {
	BsTabletLandscape,
	BsChatDots,
	BsCamera,
	BsVolumeUpFill,
	BsArrowRightCircle,
	BsChatDotsFill,
	BsCheck
} from "react-icons/bs";
import { MediasInterface } from "../../../interfaces/PostInterface";
import { formatedDateFromString } from "../../../utils/Datetime";

interface MessagesListProps {
	userDispatch: Function;
	seniorSelectedId: number;
	message: MessageInterface;
	userLocale: string;
}

interface MessageInterface {
	id: number;
	subject: string;
	unread: boolean;
	medias: MediasInterface[];
	read_at: string;
	message: string;
	type: number;
	created_at: string;
}

export default function MessageContainer({ userDispatch, message, seniorSelectedId, userLocale }: MessagesListProps) {
	const { t } = useTranslation("common");
	const history = useHistory();

	const handleMessageDetail = (id: number, unread: boolean) => {
		if (unread) {
			userDispatch({
				type: "READ_SENIOR_MESSAGE",
				payload: seniorSelectedId
			});
		}
		history.push(`/profile/7/${id}`);
	};

	const handleMediasMeta = (medias: MediasInterface[]) => {
		if (medias.length > 0) {
			const audioFiles = medias.filter(media => media.content_type.split("/")[0] === "audio");
			const images = medias.filter(media => media.content_type.split("/")[0] === "image");
			return (
				<>
					<span className="meta mr-2">
						<BsCamera color="#828282" className="nav-icon" /> {images.length}
					</span>
					<span className="meta mr-2">
						<BsVolumeUpFill color="#828282" className="nav-icon" /> {audioFiles.length}
					</span>
				</>
			);
		} else {
			return (
				<>
					<span className="meta mr-2">
						<BsCamera color="#828282" className="nav-icon" /> 0
					</span>
					<span className="meta mr-2">
						<BsVolumeUpFill color="#828282" className="nav-icon" /> 0
					</span>
				</>
			);
		}
	};

	const displayMedias = (medias: MediasInterface[]) => {
		const audio = medias.filter(media => media.content_type === "audio/mpeg");
		const images = medias.filter(media => media.content_type.split("/")[0] === "image");

		return (
			<div className="mt-5">
				<p className="meta mb-5">{t("link.__filesJoined__")} :</p>
				{audio.length > 0
					? audio.map((mp3: { id: number }) => (
							<div key={mp3.id}>
								<audio controls src={`/storages/seniors/messages/medias/${mp3.id}`} />
							</div>
					  ))
					: null}
				{images.length > 0
					? images.map(img => (
							<div className="preview-post" key={img.id}>
								<img src={`/storages/seniors/messages/medias/${img.id}`} className="preview-pict" />
							</div>
					  ))
					: null}
			</div>
		);
	};

	return (
		<>
			{message.type === 1 ? (
				<div className={message.unread ? "unread-container" : "read-container"}>
					<div className="light-card">
						<div className="space-between">
							<Link to={`/profile/7/${message.id}`}>
								<p className={message.unread ? "subject fake-link red" : "subject fake-link blue"} role="button">
									{message.unread ? (
										<BsChatDotsFill color="#fe7062" className="link-message-icon" style={{ marginRight: "0.7rem" }} />
									) : (
										<BsChatDots className="link-message-icon" style={{ marginRight: "0.7rem" }} />
									)}
									<b>{message.subject}</b>
								</p>
							</Link>
							<div className="flow-arrow">
								<BsArrowRightCircle
									className="read-more-icon"
									onClick={() => handleMessageDetail(message.id, message.unread)}
									role="button"
								/>
							</div>
						</div>
						<div className="mt-2 mb-5">
							{handleMediasMeta(message.medias)}
							<span className="meta mr-2">
								<BsCheck color="#828282" className="nav-icon" />
								{t("mailbox.__sentOn__")} {formatedDateFromString(message.created_at, "Pp", userLocale)}
							</span>
							{message.read_at ? (
								<span className="meta mr-2">
									<BsTabletLandscape color="#828282" className="nav-icon" /> {t("link.__readOn__")}{" "}
									{formatedDateFromString(message.read_at, "Pp", userLocale)}
								</span>
							) : (
								<span className="meta">
									<BsTabletLandscape color="#828282" className="nav-icon" /> <i>{t("link.__unreadMessage__")}</i>
								</span>
							)}
						</div>
						<p style={{ marginTop: "1rem" }}>
							<b>{t("common.__message__")} : </b>
							<br />
							{message.message}
							{/* {message.message.length > 50 ? (
								<i>
									{message.message.substring(0, 49)}
									<b className="fake-link blue" role="button" onClick={() => handleMessageDetail(message.id, message.unread)}>
										{" "}
										[{t("link.__moreThread__")}]
									</b>
								</i>
							) : (
								<i>{message.message}</i>
							)} */}
						</p>
						{message.medias.length > 0 ? displayMedias(message.medias) : null}
					</div>
				</div>
			) : null}
		</>
	);
}
