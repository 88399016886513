import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Container } from "react-grid-system";
import { BsXLg } from "react-icons/bs";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import MyInputPassword from "../../utils/form/MyInputPassword";
import PasswordControl from "./PasswordControl";
import ErrorContainer from "../../Layout/ErrorContainer";
import ForgotPassword from "./ForgotPassword";

interface FormValues {
	token: string;
	password: string;
	confirmPassword: string;
}

const NewPassword = () => {
	const { t } = useTranslation("common");
	const { token } = useParams<{ token?: string }>();
	const methods = useForm<FormValues>();
	const { handleSubmit, watch } = methods;
	const [httpResponse, setHttpResponse] = useState<{ error: boolean; msg: any } | null>(null);
	const [confirmPasswordError, setConfirmPasswordError] = useState(false);
	const password = useRef({});
	password.current = watch("password", "");
	const [passwordSaved, setPasswordSaved] = useState(false);
	const [displayNewPassword, setDisplayNewPassword] = useState(true);

	useEffect(() => {
		if (token) {
			http
				.post(`${url.BASEURL}/lost_password/token/validity`, { token: token })
				.then(res => setDisplayNewPassword(res.data.is_valid))
				.catch(() => setDisplayNewPassword(false));
		}
	}, [token]);

	const onSubmit = (values: FormValues) => {
		setHttpResponse(null);
		setConfirmPasswordError(false);
		if (values.password !== values.confirmPassword) {
			setConfirmPasswordError(true);
		} else {
			const data = {
				token: token,
				password: values.password,
				confirm_password: values.confirmPassword
			};
			http
				.post(`${url.BASEURL}/lost_password/recovery`, data)
				.then(() => setPasswordSaved(true))
				.catch(() => setHttpResponse({ error: true, msg: null }));
		}
	};

	return (
		<Container fluid>
			<div className="center">
				{process.env.REACT_APP_EFORLINK_LOGO === "true" ? (
					<Link to="/">
						<img src="/img/Logo-eforlink.png" alt="Logo eforlink" className="home-logo" />
					</Link>
				) : (
					<Link to="/">
						<img src="/img/amd/amdlink.png" alt="Logo AMD link" className="home-logo" />
					</Link>
				)}
				<div id="newpassword-module">
					<h1>{t("home.__slogan__")}</h1>
				</div>
			</div>
			<section id="password-form">
				<h3 className="center">{t("user.__newPassword__")}</h3>
				<ErrorContainer
					alert={t("user.__newPasswordError__")}
					success={t("user.__updateUserAccountSuccess__")}
					httpResponse={httpResponse}
					setHttpResponse={setHttpResponse}
				/>
				{passwordSaved ? (
					<div className="story-card center">
						<b>{t("user.__newPasswordSuccess__")}</b>
						<br />
						<Link to="/">
							<button type="button" className="dark-button">
								{t("home.__connexion__")}
							</button>
						</Link>
					</div>
				) : displayNewPassword ? (
					<FormProvider {...methods}>
						<form onSubmit={handleSubmit(onSubmit)}>
							{confirmPasswordError ? (
								<p className="alert">
									<span className="right error close-btn" onClick={() => setConfirmPasswordError(false)}>
										<BsXLg style={{ verticalAlign: "middle" }} />
									</span>
									<span style={{ paddingLeft: "0.7rem" }}>{t("user.__doublePasswordError__")}</span>
								</p>
							) : null}
							<PasswordControl />
							<br />
							<MyInputPassword label={t("user.__confirmPassword__")} name="confirmPassword" behavior={{ required: true }} />

							<br />
							<button type="submit" className="home-button">
								{t("common.__save__")}
							</button>
						</form>
					</FormProvider>
				) : (
					<section id="login-form">
						<p className="meta">Ce lien n'est plus fonctionnel.</p>
						<p className="mt-2">{t("user.__newPasswordTokenWarning__")}</p>
						<ForgotPassword displayTitle={false} />
					</section>
				)}
			</section>
		</Container>
	);
};

export default NewPassword;
