import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { Row, Col, Container } from "react-grid-system";
import http from "../Services/Interceptor";
import * as url from "../Constants/Url";
import MyInputPassword from "../utils/form/MyInputPassword";
import { useParams, Link } from "react-router-dom";
import PasswordControl from "../components/UserSettings/PasswordControl";
import ForgotPassword from "../components/UserSettings/ForgotPassword";
import Footer from "../Layout/Footer";

interface FormValues {
	password: string;
	confirmPassword: string;
}

const Welcome = () => {
	const { t } = useTranslation("common");
	const { token } = useParams<{ token?: string }>();
	const methods = useForm<FormValues>();
	const { handleSubmit, watch } = methods;
	const [httpResponse, setHttpResponse] = useState({ error: false, success: false });
	const [displayNewPassword, setDisplayNewPassword] = useState(token ? true : false);
	const password = useRef({});
	password.current = watch("password", "");

	useEffect(() => {
		if (token) {
			http
				.post(`${url.BASEURL}/lost_password/token/validity`, { token: token })
				.then(res => setDisplayNewPassword(res.data.is_valid))
				.catch(() => setDisplayNewPassword(false));
		}
	}, [token]);

	const onSubmit = (values: FormValues) => {
		setHttpResponse({ error: false, success: false });
		if (values.password !== values.confirmPassword) {
			setHttpResponse({ error: true, success: false });
		} else {
			const data = {
				token: token,
				password: values.password,
				confirm_password: values.confirmPassword
			};
			http
				.post(`${url.BASEURL}/lost_password/recovery`, data)
				.then(() => setHttpResponse({ error: false, success: true }))
				.catch(() => setHttpResponse({ error: true, success: false }));
		}
	};

	return (
		<FormProvider {...methods}>
			<Container fluid>
				<div className="center">
					{process.env.REACT_APP_EFORLINK_LOGO === "true" ? (
						<Link to="/">
							<img src="/img/Logo-eforlink.png" alt="Logo eforlink" className="home-logo" />
						</Link>
					) : (
						<Link to="/">
							<img src="/img/amd/amdlink.png" alt="Logo AMD link" className="home-logo" />
						</Link>
					)}
					<div id="welcome-module">
						<h1 className="slogan">{t("user.__welcome__")}</h1>
						<div className="separator"></div>
					</div>
				</div>

				{displayNewPassword ? (
					<Row>
						<Col xs={0} sm={0} md={4} lg={6}>
							<div className="center">
								<img src="/img/welcome-e4-link.png" className="illustration" alt="welcome" />
							</div>
						</Col>
						<Col xs={12} sm={12} md={8} lg={6}>
							<section id="login-form">
								{httpResponse.error && <p className="alert">{t("user.__newPasswordError__")}</p>}

								{httpResponse.success ? (
									<p className="success">
										{t("user.__newPasswordSaved__")}
										<br />
										{t("user.__newPasswordSavedWarning__")}
									</p>
								) : (
									<form onSubmit={handleSubmit(onSubmit)}>
										<PasswordControl />

										<br />
										<MyInputPassword
											label={t("user.__confirmPassword__")}
											name="confirmPassword"
											behavior={{
												required: true,
												validate: (value: string) => value === password.current || "The passwords do not match"
											}}
										/>

										<br />
										<button type="submit" className="home-button">
											{t("common.__save__")}
										</button>
									</form>
								)}
							</section>
						</Col>
					</Row>
				) : (
					<section id="login-form">
						<p className="meta">Ce lien n'est plus fonctionnel.</p>
						<p className="mt-2">{t("user.__newPasswordTokenWarning__")}</p>
						<ForgotPassword displayTitle={false} />
					</section>
				)}
			</Container>
			<Footer />
		</FormProvider>
	);
};

export default Welcome;
