import React, { useState } from "react";
import { BsDropletHalf, BsTypeBold } from "react-icons/bs";

export default function ButtonColorPicker({ color, setColor, elt, size, setSize, displaySize }) {
	const [openColorsSelection, setOpenColorsSelection] = useState(false);

	const handleColor = (selectedColor, elt) => {
		if (elt === "title") {
			setColor({ ...color, title: selectedColor });
			setOpenColorsSelection(prevState => !prevState);
		} else if (elt === "signature") {
			setColor({ ...color, signature: selectedColor });
			setOpenColorsSelection(prevState => !prevState);
		} else if (elt === "messages") {
			setColor({ ...color, messages: selectedColor });
			setOpenColorsSelection(prevState => !prevState);
		} else if (elt === "background") {
			setColor({ ...color, background: selectedColor });
			setOpenColorsSelection(prevState => !prevState);
		} else {
			return;
		}
	};

	const handleSize = elt => {
		if (elt === "title") {
			if (size.title) {
				setSize({ ...size, title: null });
			} else {
				setSize({ ...size, title: "bold" });
			}
		} else if (elt === "messages") {
			if (size.messages) {
				setSize({ ...size, messages: null });
			} else {
				setSize({ ...size, messages: "bold" });
			}
		} else if (elt === "signature") {
			if (size.signature) {
				setSize({ ...size, signature: null });
			} else {
				setSize({ ...size, signature: "bold" });
			}
		} else {
			return;
		}
	};

	return (
		<div className="select-color">
			<div className="pick-color-button" onClick={() => setOpenColorsSelection(prevState => !prevState)}>
				<BsDropletHalf />
			</div>
			{openColorsSelection ? (
				<div className="color-selection">
					<div className="color-yellow fake-link" role="button" onClick={() => handleColor("#fdca40", elt)}></div>
					<div className="color-pink fake-link" role="button" onClick={() => handleColor("#ff6392", elt)}></div>
					<div className="color-purple fake-link" role="button" onClick={() => handleColor("#8367c7", elt)}></div>
					<div className="color-green fake-link" role="button" onClick={() => handleColor("#25ced1", elt)}></div>
					<div className="color-blue fake-link" role="button" onClick={() => handleColor("#1e96fc", elt)}></div>
					<div className="color-corail fake-link" role="button" onClick={() => handleColor("#f95738", elt)}></div>
					<div className="color-brown fake-link" role="button" onClick={() => handleColor("#9c6644", elt)}></div>
					<div className="color-grey fake-link" role="button" onClick={() => handleColor("#9e9e9e", elt)}></div>
					<div className="color-black fake-link" role="button" onClick={() => handleColor("#000000", elt)}></div>
				</div>
			) : null}
			{displaySize ? (
				<div className="font-button" onClick={() => handleSize(elt)}>
					<BsTypeBold />
				</div>
			) : null}
		</div>
	);
}
