import http from "./Interceptor";
import * as constant from "../Constants/Url";

// PHOTOBOX //
export const sendPictures = (seniorId, data, onSuccess, onResponse) => {
	http
		.post(`${constant.SENIORS}/${seniorId}/photo_frame_items`, data)
		.then(res => {
			onSuccess(res);
		})
		.catch(err => {
			onResponse(err);
		});
};

// MESSAGES TO SENIOR (tablette) //
export const sendMessageToSenior = (seniorId, data, onSuccess, onResponse) => {
	http
		.post(`${constant.SENIORS}/${seniorId}/messages/new`, data)
		.then(res => onSuccess(res))
		.catch(err => onResponse(err));
};
