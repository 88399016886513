import React, { useState, useEffect } from "react";
import ModalVisioRequest from "../components/Calendar/ModalVisioRequest";
import ModalEventDetails from "../components/Calendar/ModalEventDetails";
import "./CustomFamilyCalendar.scss";
import http from "../Services/Interceptor";
import * as url from "../Constants/Url";
import moment from "moment";

// FullCalendar
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import fr from "@fullcalendar/core/locales/fr";
import nl from "@fullcalendar/core/locales/nl";
import timeGridPlugin from "@fullcalendar/timegrid";

function getFullLocale(locale) {
	if (locale === "fr") {
		return fr;
	} else if (locale === "nl") {
		return nl;
	} else {
		return null;
	}
}

const VisioModal = ({ show, setShow, visioDate, locale, seniorSelectedId }) => {
	const content = show && (
		<div className="overlay">
			<div className="modal">
				<div className="modal-close" type="button" onClick={() => setShow(false)}>
					X
				</div>
				<div className="modal-body">
					<ModalVisioRequest
						show={show}
						setShow={setShow}
						visioDate={visioDate}
						locale={locale}
						seniorSelectedId={seniorSelectedId}
					/>
				</div>
			</div>
		</div>
	);
	return content;
};

const EventModal = ({ showEvent, setShowEvent, eventInfo, locale }) => {
	const content = showEvent && (
		<div className="overlay">
			<div className="modal">
				<div className="modal-close" type="button" onClick={() => setShowEvent(false)}>
					X
				</div>
				<div className="modal-body">
					<ModalEventDetails showEvent={showEvent} setShowEvent={setShowEvent} eventInfo={eventInfo} locale={locale} />
				</div>
			</div>
		</div>
	);
	return content;
};

function CustomFamilyCalendar({ locale, seniorSelectedId }) {
	const [show, setShow] = useState(false);
	const [showEvent, setShowEvent] = useState(false);
	const [visioDate, setVisioDate] = useState(null);
	const [events, setEvents] = useState([]);
	const [eventInfo, setEventInfo] = useState([]);

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [calendarError, setCalendarError] = useState(false);

	// When a date is selected -> Visio Request Form
	const handleDateSelect = selectInfo => {
		setVisioDate(selectInfo.startStr);
		setShow(true);
	};

	// When an event is clicked -> More details
	const eventClick = info => {
		setEventInfo(info);
		setShowEvent(true);
	};

	// fetch events from the date range displayed
	const handleDates = rangeInfo => {
		setStartDate(moment(rangeInfo.startStr).format("YYYY-MM-DDTHH:mm:ss"));
		setEndDate(moment(rangeInfo.endStr).format("YYYY-MM-DDTHH:mm:ss"));
	};

	const extendEventsList = eventsList => {
		const exEvents = eventsList.map(function (event) {
			return {
				...event,
				type: `${event.entry_type_id}`,
				title: `${event.info}`,
				start: `${event.begin_datetime}`,
				end: `${event.end_datetime}`,
				detail: `${event.detail}`,
				allDay: false
			};
		});
		setEvents(exEvents);
	};

	useEffect(() => {
		if (startDate && endDate) {
			setCalendarError(false);
			http
				.get(
					`${url.SENIORS}/${seniorSelectedId}/calendar_entries/from/${encodeURIComponent(startDate)}/to/${encodeURIComponent(
						endDate
					)}`
				)
				.then(res => extendEventsList(res.data.calendar_entries))
				.catch(() => setCalendarError(true));
		}
		// eslint-disable-next-line
	}, [seniorSelectedId, startDate, endDate]);

	function changeEventColor(info) {
		let type = parseInt(info);
		switch (type) {
			case 1:
				return "workshop-type";
			case 2:
				return "event-type";
			case 11:
				return "visio-type";
			default:
				return;
		}
	}

	const renderEventContent = eventInfo => {
		return (
			<div className={changeEventColor(eventInfo.event._def.extendedProps.type)}>
				{eventInfo.timeText}: <b>{eventInfo.event.title}</b>
			</div>
		);
	};

	return (
		<>
			{calendarError && <p className="alert">Les données ne sont pas accessibles pour le moment.</p>}

			<FullCalendar
				// configuration
				headerToolbar={{
					left: "dayGridMonth,timeGridWeek,timeGridDay",
					center: "title",
					right: "prev,next"
				}}
				handleWindowResize={true}
				plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
				timeGridPlugin={{
					dayMaxEvents: 3
				}}
				locale={getFullLocale(locale)}
				// Range of the dates displayed
				datesSet={handleDates}
				// events
				events={events}
				// Handle event custom dispay
				eventContent={renderEventContent}
				// initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed

				// user action handlers
				// dateClick={dateClick}
				eventClick={eventClick}
				// eventMouseEnter={eventMouseEnter}

				// Select date to create an event
				selectable={true}
				select={handleDateSelect}
				nowIndicator={true}
				dayMaxEvents={true}
				/* you can update a remote database when these fire:
        eventAdd={function(){}}
        eventChange={function(){}}
        eventRemove={function(){}}
        */
			/>

			<VisioModal show={show} setShow={setShow} visioDate={visioDate} locale={locale} seniorSelectedId={seniorSelectedId} />
			<EventModal showEvent={showEvent} setShowEvent={setShowEvent} eventInfo={eventInfo} locale={locale} />
		</>
	);
}

export default CustomFamilyCalendar;
