import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
	const { t } = useTranslation("common");

	return (
		<div className="footer-content">
			<p className="app-version">
				© e4-link v 2.0.4
				<a href={`mailto:${process.env.REACT_APP_SUPPORT}`}> - {t("footer.__techSupport__")}</a>
			</p>
		</div>
	);
}
