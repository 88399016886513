import React from "react";
import { useTranslation } from "react-i18next";

export default function QrCode() {
	const { t } = useTranslation("common");
	return (
		<div className="center">
			<h4>{t("user.__qrCode__")}</h4>
			<p>
				<strong>{t("user.__qrCodeWarning__")}</strong>
			</p>
			<img src="/api/family/v1/qrcode/generate/png" alt="QRcode" className="large-qrcode" />
		</div>
	);
}
