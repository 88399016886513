import React from "react";
import { useParams } from "react-router-dom";
import UserNav from "../components/UserSettings/UserNav";
import UserSettings from "../components/UserSettings/UserSettings";
import AddFamilyUser from "../components/UserSettings/AddFamilyUser";
import { UserStateInterface } from "../interfaces/UserStateInterface";
interface UserAccountProps {
	userDispatch: React.Dispatch<any>;
	error: boolean;
	userState: UserStateInterface;
}

export default function UserAccount({ userState, userDispatch, error }: UserAccountProps) {
	const { component } = useParams<{ component?: string }>();

	const handleComponent = () => {
		switch (component) {
			case "addaffiliate":
				return <AddFamilyUser userState={userState} />;
			default:
				return <UserSettings userState={userState} error={error} userDispatch={userDispatch} />;
		}
	};

	return (
		<div className="content-container">
			<UserNav userDispatch={userDispatch} />
			{handleComponent()}
		</div>
	);
}
