import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./PostCard.scss";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-grid-system";
import OnePictTemplate from "./OnePictTemplate";
import ThreePictsTemplate from "./ThreePictsTemplate";
import FourPictsTemplate from "./FourPictsTemplate";
import {UserStateInterface} from "../../interfaces/UserStateInterface";
import { BsXLg } from "react-icons/bs";

export default function PostCard({ userState }: { userState: UserStateInterface }) {
	const { t } = useTranslation("common");
	const seniorSelectedId = userState.seniorSelectedId;
	const [selectedTemplate, setSelectedTemplate] = useState(1);
	const [nextStep, setNextStep] = useState(false);
	const [error, setError] = useState(false);
	const history = useHistory();

	const handleTemplate = (selectedTemplate: number) => {
		switch (selectedTemplate) {
			case 1:
				return <OnePictTemplate seniorSelectedId={seniorSelectedId} setNextStep={setNextStep} setError={setError} />;
			case 2:
				return <ThreePictsTemplate seniorSelectedId={seniorSelectedId} setNextStep={setNextStep} setError={setError} />;
			case 3:
				return <FourPictsTemplate seniorSelectedId={seniorSelectedId} setNextStep={setNextStep} setError={setError} />;
			default:
				return null;
		}
	};

	return (
		<Row className="postcard-page">
			<Col xs={6} sm={6} md={6} lg={6}>
				<h2>{t("link.__postcard__")}</h2>
				<div className="underliner"></div>
				<div className="space"></div>
			</Col>
			<Col xs={6} sm={6} md={6} lg={6}>
				{nextStep ? (
					<button type="button" onClick={() => setNextStep(false)} className="light-button right">
						{t("common.__cancel__")}
					</button>
				) : (
					<button type="button" onClick={() => history.goBack()} className="light-button right">
						{t("common.__cancel__")}
					</button>
				)}
			</Col>

			{error ? (
				<Col xs={12} sm={12} md={12} lg={12}>
					<p className="alert">
						<span className="right error close-btn" onClick={() => setError(false)}>
							<BsXLg style={{ verticalAlign: "middle" }} />
						</span>
						{t("common.__errorOccured__")}
					</p>
				</Col>
			) : null}

			{nextStep ? (
				<Col xs={12} sm={12} md={12} lg={12}>
					{handleTemplate(selectedTemplate)}
				</Col>
			) : (
				<>
					<Col xs={12} sm={12} md={12} lg={12}>
						<div className="mood-card">
							<p>
								<b>{t("link.__postcardTitle__")}</b>
							</p>
							<p style={{ marginTop: "0.75rem" }}>{t("link.__postcardWarning__")}</p>
						</div>
						<div className="space"></div>
					</Col>
					<Col xs={1} sm={1} md={1} lg={1}>
						<p className="step-number">1</p>
					</Col>
					<Col xs={11} sm={11} md={11} lg={11}>
						<p>
							<b>{t("link.__selectTemplate__")}</b>
						</p>
					</Col>
					<Col xs={12} sm={12} md={12} lg={12}>
						<div className="space"></div>
					</Col>
					<Col xs={4} sm={4} md={4} lg={4}>
						<img
							src="/img/postcard/PostCard__1.png"
							className={selectedTemplate === 1 ? "postcard-template-selected" : "postcard-template fake-link"}
							onClick={() => setSelectedTemplate(1)}
						/>
					</Col>
					<Col xs={4} sm={4} md={4} lg={4}>
						<img
							src="/img/postcard/PostCard__2.png"
							className={selectedTemplate === 2 ? "postcard-template-selected" : "postcard-template fake-link"}
							onClick={() => setSelectedTemplate(2)}
						/>
					</Col>
					<Col xs={4} sm={4} md={4} lg={4}>
						<img
							src="/img/postcard/PostCard__3.png"
							className={selectedTemplate === 3 ? "postcard-template-selected" : "postcard-template fake-link"}
							onClick={() => setSelectedTemplate(3)}
						/>
					</Col>
					<Col xs={12} sm={12} md={12} lg={12}>
						<div className="space"></div>
						<div className="right">
							<button
								type="button"
								disabled={selectedTemplate ? false : true}
								className="dark-button"
								onClick={() => setNextStep(true)}
							>
								{t("link.__nextStep__")}
							</button>
						</div>
					</Col>
				</>
			)}
		</Row>
	);
}
