import React from "react";
import {UserStateInterface} from "../interfaces/UserStateInterface";
import ContentLayout from "../Layout/ContentLayout";
import CustomFamilyCalendar from "../utils/CustomFamilyCalendar";

interface CalendarProps {
	userState: UserStateInterface;
	userDispatch: React.Dispatch<any>;
}

export default function Calendar({ userState, userDispatch }: CalendarProps) {
	const seniorSelectedId = userState.seniorSelectedId;
	const locale = userState.locale;

	return userState.familyUser.seniors.length > 1 ? (
		<ContentLayout userState={userState} userDispatch={userDispatch}>
			<CustomFamilyCalendar locale={locale} seniorSelectedId={seniorSelectedId} />
		</ContentLayout>
	) : (
		<div className="content-container">
			<CustomFamilyCalendar locale={locale} seniorSelectedId={seniorSelectedId} />
		</div>
	);
}
