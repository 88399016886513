import { useHistory } from "react-router-dom";
import http from "../Services/Interceptor";
import * as url from "../Constants/Url";

interface useLogoutProps {
	userDispatch: Function;
}

export default function useLogout({ userDispatch }: useLogoutProps) {
	const history = useHistory();

	return () => {
		sessionStorage.clear();
		localStorage.removeItem("familyUser");
		localStorage.removeItem("isPrincipal");
		localStorage.removeItem("seniorSelectedId");
		http.post(`${url.BASEURL}/logout`);
		userDispatch({
			type: "LOGOUT"
		});
		history.push("/");
	};
}
