import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useUploadPicts from "../../../hooks/useUploadPicts";
import { useMutation, useQueryClient } from "react-query";
import http from "../../../Services/Interceptor";
import * as url from "../../../Constants/Url";

interface UploadPicturesProps {
	seniorSelectedId: number;
	setPicturesUploaded: Function;
	setIsLoading: Function;
}

export default function UploadPictures({ seniorSelectedId, setPicturesUploaded, setIsLoading }: UploadPicturesProps) {
	const { t } = useTranslation("common");
	const queryClient = useQueryClient();
	const [rgpd, setRgpd] = useState(false);
	const [errorRgpd, setErrorRgpd] = useState(false);
	const [errorSize, setErrorSize] = useState(false);
	const { displayDropzoneUpload, pictsToUpload } = useUploadPicts();

	const onSubmit = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		setIsLoading(true);
		setPicturesUploaded({ success: false, error: false, nb: 0, upload: true });
		setErrorSize(false);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth"
		});

		if (!rgpd) {
			setErrorRgpd(true);
			setIsLoading(false);
		} else {
			setErrorRgpd(false);
			const photoBoxData: any = new FormData();
			for (let i = 0; i < pictsToUpload.length; i++) {
				photoBoxData.append("items[]", pictsToUpload[i]);
			}
			await mutate(photoBoxData);
		}
	};

	const { mutate } = useMutation(
		photoBoxData => http.post(`${url.SENIORS}/${seniorSelectedId}/photo_frame_items`, photoBoxData),
		{
			onSettled: () => {
				queryClient.invalidateQueries("photo_frame_items");
			},
			onSuccess: () => {
				queryClient.invalidateQueries("photo_frame_items");
				setPicturesUploaded({ success: true, error: false, upload: false, nb: pictsToUpload.length });
				setIsLoading(false);
			},
			onError: (err: { request: { status: number } }) => {
				setIsLoading(false);
				if (err.request.status === 413) {
					setErrorSize(true);
				} else {
					setPicturesUploaded({ success: false, error: true, upload: true, nb: pictsToUpload.length });
				}
			}
		}
	);

	return (
		<form onSubmit={onSubmit} encType="multipart/form-data">
			{errorRgpd ? <p className="alert">{t("common.__rgpdError__")}</p> : null}
			{errorSize ? <p className="alert">{t("link.__addPictsSizeError__")}</p> : null}

			{displayDropzoneUpload()}

			<div className="rgpd">
				<div className="inline meta-checkbox">
					<input type="checkbox" checked={rgpd} name="rgpd" onChange={() => setRgpd(prevRgpd => !prevRgpd)} />
					{t("common.__checkRgpd__")}
				</div>
			</div>
			<button
				type="button"
				className="light-button"
				onClick={() => setPicturesUploaded({ success: false, error: false, upload: false })}
			>
				{t("common.__cancel__")}
			</button>
			<button type="submit" className="dark-button">
				{t("common.__save__")}
			</button>
		</form>
	);
}
