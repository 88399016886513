import React, { useRef, useState, useCallback } from "react";
import useLazyLoading from "../../hooks/useLazyLoading";

interface SocialNewsImageProps {
	path: string;
	display: string;
}

export default function SocialNewsImage({ path, display }: SocialNewsImageProps) {
	const imgRef = useRef(null);
	const [inView] = useLazyLoading(imgRef);
	const [isLoading, setIsLoading] = useState(true);

	const onLoad = useCallback(() => {
		setIsLoading(false);
	}, []);

	return (
		<span ref={imgRef}>
			{inView ? (
				<>
					{isLoading ? "..." : null}
					{path ? (
						<img
							src={`/storages/blog_media${path}`}
							loading="lazy"
							onLoad={onLoad}
							className={display}
							alt="activities of the residents"
						/>
					) : null}
				</>
			) : null}
		</span>
	);
}
