import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaRegCommentAlt, FaUser } from "react-icons/fa";
import http from "../../../Services/Interceptor";
import * as url from "../../../Constants/Url";
import AddCommentForm from "./AddCommentForm";
import {UserStateInterface} from "../../../interfaces/UserStateInterface";
import { CommentsInterface } from "../../../interfaces/PostInterface";
import { formatedDateFromString } from "../../../utils/Datetime";

interface CommentsListProps {
	postId: number;
	commentStore: any;
	commentDispatch: Function;
	seniorSelectedId: number;
	answerComment: { answer: boolean; id: string | null };
	setAnswerComment: Function;
	addComment: boolean;
	setAddComment: Function;
	setCounter: Function;
	userState: UserStateInterface;
}

export default function CommentsList({
	postId,
	commentStore,
	commentDispatch,
	userState,
	seniorSelectedId,
	answerComment,
	setAnswerComment,
	addComment,
	setAddComment,
	setCounter
}: CommentsListProps) {
	const { t } = useTranslation("common");
	const comments: CommentsInterface[] = commentStore.comments;

	useEffect(() => {
		if (postId) {
			http
				.get(`${url.HOMES}/blog_posts/${postId}/comments`)
				.then(res => {
					commentDispatch({
						type: "GET_COMMENTS",
						payload: res.data
					});
				})
				.catch(() => {
					commentDispatch({
						type: "GET_COMMENTS",
						payload: []
					});
				});
		}
	}, [postId]);

	// Function to order the comments list :
	// put answers after initial comment, and order them chronologicaly

	const commentsToDisplay = (comments: CommentsInterface[]) => {
		let dataToDisplay: CommentsInterface[] = [];

		comments.map(comment => {
			if (!comment.is_after) {
				dataToDisplay.push(comment);
			} else {
				const firstComment = dataToDisplay.find(elt => elt.id === comment.after_comment_id);
				const addAnswerToComment: any = {
					...firstComment,
					answers: firstComment ? (firstComment.answers ? [...firstComment.answers, comment] : [comment]) : []
				};
				const indexOfThisFirstComment = dataToDisplay.findIndex(elt => elt.id === comment.after_comment_id);
				dataToDisplay.splice(indexOfThisFirstComment, 1, addAnswerToComment);
			}
		});

		const orderedDataToDisplay = dataToDisplay.map(data => {
			return {
				...data,
				answers: data.answers ? data.answers.sort((a, b) => a.datetime.localeCompare(b.datetime)) : []
			};
		});

		return displayComments(orderedDataToDisplay);
	};

	const displayUserBullet = (firstName: string, lastName: string) => {
		if (firstName) {
			if (firstName === "badge_general") {
				return <FaUser />;
			} else {
				return (
					<b>
						{firstName.substring(0, 1)}
						{lastName.substring(0, 1)}
					</b>
				);
			}
		} else {
			return <FaUser />;
		}
	};

	const displayComments = (comments: CommentsInterface[]) => {
		return (
			<>
				{comments.map((comment, index) => (
					<div key={index} data-testid="comments-list">
						<div className="comment-container">
							<div className="comment-user-bullet">
								<div
									className={
										comment.is_family
											? comment.user.type === "principal"
												? "family-bullet principal"
												: "family-bullet affiliate"
											: "user-bullet"
									}
								>
									{displayUserBullet(comment.user.first_name, comment.user.last_name)}
								</div>
							</div>
							<div className="comment-card">
								{comment.datetime ? (
									<span className="right meta">{formatedDateFromString(comment.datetime, "Pp", userState.locale)}</span>
								) : null}
								{comment.user && comment.is_family ? (
									<b>
										{comment.user.first_name} {comment.user.last_name}
									</b>
								) : (
									<b>
										{comment.user.first_name === "badge_general" ? null : comment.user.first_name} {comment.user.last_name}{" "}
										<span className="meta">- {comment.user.type}</span>
									</b>
								)}
								<div className="light-separator"></div>
								<p className="mt-2 mb-4">{comment.content}</p>
								<div className="mt-3 mb-3">
									{answerComment.answer && answerComment.id === comment.id ? (
										<AddCommentForm
											postId={postId}
											commentStore={commentStore}
											commentDispatch={commentDispatch}
											userState={userState}
											seniorSelectedId={seniorSelectedId}
											addComment={addComment}
											setAddComment={setAddComment}
											answerComment={answerComment}
											setAnswerComment={setAnswerComment}
											setCounter={setCounter}
										/>
									) : (
										<div className="meta fake-link right" onClick={() => setAnswerComment({ answer: true, id: comment.id })}>
											<FaRegCommentAlt className="comment-answer-btn" /> {t("mailbox.__answer__")}
										</div>
									)}
								</div>
							</div>
						</div>

						{comment.answers
							? comment.answers.map((elt, index) => (
									<div className="comment-is-after-container" key={index}>
										<div className="comment-user-bullet">
											<div
												className={
													elt.is_family
														? elt.user.type === "principal"
															? "family-bullet principal"
															: "family-bullet affiliate"
														: "user-bullet"
												}
											>
												{displayUserBullet(elt.user.first_name, elt.user.last_name)}
											</div>
										</div>
										<div className="comment-card">
											{elt.user ? (
												<p>
													{elt.datetime ? (
														<span className="right meta">{formatedDateFromString(elt.datetime, "Pp", userState.locale)}</span>
													) : null}
													<b>
														{elt.user.first_name === "badge_general" ? null : elt.user.first_name} {elt.user.last_name}{" "}
														<span className="meta">- {elt.user.type}</span>
													</b>
												</p>
											) : null}
											<div className="light-separator"></div>
											<p>{elt.content}</p>
										</div>
									</div>
							  ))
							: null}
					</div>
				))}
			</>
		);
	};

	return commentsToDisplay(comments);
}
