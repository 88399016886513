import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { BsReply } from "react-icons/bs";
import MyInputTextarea from "../../utils/form/MyInputTextarea";
import ErrorContainer from "../../Layout/ErrorContainer";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";

interface ResponseFormProps {
	msgId: string;
	openResponseForm: boolean;
	setOpenResponseForm: Function;
	setUpdate: Function;
	subject: string;
}

const ResponseForm = ({ msgId, openResponseForm, setOpenResponseForm, setUpdate, subject }: ResponseFormProps) => {
	const { t } = useTranslation("common");
	const { handleSubmit } = useFormContext<{ message: string }>();
	const [httpResponse, setHttpResponse] = useState<{ error: boolean; msg: string | null } | null>(null);

	const onSubmit = (values: { message: string }) => {
		const data = {
			message_id: msgId,
			message: values.message
		};

		http
			.post(`${url.HOMES}/messages/reply_to`, data)
			.then(() => {
				setOpenResponseForm(false);
				setUpdate((prevState: boolean) => !prevState);
			})
			.catch(() => setHttpResponse({ error: true, msg: null }));
	};

	return openResponseForm ? (
		<>
			<h4>
				{t("link.__msgObject__")} : {subject}
			</h4>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ErrorContainer
					alert={t("link.__msgSentError__")}
					success={t("link.__msgSentSuccess__")}
					httpResponse={httpResponse}
					setHttpResponse={setHttpResponse}
				/>
				<MyInputTextarea label={t("common.__message__")} name="message" behavior={{ required: true }} rows="5" />
				<button type="button" className="light-button" onClick={() => setOpenResponseForm(false)}>
					{t("common.__cancel__")}
				</button>
				<button type="submit" className="dark-button">
					<BsReply className="response-icon" /> {t("mailbox.__answer__")}
				</button>
			</form>
			<div className="space"></div>
		</>
	) : null;
};

export default ResponseForm;
