import React, { useState } from "react";

interface SlideShowProps {
	show: {
		selected: {
			id: number;
		} | null;
		img: {
			id: number;
		}[];
		show: boolean;
	};
}

export default function SlideShow({ show }: SlideShowProps) {
	const [selectedImg, setSelectedImg] = useState(show.selected ? show.selected : null);

	return (
		<>
			<div className="center mt-5 slideshow">
				{selectedImg ? (
					<img
						src={`/storages/seniors/messages/images/${selectedImg.id}/`}
						className="slideshow-selected-pict"
						alt="slideshow"
					/>
				) : null}
			</div>
			<div className="slideshow-background mt-5">
				{show.img.map(pict => (
					<img
						src={`/storages/seniors/messages/images/${pict.id}/thumb`}
						key={pict.id}
						className="thumbnail-img fake-link"
						onClick={() => setSelectedImg(pict)}
						alt="slideshow"
					/>
				))}
			</div>
		</>
	);
}
