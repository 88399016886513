import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCheck, BsArrowRightShort } from "react-icons/bs";
import MyInputPassword from "../../utils/form/MyInputPassword";

const PasswordControl = () => {
	const { t } = useTranslation("common");
	const [passwordSize, setPasswordSize] = useState(false);
	const [hasNumber, setHasNumber] = useState(false);
	const [hasUppercase, setHasUppercase] = useState(false);
	const [hasLowercase, setHasLowercase] = useState(false);
	const [hasSpecialChar, setHasSpecialChar] = useState(false);

	const checkPassword = (e: string) => {
		if (e.length >= 8) {
			setPasswordSize(true);
		} else {
			setPasswordSize(false);
		}
		if (/[0-9]/.test(e)) {
			setHasNumber(true);
		} else {
			setHasNumber(false);
		}
		if (/[a-z]/.test(e)) {
			setHasLowercase(true);
		} else {
			setHasLowercase(false);
		}
		if (/[A-Z]/.test(e)) {
			setHasUppercase(true);
		} else {
			setHasUppercase(false);
		}
		if (/[²€£µ<>^!@=#$%^&*()`§_+,.\\/;':"-]/.test(e)) {
			setHasSpecialChar(true);
		} else {
			setHasSpecialChar(false);
		}
	};

	return (
		<>
			<MyInputPassword
				label={t("user.__password__")}
				name="password"
				behavior={{
					required: true,
					pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[²€£µ<>^!@=#$%^&*()`§_+,.\\/;':"-]).{8,}$/
				}}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => checkPassword(e.target.value)}
			/>
			<div className="story-card mt-5">
				<p>{t("user.__passwordMustContain__")}</p>
				{passwordSize ? (
					<p className="big-meta green">
						<BsCheck style={{ verticalAlign: "middle", height: "1.1rem", width: "auto" }} /> {t("user.__eightCaracters__")}
					</p>
				) : (
					<p className="big-meta">
						<BsArrowRightShort style={{ verticalAlign: "middle", height: "1.1rem", width: "auto" }} />{" "}
						{t("user.__eightCaracters__")}
					</p>
				)}
				{hasNumber ? (
					<p className="big-meta green">
						<BsCheck style={{ verticalAlign: "middle", height: "1.1rem", width: "auto" }} /> {t("user.__oneNumber__")}
					</p>
				) : (
					<p className="big-meta">
						<BsArrowRightShort style={{ verticalAlign: "middle", height: "1.1rem", width: "auto" }} />{" "}
						{t("user.__oneNumber__")}
					</p>
				)}
				{hasUppercase ? (
					<p className="big-meta green">
						<BsCheck style={{ verticalAlign: "middle", height: "1.1rem", width: "auto" }} /> {t("user.__oneUppercase__")}
					</p>
				) : (
					<p className="big-meta">
						<BsArrowRightShort style={{ verticalAlign: "middle", height: "1.1rem", width: "auto" }} />{" "}
						{t("user.__oneUppercase__")}
					</p>
				)}
				{hasLowercase ? (
					<p className="big-meta green">
						<BsCheck style={{ verticalAlign: "middle", height: "1.1rem", width: "auto" }} /> {t("user.__oneLowercase__")}
					</p>
				) : (
					<p className="big-meta">
						<BsArrowRightShort style={{ verticalAlign: "middle", height: "1.1rem", width: "auto" }} />{" "}
						{t("user.__oneLowercase__")}
					</p>
				)}
				{hasSpecialChar ? (
					<p className="big-meta green">
						<BsCheck style={{ verticalAlign: "middle", height: "1.1rem", width: "auto" }} />{" "}
						{t("user.__oneSpecialCaracter__")}
					</p>
				) : (
					<p className="big-meta">
						<BsArrowRightShort style={{ verticalAlign: "middle", height: "1.1rem", width: "auto" }} />{" "}
						{t("user.__oneSpecialCaracter__")}
					</p>
				)}
			</div>
		</>
	);
};

export default PasswordControl;
