import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BsArrowRightCircle, BsFolder, BsArchive } from "react-icons/bs";
import { formatedDateFromString } from "../../utils/Datetime";
import { DocumentInterface } from "../../interfaces/MessagesInterface";

interface FlowDocumentProps {
	document: DocumentInterface;
	userLocale: string;
	type: string;
	seniorSelectedId: number;
	userDispatch: Function;
}

export default function FlowDocument({ document, userLocale, type, seniorSelectedId, userDispatch }: FlowDocumentProps) {
	const { t } = useTranslation("common");
	const history = useHistory();
	const [documentIsUnread, setDocumentIsUnread] = useState(document.unread);

	const markAsRead = (unread: boolean) => {
		if (unread) {
			if (type === "home") {
				userDispatch({
					type: "READ_HOME_DOCUMENT",
					payload: seniorSelectedId
				});
				setDocumentIsUnread(false);
			} else if (type === "senior") {
				userDispatch({
					type: "READ_SENIOR_DOCUMENT",
					payload: seniorSelectedId
				});
				setDocumentIsUnread(false);
			}
		}
	};

	return (
		<div className={documentIsUnread ? "unread-card" : "flow-card"}>
			<div className="space-between">
				<b>
					{type === "home" ? (
						<BsArchive className="nav-icon" style={{ marginRight: "0.5rem" }} />
					) : (
						<BsFolder className="nav-icon" style={{ marginRight: "0.5rem" }} />
					)}
					{t("mailbox.__document__")}
				</b>
				<p className="meta right">{formatedDateFromString(document.datetime, "Pp", userLocale)}</p>
			</div>
			<div className="social-separator mt-2 mb-2"></div>
			<div className="space-between">
				<div>
					{type === "senior" ? (
						<a
							href={`/storages/seniors/documents/${document.id}`}
							download={`${document.title}-${document.id}`}
							onClick={() => markAsRead(document.unread)}
						>
							<p className="mt-2">
								<b>
									{t("common.__download__")} : <span className="blue">{document.title}</span>
								</b>{" "}
								<span className="meta">({document.content_type.split("/")[1]})</span>
							</p>
						</a>
					) : type === "home" ? (
						<a
							href={`/storages/homes/documents/${document.id}`}
							download={`${document.title}-${document.id}`}
							onClick={() => markAsRead(document.unread)}
						>
							<p className="mt-2">
								<b>
									{t("common.__download__")} :{" "}
									<span className="blue">
										{document.title} - {document.content_type.split("/")[1]}
									</span>
								</b>
							</p>
						</a>
					) : null}
				</div>
				<div className="flow-arrow">
					<BsArrowRightCircle
						className="read-more-icon"
						onClick={() => history.push(`/messages/${type === "senior" ? 5 : 4}`)}
						role="button"
					/>
				</div>
			</div>
		</div>
	);
}
