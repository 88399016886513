import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { categories, getActivityTitle } from "./Activities";
import DatePicker from "react-datepicker";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import HandleLocale from "../../utils/HandleLocale";
import Timeline from "./Timeline";
import {UserStateInterface} from "../../interfaces/UserStateInterface";
import ActivityInterface from "../../interfaces/ActivityInterface";
import "../../utils/DatePicker.scss";
import "./Timeline.scss";
import { usedatefns } from "@e4ia/e4link_modules.usedatefns";
import { today, lastWeek } from "../../utils/Datetime";
import HeaderContainer from "../../Layout/HeaderContainer";

interface TimelineProps {
	userState: UserStateInterface;
	filter?: string;
}

const FilteredTimelineContainer = ({ userState, filter }: TimelineProps) => {
	const { t } = useTranslation("common");
	let displayDate = true;
	const [filteredActivities, setFilteredActivities] = useState([]);
	const [healthWorkerMap, setHealthWorkerMap] = useState(new Map());
	const [visitorMap, setVisitorMap] = useState(new Map());
	const [category, setCategory] = useState("All");
	const [timelineError, setTimelineError] = useState(false);
	const seniorSelectedId = userState.seniorSelectedId;
	const userLocale = HandleLocale(userState.locale);
	const [startDate, setStartDate] = useState(filter ? new Date(filter + "T00:00:00") : lastWeek);
	const [endDate, setEndDate] = useState(filter ? new Date(filter + "T23:59:59") : today);

	useEffect(() => {
		if (seniorSelectedId && startDate && endDate && category) {
			const fromToDateUrl = usedatefns({
				type: "fromToUrl",
				startDate: startDate,
				endDate: endDate,
				style: " "
			});

			http
				.get(`${url.SENIORS}/${seniorSelectedId}/timeline/plus/occupations/${fromToDateUrl}`)
				.then(res => {
					setTimelineError(false);
					const activities = res.data.activities;
					const data = activities.map(function (activity: ActivityInterface) {
						return {
							...activity,
							category: getActivityTitle(activity.aid)
						};
					});
					if (res.data.health_workers.length > 0) {
						setHealthWorkerMap(new Map(res.data.health_workers));
					}
					if (res.data.family_users.length > 0) {
						setVisitorMap(new Map(res.data.family_users));
					}
					if (category !== "All") {
						setFilteredActivities(data.filter((activity: ActivityInterface) => category === activity.category));
					} else {
						setFilteredActivities(data);
					}
				})
				.catch(() => setTimelineError(true));
		}
		// eslint-disable-next-line
	}, [seniorSelectedId, startDate, endDate, category]);

	return (
		<>
			<HeaderContainer title={t("timeline.__activitiesAndCare__")} />

			<div className="colored-background">
				<label>{t("timeline.__dates__")} :</label>
				<div className="space-between">
					<DatePicker
						selected={startDate}
						onChange={(date: Date) => setStartDate(date)}
						selectsStart
						startDate={startDate}
						endDate={endDate}
						locale={userLocale}
						dateFormat="PP"
					/>
					<DatePicker
						selected={endDate}
						onChange={(date: Date) => setEndDate(date)}
						selectsEnd
						startDate={startDate}
						endDate={endDate}
						minDate={startDate}
						locale={userLocale}
						dateFormat="PP"
					/>
				</div>
				<select name="categories" onChange={e => setCategory(e.target.value)}>
					<option value="All">{t("timeline.__selectCategory__")}</option>
					{categories.map((category, index) => (
						<option key={index} value={category} className="select-form">
							{category}
						</option>
					))}
				</select>
			</div>
			<div className="space"></div>
			{filteredActivities.length > 0 ? (
				<Timeline
					activities={filteredActivities}
					healthWorkerMap={healthWorkerMap}
					visitorMap={visitorMap}
					timelineError={timelineError}
					displayDate={displayDate}
					userLocale={userState.locale}
				/>
			) : (
				<p>{t("common.__noData__")}</p>
			)}
		</>
	);
};

export default FilteredTimelineContainer;
