export const BASEURL = "/api/family/v1";
export const SENIORS = "/api/family/v1/seniors";
export const QRCODE = "/qrcode/generate";
export const MESSAGES = "/seniors/messages";
export const THREAD = "/homes/messages";
export const VISIO = "homes/visio";
export const RGPD = "/me/rgpd/accept";
export const HOMES = "/api/family/v1/homes";
export const FAMILY_URL = "/api/family/v1/family_users";

// IMAGES
export const AVATAR = "/storages/seniors";
export const DOCUMENTS = "/storages/homes/documents";
export const MSGIMG = "/storages/seniors/messages/medias";
export const LOGO = "/storages/homes";
