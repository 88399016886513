import React, { useState } from "react";

interface Props {
	children: React.ReactNode;
	text: string;
}

export const Tooltip = ({ children, text, ...rest }: Props) => {
	const [show, setShow] = useState(false);

	return (
		<span className="tooltip-container">
			<span className={show ? "tooltip-box visible" : "tooltip-box"}>
				{text}
				{/* <span className="tooltip-arrow" /> */}
			</span>
			<span onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} {...rest}>
				{children}
			</span>
		</span>
	);
};
