import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import common_en from "../locales/en/common.json";
import common_fr from "../locales/fr/common.json";
import common_nl from "../locales/nl/common.json";
import activities_en from "../locales/en/activities.json";
import activities_fr from "../locales/fr/activities.json";
import activities_nl from "../locales/nl/activities.json";
import rgpd_en from "../locales/en/rgpd.json";
import rgpd_fr from "../locales/fr/rgpd.json";
import rgpd_nl from "../locales/nl/rgpd.json";

const resources = {
	en: {
		common: common_en,
		activities: activities_en,
		rgpd: rgpd_en
	},
	fr: {
		common: common_fr,
		activities: activities_fr,
		rgpd: rgpd_fr
	},
	nl: {
		common: common_nl,
		activities: activities_nl,
		rgpd: rgpd_nl
	}
};

const options = {
	order: ["localStorage", "navigator", "queryString", "cookie", "subdomain", "htmlTag"],
	lookupCookie: "i18next",
	lookupLocalStorage: "i18next"
};

i18next
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: true,
		resources,
		detection: options,
		lng: localStorage.getItem("i18next"),
		supportedLngs: ["en", "fr", "nl"],
		fallbackLng: "en",
		nonExplicitSupportedLngs: true
	});

export default i18next;
