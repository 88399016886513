import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";
import http from "../../../Services/Interceptor";
import * as url from "../../../Constants/Url";
import MyInputTextarea from "../../../utils/form/MyInputTextarea";
import { UserStateInterface } from "../../../interfaces/UserStateInterface";
import { formatedDate } from "../../../utils/Datetime";

interface AddCommentProps {
	postId: number;
	commentStore: any;
	commentDispatch: Function;
	addComment: boolean;
	setAddComment: Function;
	seniorSelectedId: number;
	answerComment: { answer: boolean; id: string | null };
	setAnswerComment: Function;
	setCounter: Function;
	userState: UserStateInterface;
}

interface InputValues {
	comment: string;
}

export default function AddCommentForm({
	postId,
	userState,
	commentStore,
	commentDispatch,
	addComment,
	setAddComment,
	seniorSelectedId,
	answerComment,
	setAnswerComment,
	setCounter
}: AddCommentProps) {
	const { t } = useTranslation("common");
	const queryClient = useQueryClient();
	const { handleSubmit } = useFormContext<InputValues>();

	const onSubmit = async (values: InputValues) => {
		commentDispatch({
			type: "RESET_ES"
		});
		let data: any = null;
		if (answerComment && answerComment.answer) {
			data = {
				comment: {
					blog_id: postId,
					content: values.comment,
					user: {
						id: userState.familyUser.id,
						type: userState.familyUser.principal ? "principal" : "proche",
						first_name: userState.familyUser.first_name,
						last_name: userState.familyUser.last_name
					},
					datetime: formatedDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
					is_family: true,
					is_after: true,
					after_comment_id: answerComment.id,
					senior_id: seniorSelectedId,
					id: uuidv4()
				}
			};
		} else {
			data = {
				comment: {
					blog_id: postId,
					content: values.comment,
					user: {
						id: userState.familyUser.id,
						type: userState.familyUser.principal ? "principal" : "proche",
						first_name: userState.familyUser.first_name,
						last_name: userState.familyUser.last_name
					},
					datetime: formatedDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
					is_family: true,
					is_after: false,
					after_comment_id: null,
					senior_id: seniorSelectedId,
					id: uuidv4(),
					answers: []
				}
			};
		}
		await mutate(data);
	};

	const { mutate } = useMutation(data => http.put(`${url.HOMES}/blog_posts/${postId}/comments`, data), {
		onSuccess: res => {
			queryClient.invalidateQueries("blog_posts");
			commentDispatch({
				type: "ADD_COMMENT",
				payload: res.data
			});
			setCounter((prevState: number) => prevState + 1);
			if (addComment) {
				setAddComment(false);
			}
			if (answerComment) {
				setAnswerComment({ answer: false, id: null });
			}
		},
		onError: () => {
			commentDispatch({
				type: "DISPLAY_ERROR"
			});
		}
	});

	const handleCancelButton = () => {
		commentDispatch({
			type: "RESET_ES"
		});
		if (answerComment) {
			setAnswerComment({ answer: false, id: null });
		}
		if (addComment) {
			setAddComment(false);
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} data-testid="add-comment-form">
			{commentStore.error ? "erreur" : null}
			<MyInputTextarea
				testid="comment-textarea"
				name="comment"
				label={t("news.__addComment__")}
				behavior={{ required: true }}
				rows="4"
			/>
			<button type="button" className="light-button" onClick={() => handleCancelButton()} data-testid="add-comment-cancel">
				{t("common.__cancel__")}
			</button>
			<button type="submit" className="dark-button" data-testid="add-comment-submit">
				{t("common.__send__")}
			</button>
		</form>
	);
}
