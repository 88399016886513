import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsHeart, BsHeartFill } from "react-icons/bs";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import { SinglePostInterface } from "../../interfaces/PostInterface";

interface LikeButtonProps {
	post: SinglePostInterface;
}

export default function LikeButton({ post }: LikeButtonProps) {
	const { t } = useTranslation("common");
	const [iLike, setiLike] = useState(post.ilike);
	const [likeCounter, setLikeCounter] = useState(post.like_counter);

	const addLike = (id: number) => {
		if (id) {
			http.get(`${url.BASEURL}/blog_posts/${id}/like`).then(() => {
				if (iLike) {
					setiLike(false);
					setLikeCounter(prevState => prevState - 1);
				} else {
					setiLike(true);
					setLikeCounter(prevState => prevState + 1);
				}
			});
		}
	};
	const BsHeartCommon = iLike ? BsHeartFill : BsHeart;

	return (
		<div onClick={() => addLike(post.id)} data-testid={iLike ? "ilike-icon" : "like-icon"}>
			<BsHeartCommon className={`social-like-icon ${iLike ? "" : "fake-link"}`} />
			{likeCounter || (
				<span className="meta" data-testid="ask-for-like">
					{t("news.__firstLike__")}
				</span>
			)}
		</div>
	);
}
