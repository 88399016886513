import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BsPlus } from "react-icons/bs";
import "../../MailBox/Media.scss";
import MessagesList from "./MessagesList";
import http from "../../../Services/Interceptor";
import * as url from "../../../Constants/Url";
import {UserStateInterface} from "../../../interfaces/UserStateInterface";
import HeaderContainer from "../../../Layout/HeaderContainer";

interface MessagesProps {
	userDispatch: Function;
	userState: UserStateInterface;
}

const Messages = ({ userState, userDispatch }: MessagesProps) => {
	const { t } = useTranslation("common");
	const seniorSelectedId = userState.seniorSelectedId;

	useEffect(() => {
		if (seniorSelectedId) {
			http.get(`${url.BASEURL}/seniors/${seniorSelectedId}/all/unread/count`).then(res => {
				userDispatch({
					type: "UPDATE_COUNTERS",
					payload: { senior: seniorSelectedId, counters: res.data }
				});
			});
		}
	}, [seniorSelectedId]);

	const buttons = () => {
		return (
			<div>
				<Link to="/profile/6">
					<button type="button" className="dark-button">
						<BsPlus style={{ verticalAlign: "middle" }} /> {t("common.__message__")}
					</button>
				</Link>
				<Link to="/postcard">
					<button type="button" className="light-button">
						<BsPlus style={{ verticalAlign: "middle" }} /> {t("link.__postcard__")}
					</button>
				</Link>
			</div>
		);
	};

	return (
		<>
			<HeaderContainer title={t("link.__privateMessage__")} button={buttons} />
			<MessagesList userState={userState} userDispatch={userDispatch} />
		</>
	);
};

export default Messages;
