import React from "react";
import { Row, Col } from "react-grid-system";
import Sidebar from "./Sidebar";
import {UserStateInterface} from "../interfaces/UserStateInterface";

interface ContentLayoutProps {
	userState: UserStateInterface;
	userDispatch: React.Dispatch<any>;
	children: React.ReactNode;
}

export default function ContentLayout({ userState, userDispatch, children }: ContentLayoutProps) {
	return (
		<Row className="content-container">
			<Col xs={12} sm={12} md={4} lg={3}>
				<Sidebar userState={userState} userDispatch={userDispatch} />
			</Col>
			<Col xs={12} sm={12} md={8} lg={9}>
				{children}
			</Col>
		</Row>
	);
}
