import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import http from "../../../Services/Interceptor";
import * as url from "../../../Constants/Url";
import { useInfiniteQuery } from "react-query";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import Loader from "../../../Layout/Loader";
import NoDataAvailable from "../../../Layout/NoDataAvailable";

interface FullGalleryProps {
	seniorSelectedId: number;
	removeList: number[];
	handleRemove: Function;
	userId: number;
	isPrincipal: boolean;
}

interface PicturesInterface {
	uuid: number;
	added_by: number;
	accepted: boolean | null;
	path: string;
}

export default function FullGallery({ seniorSelectedId, removeList, handleRemove, userId, isPrincipal }: FullGalleryProps) {
	const { t } = useTranslation("common");

	const { status, data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
		["photo_frame_items", seniorSelectedId],
		async ({ pageParam = 0 }) => {
			const res = await http.get(`${url.BASEURL}/seniors/${seniorSelectedId}/photo_frame_items?nb=5&idx=` + pageParam);
			return res.data;
		},
		{
			getPreviousPageParam: firstPage => firstPage.previousId ?? false,
			getNextPageParam: lastPage => lastPage.nextId ?? false
		}
	);

	const loadMoreButtonRef = useRef<HTMLButtonElement>(null);

	useIntersectionObserver({
		target: loadMoreButtonRef,
		onIntersect: fetchNextPage,
		enabled: hasNextPage
	});

	return (
		<>
			<div className="mood-card" style={{ marginBottom: "1rem" }}>
				{t("common.__toKnow__")} :
				<br />
				<ul>
					<li>
						<span className="meta">{t("link.__photoFrameColorWarning__")}</span>
					</li>
					{isPrincipal ? (
						<li>
							<span className="meta">{t("link.__photoFrameDelWarning__")}</span>
						</li>
					) : (
						<li>
							<span className="meta">{t("link.__photoFrameDelWarningAffiliate__")}</span>
						</li>
					)}
				</ul>
			</div>

			{status === "loading" ? (
				<Loader />
			) : status === "error" ? (
				<NoDataAvailable />
			) : data ? (
				data.pages.length > 0 ? (
					<div className="masonry">
						{data.pages.map((group, i) => (
							<ul key={i}>
								{group.data.map((pict: PicturesInterface) => (
									<li className="grid" key={pict.uuid}>
										{userId === pict.added_by ? (
											<>
												<div className="photo-items">
													<img src={`/storages/photo_frame_items/${pict.path}`} alt={pict.path} loading="lazy" />
													<div className="photo-moderation">
														{pict.accepted === false && <h4>{t("link.__pictsRefused__")}</h4>}
														{pict.accepted === null && <h4>{t("link.__pictsPending__")}</h4>}
													</div>
												</div>
												<div className="pict-actions">
													<input
														type="checkbox"
														checked={removeList.includes(pict.uuid) ? true : false}
														onChange={() => handleRemove(pict.uuid)}
													/>
													<label className={removeList.includes(pict.uuid) ? "blue bold" : ""}>{t("common.__delete__")}</label>
												</div>
											</>
										) : (
											<>
												<div className="black-overlay">
													<img src={`/storages/photo_frame_items/${pict.path}`} alt={pict.path} />
												</div>
												{isPrincipal ? (
													<div className="pict-actions">
														<input
															type="checkbox"
															checked={removeList.includes(pict.uuid) ? true : false}
															onChange={() => handleRemove(pict.uuid)}
														/>
														<label className={removeList.includes(pict.uuid) ? "blue bold" : ""}>{t("common.__delete__")}</label>
													</div>
												) : userId === pict.added_by ? (
													<div className="pict-actions">
														<input
															type="checkbox"
															checked={removeList.includes(pict.uuid) ? true : false}
															onChange={() => handleRemove(pict.uuid)}
														/>
														<label className={removeList.includes(pict.uuid) ? "blue bold" : ""}>{t("common.__delete__")}</label>
													</div>
												) : null}
											</>
										)}
									</li>
								))}
							</ul>
						))}
						<div className="center">
							<button
								ref={loadMoreButtonRef}
								onClick={() => fetchNextPage()}
								disabled={!hasNextPage || isFetchingNextPage}
								className="light-button"
							>
								{isFetchingNextPage ? t("common.__loading__") : hasNextPage ? t("common.__more__") : null}
							</button>
							{isFetching && !isFetchingNextPage ? t("common.__loading__") : null}
						</div>
					</div>
				) : (
					<div className="tips">
						<p>{t("link.__photoFrameWarning__")}</p>
					</div>
				)
			) : null}
		</>
	);
}
