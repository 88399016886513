import React, { useMemo } from "react";
import { Redirect } from "react-router-dom";

const withActiveAndE4linkedAccess = WrappedComponent => {
	return function (props) {
		const mySeniors = useMemo(() => props.userState.familyUser.seniors, [props.userState.familyUser]);
		const seniorSelectedId = useMemo(() => props.userState.seniorSelectedId, [props.userState.seniorSelectedId]);
		const mySenior = mySeniors.length > 1 ? mySeniors.find(senior => senior.id === seniorSelectedId) : mySeniors[0];
		return mySenior && mySenior.active && mySenior.e4linked ? <WrappedComponent {...props} /> : <Redirect to="/social" />;
	};
};

export default withActiveAndE4linkedAccess;
