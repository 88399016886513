import React, { useState, useRef, useEffect } from "react";

// Inspired by : https://dev.to/abdulbasit313/how-to-develop-a-stopwatch-in-react-js-with-custom-hook-561b

const Chrono = ({ isRecording, isStopped }) => {
	const [timer, setTimer] = useState(0);
	const increment = useRef(null);

	const handleStart = () => {
		increment.current = setInterval(() => {
			setTimer(timer => timer + 1);
		}, 1000);
	};

	const handlePause = () => {
		clearInterval(increment.current);
	};

	const formatTime = () => {
		const getSeconds = `0${timer % 60}`.slice(-2);
		const minutes = `${Math.floor(timer / 60)}`;
		const getMinutes = `0${minutes % 60}`.slice(-2);
		const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
		return `${getHours} : ${getMinutes} : ${getSeconds}`;
	};

	useEffect(() => {
		if (isRecording && !isStopped) {
			handleStart();
		} else {
			handlePause();
		}
	}, [isRecording, isStopped]);

	return <p style={{ marginBottom: "0.5rem" }}>Durée : {formatTime()}</p>;
};

export default Chrono;
