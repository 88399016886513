import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

const MyInputText = props => {
	const { t } = useTranslation("common");
	const { register, formState: { errors } } = useFormContext();
	return (
		<div className="my-input">
			<label htmlFor={props.name}>{props.label}</label>
			<input id={props.name} name={props.name} type="text" {...register(props.name, { required: props.behavior.required })} />
			{props.behavior.required ? (
				<small id={`${props.name}Error`}>
					{errors[props.name] ? (
						<span className="error">{t("common.__requiredInput__")}.</span>
					) : (
						<span className="meta-input right">{t("common.__required__")}</span>
					)}
				</small>
			) : null}
		</div>
	);
};

export default MyInputText;
