import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "./i18n/i18next";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";

import "./index.css";
import "./fonts/Poppins/Poppins-Bold.ttf";
import "./fonts/Poppins/Poppins-Regular.ttf";
import "./fonts/Poppins/Poppins-Light.ttf";

ReactDOM.render(
	<React.StrictMode>
		<I18nextProvider i18next={i18next}>
			<App />
		</I18nextProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
