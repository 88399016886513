import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import { Prompt, useHistory } from "react-router-dom";
import http from "../../../Services/Interceptor";
import * as url from "../../../Constants/Url";
import { useMutation, useQueryClient } from "react-query";
import FullGallery from "./FullGallery";
// import DeletePicturesModal from "./DeletePicturesModal";
import { BsTrash } from "react-icons/bs";
import Loader from "../../../Layout/Loader";
interface GalleryProps {
	seniorSelectedId: number;
	userId: number;
	setPicturesDeleted: Function;
	isPrincipal: boolean;
	setPicturesUploaded: Function;
}

export default function Gallery({
	seniorSelectedId,
	userId,
	setPicturesDeleted,
	isPrincipal,
	setPicturesUploaded
}: GalleryProps) {
	const { t } = useTranslation("common");
	const [removeList, setRemoveList] = useState<number[]>([]);
	const queryClient = useQueryClient();
	// const [show, setShow] = useState({ show: false, imgToDelete: 0 });
	// const [confirmNavigation, setConfirmNavigation] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	// const nextLocation = useRef(null);
	// const history = useHistory();

	// useEffect(() => {
	// 	if (confirmNavigation) {
	// 		history.push(nextLocation.current);
	// 	}
	// }, [confirmNavigation]);

	const handleRemove = (id: number) => {
		setPicturesUploaded({
			success: false,
			error: false,
			upload: false,
			nb: 0
		});
		if (removeList.includes(id)) {
			setRemoveList(removeList.filter(item => item !== id));
		} else {
			setRemoveList([...removeList, id]);
		}
	};

	const deletePictures = async () => {
		setIsLoading(true);
		await mutate();
	};

	const { mutate } = useMutation(
		() =>
			http.post(`${url.BASEURL}/seniors/${seniorSelectedId}/photo_frame_items`, {
				_method: "DELETE",
				photo_frame: {
					items: removeList
				}
			}),
		{
			onSettled: () => {
				queryClient.invalidateQueries("photo_frame_items");
			},
			onSuccess: () => {
				queryClient.invalidateQueries("photo_frame_items");
				setIsLoading(false);
				setPicturesDeleted({ success: true, error: false, nb: removeList.length });
				setRemoveList([]);
			},
			onError: () => {
				setIsLoading(false);
				setPicturesDeleted({ success: false, error: true, nb: removeList.length });
			}
		}
	);

	// const handleBlockedNavigation = (e: any) => {
	// 	if (removeList.length > 0 && e.pathname !== "/profile/3") {
	// 		setShow({ show: true, imgToDelete: removeList.length });
	// 		nextLocation.current = e.pathname;
	// 		return false;
	// 	}
	// 	return true;
	// };

	return (
		<>
			{removeList.length > 0 ? (
				<div className="colored-background" style={{ padding: "0.1rem 0.5rem 1.2rem 0.5rem" }}>
					<button className="right suppr-button mt-2" onClick={() => deletePictures()}>
						{t("common.__delete__")}
					</button>
					<p style={{ marginTop: "1.25rem" }}>
						<BsTrash className="timeline-icon red" /> {t("link.__pictsToRemoveCounter__")} : <b>{removeList.length}</b>
					</p>
				</div>
			) : null}
			{isLoading ? (
				<Loader />
			) : (
				<FullGallery
					removeList={removeList}
					handleRemove={handleRemove}
					seniorSelectedId={seniorSelectedId}
					userId={userId}
					isPrincipal={isPrincipal}
				/>
			)}

			{/* <Prompt when={removeList.length > 0 && !confirmNavigation} message={e => handleBlockedNavigation(e)} /> */}

			{/* <DeletePicturesModal
				setConfirmNavigation={setConfirmNavigation}
				show={show}
				setShow={setShow}
				deletePictures={deletePictures}
			/> */}
		</>
	);
}
