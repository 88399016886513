import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./Media.scss";
import DocumentContainer from "./DocumentContainer";
import { DocumentInterface } from "../../interfaces/MessagesInterface";

interface DocumentsProps {
	documents: DocumentInterface[];
	userDispatch: Function;
	seniorSelectedId: number;
	userLocale: string;
	type: string;
}

export default function DocumentsWithSearch({
	documents,
	userLocale,
	userDispatch,
	seniorSelectedId,
	type
}: DocumentsProps) {
	const { t } = useTranslation("common");
	const [search, setSearch] = useState(documents);

	const searchDocument = (value: string) => {
		if (value) {
			const searchedDocuments = documents.filter((document: DocumentInterface) => {
				return document.title.toLowerCase().includes(value.toLowerCase());
			});
			setSearch(searchedDocuments);
		} else {
			setSearch(documents);
		}
	};

	return (
		<>
			<input type="text" placeholder={t("common.__search__")} onChange={e => searchDocument(e.target.value)} />
			<div className="mt-2">
				{search.map((document: DocumentInterface) => (
					<DocumentContainer
						document={document}
						userLocale={userLocale}
						type={type}
						seniorSelectedId={seniorSelectedId}
						userDispatch={userDispatch}
						key={document.id}
					/>
				))}
			</div>
		</>
	);
}
