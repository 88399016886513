import React from "react";
import { Redirect, useParams } from "react-router-dom";
import SeniorStories from "../components/Link/Lifestories/SeniorStories";
import Messages from "../components/Link/MessagesBox/Messages";
import LinkNav from "../components/Link/LinkNav";
import UploadAvatar from "../components/Link/UploadAvatar";
import PhotoFrame from "../components/Link/PhotoFrame/PhotoFrame";
import MessageDetails from "../components/Link/MessagesBox/MessageDetails";
import { UserStateInterface } from "../interfaces/UserStateInterface";
import SendMessage from "../components/Link/MessagesBox/SendMessage";
import ContentLayout from "../Layout/ContentLayout";

type LinkProps = {
	userDispatch: React.Dispatch<any>;
	userState: UserStateInterface;
};

export default function Link({ userState, userDispatch }: LinkProps) {
	const { component } = useParams<{ component?: string }>();
	const { msgId } = useParams<{ msgId?: string }>();

	const mySeniors = userState.familyUser.seniors;
	const seniorSelectedId = userState.seniorSelectedId;
	const mySenior = mySeniors.find(senior => senior.id === seniorSelectedId);
	const accessAllowed = mySenior ? mySenior.e4linked : false;

	const handleComponent = (component?: string) => {
		switch (component) {
			case "1":
				return <Messages userState={userState} userDispatch={userDispatch} />;
			case "2":
				return <SeniorStories userState={userState} />;
			case "3":
				return <PhotoFrame userState={userState} />;
			case "5":
				return userState.familyUser.principal ? (
					<UploadAvatar userState={userState} />
				) : (
					<Messages userState={userState} userDispatch={userDispatch} />
				);
			case "6":
				return <SendMessage userState={userState} />;
			case "7":
				return msgId ? (
					<MessageDetails userState={userState} msgId={parseInt(msgId)} />
				) : (
					<Messages userState={userState} userDispatch={userDispatch} />
				);
			default:
				return <Messages userState={userState} userDispatch={userDispatch} />;
		}
	};

	return accessAllowed ? (
		<ContentLayout userState={userState} userDispatch={userDispatch}>
			<>
				<LinkNav isPrincipal={userState.familyUser.principal} />
				<div className="space"></div>
				{handleComponent(component)}
			</>
		</ContentLayout>
	) : component === "7" && msgId ? (
		<ContentLayout userState={userState} userDispatch={userDispatch}>
			<MessageDetails userState={userState} msgId={parseInt(msgId)} />
		</ContentLayout>
	) : (
		<Redirect to="/flow" />
	);
}
