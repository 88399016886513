import i18n from "../../i18n/i18next";

export function getWeatherSentence(ow: number) {
	if (ow >= 200 && ow <= 201) {
		return i18n.t("common:weather.__thunderstormLightRain__");
	} else if (ow === 202) {
		return i18n.t("common:weather.__thunderstormHeavyRain__");
	} else if ((ow >= 210 && ow <= 211) || ow === 221) {
		return i18n.t("common:weather.__stormy__");
	} else if (ow === 212) {
		return i18n.t("common:weather.__severeThunderstorm__");
	} else if (ow >= 230 && ow <= 232) {
		return i18n.t("common:weather.__thunderstormDrizzle__");
	} else if (ow >= 300 && ow <= 310) {
		return i18n.t("common:weather.__drizzle__");
	} else if (ow >= 311 && ow <= 321) {
		return i18n.t("common:weather.__drizzleRain__");
	} else if (ow >= 500 && ow <= 501) {
		return i18n.t("common:weather.__rain__");
	} else if (ow >= 502 && ow <= 504) {
		return i18n.t("common:weather.__heavyRain__");
	} else if (ow === 511) {
		return i18n.t("common:weather.__freezingRain__");
	} else if (ow === 520) {
		return i18n.t("common:weather.__lightRain__");
	} else if (ow === 521) {
		return i18n.t("common:weather.__showerRain__");
	} else if (ow === 522) {
		return i18n.t("common:weather.__heavyShowerRain__");
	} else if (ow === 531) {
		return i18n.t("common:weather.__irregularRain__");
	} else if ((ow >= 600 && ow <= 601) || (ow >= 620 && ow <= 621)) {
		return i18n.t("common:weather.__snow__");
	} else if (ow === 602) {
		return i18n.t("common:weather.__heavySnowfall__");
	} else if (ow >= 611 && ow <= 613) {
		return i18n.t("common:weather.__hail__");
	} else if (ow >= 615 && ow <= 616) {
		return i18n.t("common:weather.__rainSnow__");
	} else if (ow === 622) {
		return i18n.t("common:weather.__heavySnow__");
	} else if (ow === 701) {
		return i18n.t("common:weather.__fog__");
	} else if ((ow >= 711 && ow <= 721) || ow === 741) {
		return i18n.t("common:weather.__mist__");
	} else if ((ow === 731 || ow >= 751) && ow <= 761) {
		return i18n.t("common:weather.__dustStorm__");
	} else if (ow === 762) {
		return i18n.t("common:weather.__volcanicCloud__");
	} else if (ow === 771 || ow === 781) {
		return i18n.t("common:weather.__tornado__");
	} else if (ow === 800) {
		return i18n.t("common:weather.__clearSky__");
	} else if (ow === 801 || ow === 802) {
		return i18n.t("common:weather.__scatteredClouds__");
	} else if (ow === 803 || ow === 804) {
		return i18n.t("common:weather.__coveredSky__");
	}
}

export function getWeatherIcon(ow: number) {
	if ((ow >= 200 && ow <= 202) || (ow >= 210 && ow <= 211) || (ow >= 221 && ow <= 232)) {
		return "/img/weather/thunderstorm.png";
	} else if (ow === 212) {
		return "/img/weather/severe-thunderstorm.png";
	} else if (ow >= 300 && ow <= 321) {
		return "/img/weather/drizzle.png";
	} else if ((ow >= 500 && ow <= 501) || ow === 520) {
		return "/img/weather/rain.png";
	} else if ((ow >= 502 && ow <= 511) || (ow >= 521 && ow <= 522)) {
		return "/img/weather/heavy-rain.png";
	} else if ((ow >= 600 && ow <= 601) || (ow >= 620 && ow <= 621)) {
		return "/img/weather/snowy.png";
	} else if (ow === 602 || ow === 622) {
		return "/img/weather/blizzard.png";
	} else if (ow >= 611 && ow <= 613) {
		return "/img/weather/hail.png";
	} else if (ow >= 615 && ow <= 616) {
		return "/img/weather/sleet.png";
	} else if (ow === 701) {
		return "/img/weather/fog.png";
	} else if ((ow >= 711 && ow <= 721) || ow === 741 || ow === 762) {
		return "/img/weather/haze.png";
	} else if ((ow === 731 || ow >= 751) && ow <= 761) {
		return "/img/weather/breezy.png";
	} else if (ow === 771 || ow === 781) {
		return "/img/weather/tornado.png";
	} else if (ow === 800) {
		return "/img/weather/sunny.png";
	} else if (ow === 801 || ow === 802) {
		return "/img/weather/party-cloudy.png";
	} else if (ow === 803 || ow === 804) {
		return "/img/weather/cloudy.png";
	}
}
