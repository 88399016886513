import React, { cloneElement } from "react";
import { BsX } from "react-icons/bs";

interface ModalProps {
	show: { show: boolean; info: any };
	setShow: Function;
	children: any;
}

export default function ModalContainer({ show, setShow, children }: ModalProps) {
	return show.show ? (
		<div className="overlay">
			<div className="modal">
				<div className="modal-close" role="button" onClick={() => setShow({ show: false, info: null })}>
					<BsX />
				</div>
				<div className="modal-body">{cloneElement(children, { show: show, setSHow: setShow })}</div>
			</div>
		</div>
	) : null;
}
