import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BsCalendar, BsCameraVideoFill, BsImage, BsChatSquare, BsArrowRightCircle } from "react-icons/bs";
import { FaFileDownload, FaRegFilePdf } from "react-icons/fa";
import Parse from "html-react-parser";
import VideoPlayer from "./VideoPlayer";
import Comments from "./Comments/Comments";
import { MediasInterface, SinglePostInterface } from "../../interfaces/PostInterface";
import "./social.scss";
import SocialNewsImage from "./SocialNewsImage";
import LikeButton from "./LikeButton";
import { formatedDateFromString } from "../../utils/Datetime";
import {UserStateInterface} from "../../interfaces/UserStateInterface";

interface SocialNewsProps {
	post: SinglePostInterface;
	seniorSelectedId: number;
	userState: UserStateInterface;
}

interface ViewCommentsInterface {
	display: boolean;
	id: number | null;
}

export default function SocialNewsContainer({ post, userState, seniorSelectedId }: SocialNewsProps) {
	const { t } = useTranslation("common");
	// const queryClient = useQueryClient();
	const [viewComments, setViewComments] = useState<ViewCommentsInterface>({ display: false, id: null });

	// const addLike = async (id: any) => {
	// 	await mutate(id);
	// };

	// const { mutate } = useMutation(postId => http.get(`${url.BASEURL}/blog_posts/${postId}/like`), {
	// 	onSuccess: () => {
	// 		queryClient.invalidateQueries(["blog_posts", seniorSelectedId]);
	// 	}
	// });

	const displayMeta = (medias: MediasInterface[]) => {
		if (medias.length > 0) {
			const videoCounter = medias.filter(media => media.content_type.split("/")[0] === "video");
			const picturesCounter = medias.filter(media => media.content_type.split("/")[0] === "image");
			const pdfCounter = medias.filter(media => media.content_type === "application/pdf");
			return (
				<>
					<BsCameraVideoFill color="#4A4E64" className="nav-icon ml-5" /> {videoCounter.length}
					<BsImage color="#4A4E64" className="nav-icon ml-5" /> {picturesCounter.length}
					<FaRegFilePdf color="#4A4E64" className="nav-icon ml-5" /> {pdfCounter.length}
				</>
			);
		}
	};

	const displayMedias = (medias: MediasInterface[]) => {
		const video = medias.filter(media => media.content_type.split("/")[0] === "video");
		const pictures = medias.filter(media => media.content_type.split("/")[0] === "image");
		if (video.length > 0 && pictures.length < 1) {
			return (
				<div className="center">
					<VideoPlayer uriVideo={video[0].path} contentType={video[0].content_type} />
				</div>
			);
		} else {
			const pictures = medias.filter(media => media.content_type.split("/")[0] === "image");
			if (pictures.length === 1) {
				return <SocialNewsImage path={pictures[0].path} display={"pict"} />;
			} else if (pictures.length > 1) {
				return (
					<div className="pict-container">
						<img src={`/storages/blog_media${pictures[0].path}`} className="inline-picts" alt="activity of the residents" />
						<img src={`/storages/blog_media${pictures[1].path}`} className="inline-picts" alt="activity of the residents" />
					</div>
				);
			}
		}
	};

	const handleDisplayComments = (postId: number) => {
		if (viewComments.id === postId) {
			setViewComments({ display: false, id: null });
		} else {
			if (postId) {
				setViewComments({ display: true, id: postId });
			}
		}
	};

	const displayPdf = (medias: MediasInterface[]) => {
		const pdfs = medias.filter(media => media.content_type === "application/pdf");
		return (
			pdfs.length > 0 &&
			pdfs.map((elt, index) => (
				<div key={index} className="pdf-file">
					<a href={`/storages/blog_media${elt.path}`} download={`${elt.filename}`}>
						<FaFileDownload style={{ margin: "0 1rem 0 0.2rem", verticalAlign: "middle" }} />
						<b>{elt.filename ? elt.filename : t("common.__pdfFile__")}</b>
					</a>
				</div>
			))
		);
	};

	return (
		<div className="social-card">
			{post.focused_on ? (
				<div className="ribbon">
					<span>{t("news.__private__")}</span>
				</div>
			) : null}
			<div className="space-between">
				<Link to={`/social/${post.id}`}>
					<h3 className="blogpost-title">{post.title}</h3>
				</Link>
				<Link to={`/social/${post.id}`}>
					<BsArrowRightCircle className="read-more-post" />
				</Link>
			</div>
			<div className="social-info">
				<span className="meta">
					<BsCalendar color="#4A4E64" className="nav-icon" />
					{formatedDateFromString(post.date, "PPP", userState.locale)}
				</span>
				{displayMeta(post.medias)}
			</div>

			{post.medias.length === 0 ? null : (
				<>
					{displayMedias(post.medias)}
					{displayPdf(post.medias)}
				</>
			)}

			<div className="social-content">
				<div className="social-text">
					<div className="tinymce-content">{Parse(post.body)}</div>
				</div>
				<div className="social-separator"></div>
			</div>
			<div className="social-container">
				<div className="social-box center">
					<LikeButton post={post} />
				</div>
				<div className="social-box center">
					<div className="fake-link" onClick={() => handleDisplayComments(post.id)} role="button">
						<BsChatSquare className="social-icon" />
						<span className="social-text-link fake-link">{post.comment_counter}</span>
					</div>
				</div>
			</div>
			{viewComments && viewComments.id === post.id ? (
				<>
					<div className="social-separator"></div>
					<div className="light-card">
						<Comments
							postId={post.id}
							commentsCounter={post.comment_counter}
							userState={userState}
							seniorSelectedId={seniorSelectedId}
						/>
					</div>
				</>
			) : null}
		</div>
	);
}
