import React from "react";
import UploadAvatar from "../components/Link/UploadAvatar";
import { UserStateInterface } from "../interfaces/UserStateInterface";
import ContentLayout from "../Layout/ContentLayout";
import withActiveAndE4linkedAccess from "../Layout/withActiveAndE4linkedAccess";

interface AvatarProps {
	userState: UserStateInterface;
	userDispatch: React.Dispatch<any>;
}

const Avatar = ({ userState, userDispatch }: AvatarProps) => {
	return (
		<ContentLayout userState={userState} userDispatch={userDispatch}>
			<UploadAvatar userState={userState} />
		</ContentLayout>
	);
};
export default withActiveAndE4linkedAccess(Avatar);
