import React, { useState } from "react";
import * as API from "../../Services/API";
import { BsEnvelope } from "react-icons/bs";
import { PostcardModal } from "./PostcardModal";
import { useHistory } from "react-router-dom";

export default function ButtonSendPostcard({ seniorSelectedId, canvasRef, modal, setError }) {
	const [show, setShow] = useState(false);
	const history = useHistory();

	const sendPostcard = () => {
		setError(false);
		const formData = new FormData();
		const canvas = canvasRef.current;

		if (canvas.toBlob) {
			canvas.toBlob(function (blob) {
				const postcard = [new File([blob], "postcard.jpeg", { type: "image/jpeg" })];

				for (let i = 0; i < postcard.length; i++) {
					formData.append(`file${[i + 1]}`, postcard[i]);
				}
				formData.append("subject", "sujet");
				formData.append("message", "message");

				API.sendMessageToSenior(
					seniorSelectedId,
					formData,
					() => {
						modal ? history.push("/profile/1") : setShow(true);
					},
					() => {
						setError(true);
					}
				);
			});
		}
	};

	return (
		<>
			<button className="dark-button" type="button" onClick={() => sendPostcard()}>
				<BsEnvelope style={{ marginRight: "5px", verticalAlign: "middle" }} /> Message privé
			</button>
			<PostcardModal
				show={show}
				setShow={setShow}
				format={"privateMessage"}
				seniorSelectedId={seniorSelectedId}
				canvasRef={canvasRef}
				setError={setError}
			/>
		</>
	);
}
