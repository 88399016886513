import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BsArrowRightCircle, BsClipboardCheck } from "react-icons/bs";
import { AiOutlineQrcode } from "react-icons/ai";
import { formatedDateFromString } from "../../utils/Datetime";
import { getActivityAction, getActivityDetail, getActivityTitle } from "../Timeline/Activities";
import getActivityMood from "../Timeline/getActivityMood";

interface FlowActivityProps {
	activity: {
		aid: number;
		dt: string;
		dval: number;
		hwid: number | null;
		fuid: number | null;
		o: number;
		hw?: { first_name: string; last_name: string };
		fu?: { first_name: string; last_name: string };
	};
	userLocale: string;
}

export default function FlowActivity({ activity, userLocale }: FlowActivityProps) {
	const { t } = useTranslation("common");

	const displayMood = (o: number) => {
		switch (o) {
			case 13:
				return <AiOutlineQrcode className="nav-icon" />;
			case 1:
			case 2:
			case 3:
			case 4:
			case 5:
				return getActivityMood(o, true);
			default:
				return <BsClipboardCheck className="nav-icon" />;
		}
	};

	return (
		<div className="flow-card">
			<div className="space-between">
				<b>
					{activity.aid === 13 ? displayMood(13) : displayMood(activity.o)}
					<span style={{ marginLeft: "0.5rem" }}>{getActivityTitle(activity.aid)}</span>
				</b>
				<p className="meta right">{formatedDateFromString(activity.dt, "Pp", userLocale)}</p>
			</div>
			<div className="social-separator mt-2 mb-2"></div>
			<div className="space-between">
				<div>
					<p className="mt-2">
						<b>{getActivityAction(activity.aid)}</b> {activity.dval ? `: ${getActivityDetail(activity.dval, activity.aid)}` : null}
					</p>
					{activity.hwid
						? activity.hw && (
								<p className="meta">
									{t("timeline.__healthworker__")} :{" "}
									<b>
										{activity.hw.last_name} {activity.hw.first_name === "badge_general" ? null : activity.hw.first_name}
									</b>
								</p>
						  )
						: null}
					{activity.fuid
						? activity.fu && (
								<p className="meta">
									<b>
										{activity.fu.last_name} {activity.fu.first_name}
									</b>
								</p>
						  )
						: null}
				</div>
				<div className="flow-arrow">
					<Link to={`/activities/${activity.dt.split(" ")[0]}`}>
						<BsArrowRightCircle className="read-more-icon" />
					</Link>
				</div>
			</div>
		</div>
	);
}
