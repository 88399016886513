import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BsChatDots, BsArrowRightCircle } from "react-icons/bs";
import { MediasInterface } from "../../interfaces/PostInterface";
import { formatedDateFromString } from "../../utils/Datetime";

interface MessagesListProps {
	userDispatch: Function;
	seniorSelectedId: number;
	message: MessageInterface;
	userLocale: string;
}

interface MessageInterface {
	id: number;
	subject: string;
	unread: boolean;
	medias: MediasInterface[];
	read_at: string;
	message: string;
	type: number;
	created_at: string;
}

export default function FlowLinkMessage({ userDispatch, message, seniorSelectedId, userLocale }: MessagesListProps) {
	const { t } = useTranslation("common");
	const history = useHistory();

	const handleMessageDetail = (id: number, unread: boolean) => {
		if (unread) {
			userDispatch({
				type: "READ_SENIOR_MESSAGE",
				payload: seniorSelectedId
			});
		}
		history.push(`/profile/7/${id}`);
	};

	return message.type === 1 ? (
		<div className={message.unread ? "unread-card" : "flow-card"}>
			<div className="space-between">
				<b>
					<BsChatDots className="nav-icon" style={{ marginRight: "0.5rem" }} />
					{t("link.__privateMessage__")}
				</b>
				<p className="meta">
					{t("mailbox.__sentOn__")} : {formatedDateFromString(message.created_at, "P", userLocale)}
					<br />
					{message.read_at ? (
						<>
							{t("link.__readOn__")} : {formatedDateFromString(message.read_at, "Pp", userLocale)}
						</>
					) : (
						t("link.__unreadMessage__")
					)}
				</p>
			</div>
			<div className="social-separator mt-2 mb-2"></div>
			<div className="space-between">
				<p className="mt-2 fake-link" onClick={() => handleMessageDetail(message.id, message.unread)} role="button">
					{message.unread ? (
						<b className="blue">{t("link.__readMessage__")}</b>
					) : (
						<>
							<b>{t("link.__msgObject__")} :</b> {message.subject}
						</>
					)}
				</p>
				<div className="flow-arrow">
					<BsArrowRightCircle
						className="read-more-icon"
						onClick={() => handleMessageDetail(message.id, message.unread)}
						role="button"
					/>
				</div>
			</div>
		</div>
	) : null;
	// <div className={message.unread ? "unread-card" : "flow-card"}>
	// 	<div className="space-between">
	// 		<b>
	// 			<BsChatDotsFill className="nav-icon" style={{ marginRight: "0.5rem" }} />
	// 			{t("link.__privateMessage__")}
	// 		</b>
	// 		<p className="meta">
	// 			{t("mailbox.__receivedOn__")}
	// 			{formatedDateFromString(message.created_at, "P", userLocale)}
	// 		</p>
	// 	</div>
	// 	<div className="social-separator mt-2"></div>
	// 	<div className="space-between">
	// 		<p className="mt-2">
	// 			<b>{t("common.__message__")} :</b> <br />
	// 			{message.message}
	// 		</p>
	// 		<div className="flow-arrow">
	// 			<BsArrowRightCircle
	// 				className="read-more-icon"
	// 				onClick={() => handleMessageDetail(message.id, message.unread)}
	// 				role="button"
	// 			/>
	// 		</div>
	// 	</div>
	// </div>
}
