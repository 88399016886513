import React from "react";
import { useTranslation } from "react-i18next";
import * as url from "../../Constants/Url";
import Select from "react-select";
import useAxios from "../../hooks/useAxios";
import Loader from "../../Layout/Loader";

interface HandleTimezoneProps {
	timezone: { value: string; label: string };
	setTimezone: Function;
	familyUserTimezone: { value: string; label: string };
}

const HandleTimezone = ({ timezone, setTimezone, familyUserTimezone }: HandleTimezoneProps) => {
	const { t } = useTranslation("common");
	const { data, loading } = useAxios({ url: `${url.BASEURL}/timezones` });
	const timezoneList = data ? data.timezones : [];
	const timezones =
		timezoneList && timezoneList.length > 0
			? timezoneList.map((timezone: string) => ({ value: timezone, label: timezone }))
			: [];

	return loading ? (
		<Loader />
	) : (
		<Select
			placeholder={t("common.__timezone__")}
			name="colors"
			options={timezones}
			className="basic-multi-select mt-2"
			classNamePrefix="select"
			onChange={(e: any) => setTimezone(e)}
			value={timezone ? timezone : familyUserTimezone}
			isOptionSelected={(option, selectValue) => selectValue.some(i => i === option)}
			menuPlacement="top"
		/>
	);
};

export default HandleTimezone;
