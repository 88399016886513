import React, { createContext, useReducer } from "react";
import ErrorSuccessReducer from "./ErrorSuccessReducer";

export const ErrorSuccessContext = createContext();

const initialState = {
	error: false,
	success: false,
	msg: null
};

export const ErrorSuccessProvider = ({ children }) => {
	const [esState, esDispatch] = useReducer(ErrorSuccessReducer, initialState);

	return (
		<ErrorSuccessContext.Provider
			value={{
				esState,
				esDispatch
			}}
		>
			{children}
		</ErrorSuccessContext.Provider>
	);
};

export const ErrorSuccessConsumer = ErrorSuccessContext.Consumer;
