import React from "react";

export const displayAvatar = (updated: boolean, gender: string, id: number, classname: string) => {
	switch (updated) {
		case false:
			if (gender === "M") {
				return <img src="/img/senior_male.png" alt="profil" className={classname} />;
			}
			if (gender === "F") {
				return <img src="/img/senior_female.png" alt="profil" className={classname} />;
			}
			break;
		case true:
			return <img src={`/storages/seniors/${id}/avatar`} alt="profil" className={classname} />;
		default:
			return;
	}
};
