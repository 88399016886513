import React, { useState, useEffect } from "react";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import "./Weather.scss";

interface HomeLogoProps {
	seniorHome: {
		id: number;
		name: string;
	};
}

export default function HomeLogo({ seniorHome }: HomeLogoProps) {
	const [logo, setLogo] = useState(false);

	useEffect(() => {
		if (seniorHome.id) {
			http
				.get(`${url.LOGO}/${seniorHome.id}/logo`)
				.then(() => setLogo(true))
				.catch(() => setLogo(false));
		}
	}, [seniorHome]);

	return logo ? (
		<img src={`${url.LOGO}/${seniorHome.id}/logo`} alt="logo" className="logo-home" />
	) : (
		<h3>{seniorHome.name}</h3>
	);
}
