import React from "react";
import { useTranslation } from "react-i18next";
import ButtonSendToPhotoframe from "./ButtonSendToPhotoframe";
import { BsXCircle, BsCheckCircle } from "react-icons/bs";
import ButtonSendPostcard from "./ButtonSendPostcard";
import ButtonDownloadPostcard from "./ButtonDownloadPostcard";

export const PostcardModal = ({ show, setShow, format, seniorSelectedId, canvasRef, setError }) => {
	const { t } = useTranslation("common");

	const content = show && (
		<div className="overlay">
			<div className="modal">
				<div className="modal-close" type="button" onClick={() => setShow(false)}>
					<BsXCircle />
				</div>
				<div className="modal-body">
					<h2>{t("link.__postcard__")}</h2>
					<div className="space"></div>
					{format === "privateMessage" ? (
						<div>
							<div className="card">
								<BsCheckCircle style={{ width: "28", height: "auto", verticalAlign: "middle" }} />{" "}
								<b>{t("link.__postcardSentSuccess__")}</b>
							</div>
							<ButtonDownloadPostcard canvas={canvasRef.current} />
							<ButtonSendToPhotoframe
								seniorSelectedId={seniorSelectedId}
								canvasRef={canvasRef}
								modal={true}
								setError={setError}
							/>
						</div>
					) : null}
					{format === "photoFrame" ? (
						<div>
							<div className="card">
								<BsCheckCircle style={{ width: "28", height: "auto", verticalAlign: "middle" }} />{" "}
								<b>{t("link.__sentToPhotoFrameSuccess__")}</b>
							</div>
							<ButtonDownloadPostcard canvas={canvasRef.current} />
							<ButtonSendPostcard seniorSelectedId={seniorSelectedId} canvasRef={canvasRef} modal={true} setError={setError} />
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
	return content;
};
