import React from "react";
import SlideShow from "./SlideShow";
import { BsXCircle } from "react-icons/bs";

interface ModalProps {
	show: {
		selected: {
			id: number;
		} | null;
		img: {
			id: number;
		}[];
		show: boolean;
	};
	setShow: Function;
}

const ModalSlideShow = ({ show, setShow }: ModalProps) => {
	return show.show ? (
		<div className="overlay">
			<div className="modal">
				<div className="modal-close" role="button" onClick={() => setShow({ show: false, img: [], selected: null })}>
					<BsXCircle />
				</div>
				<div className="modal-body">
					<SlideShow show={show} />
				</div>
			</div>
		</div>
	) : null;
};

export default ModalSlideShow;
