import { useState, useEffect } from "react";

export default function useDeviceDetect() {
	const [isMobile, setMobile] = useState<boolean>(false);
	const [isIos, setIsIos] = useState<boolean>(false);
	const [isAndroid, setIsAndroid] = useState<boolean>(false);

	useEffect(() => {
		const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
		const mobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));
		const android = Boolean(userAgent.match(/Tablet|Android|BlackBerry|Opera Mini|IEMobile|WPDesktop/i));
		const iOs = Boolean(userAgent.match(/iPhone|iPad|iPod/i));
		setMobile(mobile);
		setIsIos(iOs);
		setIsAndroid(android);
	}, []);

	return { isMobile, isIos, isAndroid };
}
