import React, { useContext } from "react";
import { FamilyUserContext } from "../contexts/FamilyUserContext";
import {UserStateInterface} from "../interfaces/UserStateInterface";
import ContentLayout from "../Layout/ContentLayout";
import HomeInfo from "../components/HomeInfo/HomeInfo";

interface HomeDetailsProps {
	userState: UserStateInterface;
	userDispatch: React.Dispatch<any>;
}

export default function HomeDetails({ userState, userDispatch }: HomeDetailsProps) {
	const { seniorHome } = useContext(FamilyUserContext);

	return (
		<ContentLayout userState={userState} userDispatch={userDispatch}>
			<HomeInfo userState={userState} seniorHome={seniorHome} />
		</ContentLayout>
	);
}
