import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import videojs from "video.js";
import "video.js/dist/video-js.css";

interface VideoPlayerProps {
	uriVideo: string;
	contentType: string;
}

export default function VideoPlayer({ uriVideo, contentType }: VideoPlayerProps) {
	const { t } = useTranslation("common");
	const playerRef = useRef<HTMLVideoElement>(null);
	const videoSrc = `/storages/blog_media${uriVideo}`;
	const [downloadLink, setDownloadLink] = useState(false);

	const videoJsOptions = {
		controls: true,
		autoPlay: false,
		playsInline: true,
		muted: true,
		fluid: true,
		bigPlayButton: true,
		sources: [
			{
				src: videoSrc,
				type: contentType
			}
		],
		preload: "metadata",
		playbackRates: [0.5, 1, 1.5, 2]
		// children: {
		// 	mediaLoader: true,
		// 	controlBar: {
		// 		children: [
		// 			"playToggle",
		// 			"volumePanel",
		// 			"currentTimeDisplay",
		// 			"timeDivider",
		// 			"durationDisplay",
		// 			"progressControl",
		// 			"liveDisplay",
		// 			"remainingTimeDisplay",
		// 			"customControlSpacer",
		// 			"playbackRateMenuButton",
		// 			"audioTrackButton",
		// 			"fullscreenToggle"
		// 		]
		// 	}
		// }
	};

	useEffect(() => {
		if (playerRef && playerRef.current) {
			const player = videojs(
				playerRef.current,
				videoJsOptions,
				() => {
					// player.src({
					//     src: videoSrc,
					//     type: contentType
					// });
					// player.poster(posterSrc);

					player.on("error", function () {
						setDownloadLink(true);
						player.dispose();
					});
					// player.ready(function () {
					//     // player.play() //start on load
					// })
				}

				// player.on("ended", () => {
				//     player.posterImage.show();
				// });
			);
			return () => {
				player.dispose();
			};
		}
	}, []);

	return (
		<>
			<div data-vjs-player>
				<video
					ref={playerRef}
					className="video-js big-play-centered "
					style={{ width: "100%", background: "transparent" }}
				/>
			</div>

			{downloadLink && (
				<div className="story-card">
					<p>
						{t("news.__videoNotWorking__")}
						{/* <a href={videoSrc} download><strong>Téléchargez la video.</strong></a> */}
					</p>
				</div>
			)}
		</>
	);
}
