import React from "react";
import { BsXLg } from "react-icons/bs";

interface HandleErrorSuccessProps {
	esState: {
		msg: string;
		error: boolean;
		success: boolean;
	};
	esDispatch: Function;
}

export default function HandleErrorSuccess({ esState, esDispatch }: HandleErrorSuccessProps) {
	return (
		<>
			{esState.error && (
				<p className="alert">
					<span className="right error close-btn" onClick={() => esDispatch({ type: "RESET_ES" })}>
						<BsXLg style={{ verticalAlign: "middle" }} />
					</span>
					{esState.msg}
				</p>
			)}
			{esState.success && (
				<p className="success">
					<span className="right error close-btn" onClick={() => esDispatch({ type: "RESET_ES" })}>
						<BsXLg style={{ verticalAlign: "middle" }} />
					</span>
					{esState.msg}
				</p>
			)}
		</>
	);
}
