import React from "react";
import { BsEmojiFrown, BsEmojiNeutral, BsEmojiSmile, BsClipboardCheck } from "react-icons/bs";

export default function getActivityMood(o: number, isFlow: boolean) {
	switch (o) {
		case 1:
		case 2:
			return <BsEmojiFrown className={isFlow ? "nav-icon" : "mood-emoticon-timeline"} />;
		case 3:
			return <BsEmojiNeutral className={isFlow ? "nav-icon" : "mood-emoticon-timeline"} />;
		case 4:
		case 5:
			return <BsEmojiSmile className={isFlow ? "nav-icon" : "mood-emoticon-timeline"} />;
		default:
			return <BsClipboardCheck className={isFlow ? "nav-icon" : "mood-emoticon-timeline"} />;
	}
}
