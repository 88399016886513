import React from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../i18n/LanguageSwitcher";

interface HandleLocaleProps {
	language: string;
	setLanguage: Function;
	isForAffiliate: boolean;
}

const HandleLanguage = ({ language, setLanguage, isForAffiliate }: HandleLocaleProps) => {
	const { t } = useTranslation("common");

	const languages = [
		{
			id: 1,
			name: t("common.__french__"),
			locale: "fr"
		},
		{
			id: 2,
			name: t("common.__english__"),
			locale: "en"
		},
		{
			id: 3,
			name: t("common.__dutch__"),
			locale: "nl"
		}
	];

	return (
		<LanguageSwitcher userLocale={language} languages={languages} setLanguage={setLanguage} isForAffiliate={isForAffiliate} />
	);
};

export default HandleLanguage;
