// NotificationBOX : Type of messages
export const MSG_FROM_FAMILY_TO_USER = 1;
export const MSG_FROM_USER_TO_FAMILY = 2;
export const FAMILY_ASK_FOR_VISIO = 3;
export const USER_ACCEPT_VISIO = 4;
export const USER_REJECT_VISIO = 5;
export const FAMILY_CANCEL_VISIO = 6;
export const MULTI_MSG_FROM_USER_TO_FAMILY = 7;
export const DUPLICATED_MSG_FROM_USER_TO_FAMILY = 8;
export const USER_ASK_FOR_VISIO = 13;
export const FAMILY_ACCEPT_VISIO = 14;
export const FAMILY_REJECT_VISIO = 15;
export const USER_CANCEL_VISIO = 16;
export const WELCOME_MESSAGE = 100;
