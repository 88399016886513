import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Media.scss";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import {UserStateInterface} from "../../interfaces/UserStateInterface";
import DocumentsWithSearch from "./DocumentsWithSearch";
import HeaderContainer from "../../Layout/HeaderContainer";

interface DocumentsProps {
	userDispatch: Function;
	homeId: number;
	seniorSelectedId: number;
	msgDispatch: Function;
	msgStore: any;
	userState: UserStateInterface;
}

export default function Documents({
	userState,
	userDispatch,
	homeId,
	msgStore,
	msgDispatch,
	seniorSelectedId
}: DocumentsProps) {
	const { t } = useTranslation("common");
	const documents = msgStore.documents;

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.HOMES}/${homeId}/documents`)
				.then(res => {
					msgDispatch({
						type: "GET_DOCUMENTS",
						payload: res.data.home_documents
					});
				})
				.catch(() => {
					msgDispatch({
						type: "GET_DOCUMENTS",
						payload: []
					});
				});
		}
		// eslint-disable-next-line
	}, [homeId]);

	useEffect(() => {
		if (seniorSelectedId) {
			http.get(`${url.BASEURL}/seniors/${seniorSelectedId}/all/unread/count`).then(res => {
				userDispatch({
					type: "UPDATE_COUNTERS",
					payload: { senior: seniorSelectedId, counters: res.data }
				});
			});
		}
	}, [seniorSelectedId]);

	return (
		<>
			<HeaderContainer title={t("mailbox.__generalDocuments__")} />

			{documents.length > 0 ? (
				<DocumentsWithSearch
					documents={documents}
					userLocale={userState.locale}
					type="home"
					seniorSelectedId={seniorSelectedId}
					userDispatch={userDispatch}
				/>
			) : (
				<p>{t("mailbox.__noDocument__")}</p>
			)}
		</>
	);
}
