import React, { useState, useEffect } from "react";
import "./Moodweek.scss";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import MoodweekContainer from "./MoodweekContainer";

interface Props {
	seniorSelectedId: number;
}
interface MoodInterface {
	value: number;
	date: string;
}

const Moodweek = ({ seniorSelectedId }: Props) => {
	const [mood, setMood] = useState<{ date: string; value: number }[]>([]);

	useEffect(() => {
		if (seniorSelectedId) {
			http
				.get(`${url.SENIORS}/${seniorSelectedId}/mood`)
				.then(res => {
					const moods = res.data;
					const noMoodToDisplay = moods.every((elt: MoodInterface) => elt.value === 0);
					if (noMoodToDisplay) {
						setMood([]);
					} else {
						setMood(moods);
					}
				})
				.catch(() => setMood([]));
		}
		// eslint-disable-next-line
	}, [seniorSelectedId]);

	return mood.length > 0 ? <MoodweekContainer mood={mood} /> : null;
};

export default Moodweek;
