import React from "react";
import PostCard from "../components/PostCard/PostCard";
import { UserStateInterface } from "../interfaces/UserStateInterface";
import withActiveAndE4linkedAccess from "../Layout/withActiveAndE4linkedAccess";

const PostCards = ({ userState }: { userState: UserStateInterface }) => {
	return (
		<div className="content-container">
			<PostCard userState={userState} />
		</div>
	);
};
export default withActiveAndE4linkedAccess(PostCards);
