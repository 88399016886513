import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BsChevronDoubleLeft } from "react-icons/bs";

interface Props {
	title: string;
	button?: () => any;
}

const HeaderContainer = ({ title, button }: Props) => {
	const { t } = useTranslation("common");
	const history = useHistory();
	return (
		<>
			<div className="space-between">
				<h2>{title}</h2>
				{button ? (
					<div>{button()}</div>
				) : (
					<div className="fake-link meta mt-2" onClick={() => history.goBack()} role="button">
						<BsChevronDoubleLeft style={{ verticalAlign: "middle" }} /> {t("common.__back__")}
					</div>
				)}
			</div>
			<div className="underliner"></div>
		</>
	);
};
export default HeaderContainer;
