import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatedDateFromString } from "../../utils/Datetime";
import { MessageInterface } from "../../interfaces/MessagesInterface";
// import CancelVisio from "./CancelVisio";
import RejectVisio from "../MailBox/RejectVisio";
import { handleIcon, displayMessages, handleResponse } from "../../utils/MailBoxUtils";
import ModalContainer from "../../Layout/ModalContainer";
import {UserStateInterface} from "../../interfaces/UserStateInterface";
import * as types from "../../Constants/MessagesConstants";
import { BsX } from "react-icons/bs";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";

interface FlowMessageProps {
	message: MessageInterface;
	seniorSelectedId: number;
	userDispatch: Function;
	userState: UserStateInterface;
}

export default function FlowMessages({ message, seniorSelectedId, userDispatch, userState }: FlowMessageProps) {
	const { t } = useTranslation("common");
	const userLocale = userState.locale;
	const userConnectedId = userState.familyUser.id;

	// const [showCancelVisio, setShowCancelVisio] = useState({ show: false, info: null });
	const [showRejectVisio, setShowRejectVisio] = useState<{
		show: boolean;
		info: { visioId: number; visioDate: string } | null;
	}>({ show: false, info: null });
	const [visioIsRejected, setVisioIsRejected] = useState({ rejected: false });

	const [error, setError] = useState(false);
	const [displaySuccess, setDisplaySuccess] = useState(false);

	const visioIsAccepted = (id: number) => {
		if (id) {
			setError(false);
			const data: any = {
				message_id: id
			};
			http
				.post(`${url.HOMES}/visio/accept`, data)
				.then(() => {
					userDispatch({
						type: "READ_MESSAGE",
						payload: { senior: seniorSelectedId, nb: 1 }
					});
					setDisplaySuccess(true);
				})
				.catch(() => setError(true));
		}
	};

	return (
		<>
			<div className={message.unread ? "unread-card" : "flow-card"}>
				<div className="space-between">
					{handleIcon(message.type, message.unread, message.subject, true, message.family_user, userConnectedId)}
					<p className="meta right">{formatedDateFromString(message.datetime, "Pp", userLocale)}</p>
				</div>
				<div className="social-separator mt-2 mb-2"></div>
				<div className="space-between">
					<div className="mt-2">
						{displayMessages(message, userLocale)}
						{message.type === types.USER_ASK_FOR_VISIO ? (
							<>
								{displaySuccess ? (
									<div className="green-success">{t("mailbox.__visioIsAccepted__")}</div>
								) : visioIsRejected.rejected ? (
									<div className="green-success">{t("mailbox.__visioIsRejected__")}</div>
								) : (
									<div>
										{error ? (
											<p className="alert">
												{t("common.__errorOccured__")}
												<span className="right fake-link" role="button" onClick={() => setError(false)}>
													<BsX />
												</span>
											</p>
										) : null}
										<button
											type="button"
											className="light-button"
											onClick={() =>
												setShowRejectVisio({ show: true, info: { visioId: message.id, visioDate: message.visio_datetime } })
											}
										>
											{t("mailbox.__no__")}
										</button>
										<button type="button" className="dark-button" onClick={() => visioIsAccepted(message.id)}>
											{t("mailbox.__ok__")}
										</button>
									</div>
								)}
							</>
						) : null}
					</div>

					<div className="flow-arrow">
						{handleResponse(
							message.type,
							message.id,
							message.unread,
							message.family_user.id,
							message.visio_datetime,
							userConnectedId,
							seniorSelectedId,
							setShowRejectVisio,
							userDispatch,
							true //isFlow
						)}
					</div>
				</div>
			</div>
			{/* <ModalContainer show={showCancelVisio} setShow={setShowCancelVisio}>
				<CancelVisio
					show={showCancelVisio}
					setShow={setShowCancelVisio}
					userDispatch={userDispatch}
					seniorSelectedId={seniorSelectedId}
					userState={userState}
				/>
			</ModalContainer> */}
			<ModalContainer show={showRejectVisio} setShow={setShowRejectVisio}>
				<RejectVisio
					show={showRejectVisio}
					setShow={setShowRejectVisio}
					userState={userState}
					userDispatch={userDispatch}
					seniorSelectedId={seniorSelectedId}
					setVisioIsRejected={setVisioIsRejected}
				/>
			</ModalContainer>
		</>
	);
}
