import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useInfiniteQuery } from "react-query";
import { MediasInterface } from "../../../interfaces/PostInterface";
import * as url from "../../../Constants/Url";
import http from "../../../Services/Interceptor";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import {UserStateInterface} from "../../../interfaces/UserStateInterface";
import NoDataAvailable from "../../../Layout/NoDataAvailable";
import Loader from "../../../Layout/Loader";
import MessageContainer from "./MessageContainer";

interface MessagesListProps {
	userDispatch: Function;
	userState: UserStateInterface;
}

interface MessageInterface {
	id: number;
	subject: string;
	unread: boolean;
	medias: MediasInterface[];
	read_at: string;
	message: string;
	type: number;
	created_at: string;
}

export default function MessagesList({ userState, userDispatch }: MessagesListProps) {
	const { t } = useTranslation("common");
	const seniorSelectedId = userState.seniorSelectedId;

	const { status, data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
		["senior_messages", seniorSelectedId],
		async ({ pageParam = 0 }) => {
			const res = await http.get(`${url.SENIORS}/${seniorSelectedId}/messages?nb=10&idx=` + pageParam);
			return res.data;
		},
		{
			getPreviousPageParam: firstPage => firstPage.previousId ?? false,
			getNextPageParam: lastPage => lastPage.nextId ?? false
		}
	);

	const loadMoreButtonRef = useRef<HTMLButtonElement>(null);

	useIntersectionObserver({
		target: loadMoreButtonRef,
		onIntersect: fetchNextPage,
		enabled: hasNextPage
	});

	return (
		<>
			{status === "loading" ? (
				<Loader />
			) : status === "error" ? (
				<NoDataAvailable />
			) : (
				data &&
				data.pages.map((group, i) =>
					group.nextId > 0 ? (
						<>
							<div key={i}>
								{group.senior_messages.map((archive: MessageInterface) => (
									<MessageContainer
										key={archive.id}
										seniorSelectedId={seniorSelectedId}
										userDispatch={userDispatch}
										message={archive}
										userLocale={userState.locale}
									/>
								))}
							</div>
						</>
					) : (
						<div className="mood-card" style={{ marginTop: "2.5rem" }} key={i}>
							<p>
								<b>{t("link.__messageWarning__")}</b>
							</p>
							<p className="meta mb-5">{t("link.__privateMsgWarning__")}</p>
							<p style={{ marginTop: "1.25rem" }}>{t("link.__messageWarningOne__")}</p>
							<p style={{ marginTop: "0.75rem" }}>{t("link.__messageWarningTwo__")}</p>
							<Link to="/profile/6">
								<button type="button" className="dark-button" style={{ marginTop: "1.25rem" }}>
									{t("link.__newMsg__")}
								</button>
							</Link>
						</div>
					)
				)
			)}
			{data && data.pageParams.length > 1 ? (
				<div className="center">
					<button
						ref={loadMoreButtonRef}
						onClick={() => fetchNextPage()}
						disabled={!hasNextPage || isFetchingNextPage}
						className="light-button"
					>
						{isFetchingNextPage ? t("common.__loading__") : hasNextPage ? t("common.__more__") : null}
					</button>
					{isFetching && !isFetchingNextPage ? t("common.__loading__") : null}
				</div>
			) : null}
		</>
	);
}
