import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../../utils/Table.scss";
import http from "../../../Services/Interceptor";
import * as url from "../../../Constants/Url";
import { BsFillQuestionDiamondFill, BsChatDots, BsChatDotsFill } from "react-icons/bs";
import ModalSlideShow from "./ModalSlideShow";
import {UserStateInterface} from "../../../interfaces/UserStateInterface";
import NoDataAvailable from "../../../Layout/NoDataAvailable";
import { formatedDateFromString } from "../../../utils/Datetime";
import HeaderContainer from "../../../Layout/HeaderContainer";

interface MessageDetailsProps {
	msgId: number;
	userState: UserStateInterface;
}

interface MessageInterface {
	id: number;
	subject: string;
	message: string;
	created_at: string;
	updated_at: string;
	medias: MediasInterface[];
	type: number;
}

interface MediasInterface {
	content_type: string;
	id: number;
	path: string;
}

export default function MessageDetails({ userState, msgId }: MessageDetailsProps) {
	const { t } = useTranslation("common");
	const [msgDetails, setMsgDetails] = useState<MessageInterface[]>([]);
	const [error, setError] = useState(false);

	const seniors = userState.familyUser.seniors;
	const mySenior = seniors.find(senior => senior.id === userState.seniorSelectedId);
	const isE4linked = mySenior ? mySenior.e4linked : false;

	useEffect(() => {
		if (msgId) {
			setError(false);
			http
				.get(`${url.SENIORS}/messages/${msgId}/thread`)
				.then(res => setMsgDetails(res.data.senior_messages))
				.catch(() => setError(true));
		}
		// eslint-disable-next-line
	}, [msgId]);

	interface SlideShowInterface {
		show: boolean;
		selected: {
			id: number;
		} | null;
		img: {
			id: number;
		}[];
	}
	const [show, setShow] = useState<SlideShowInterface>({ show: false, img: [], selected: null });
	const displayMedias = (medias: MediasInterface[]) => {
		const pictures = medias.filter(media => media.content_type.split("/")[0] === "image");
		const audio = medias.filter(media => media.content_type === "audio/mpeg");
		return (
			<>
				{pictures.length > 0 ? (
					<>
						{pictures.map(img => (
							<img
								src={`/storages/seniors/messages/images/${img.id}`}
								key={img.id}
								className="fake-link"
								style={{ height: "10rem", width: "auto", margin: "1rem 1rem 0 0", display: '"inline-block' }}
								alt="e4link"
								onClick={() => setShow({ show: true, img: pictures, selected: img })}
								loading="lazy"
							/>
						))}
						<p className="meta mt-5">
							<BsFillQuestionDiamondFill color="#828282" className="nav-icon" />
							{t("link.__zoomImg__")}
						</p>
					</>
				) : null}
				{audio.length > 0 ? (
					<div className="light-card">
						{audio.map(mp3 => (
							<audio controls src={`/storages/seniors/messages/medias/${mp3.id}`} key={mp3.id} />
						))}
					</div>
				) : null}
			</>
		);
	};

	return (
		<>
			<HeaderContainer title={t("link.__privateMessage__")} />

			{error ? (
				<NoDataAvailable />
			) : (
				<div className="mt-5">
					{msgDetails.map(msg =>
						msg.type === 2 ? (
							<div className="colored-card" key={msg.id}>
								<BsChatDotsFill color="#fe7062" className="link-message-icon mr-2" style={{ transform: "scaleX(-1)" }} />
								<span className="meta">
									{t("mailbox.__sentOn__")} : {formatedDateFromString(msg.updated_at, "PPPPp", userState.locale)}
								</span>
								<div className="white-separator"></div>
								<div style={{ whiteSpace: "pre-wrap", margin: "1.5rem 0 1rem 0" }}>
									<p className="senior-message">{msg.message}</p>
								</div>
							</div>
						) : (
							<>
								<div className="mt-2"></div>
								<div className="flow-card" key={msg.id}>
									<BsChatDots color="#4a4e64" className="link-message-icon mr-2" />
									<span className="meta">
										{t("mailbox.__sentOn__")} : {formatedDateFromString(msg.created_at, "PPPPp", userState.locale)}
									</span>
									<div className="underliner mt-5"></div>
									<p>
										<b>
											{t("link.__msgObject__")} : {msg.subject}
										</b>
									</p>
									<div className="mt-5"></div>
									<div style={{ whiteSpace: "pre-wrap", margin: "1rem 0 1rem 0" }}>
										<p>{msg.message}</p>
									</div>
									{msg.medias.length > 0 ? displayMedias(msg.medias) : null}
								</div>
							</>
						)
					)}
				</div>
			)}

			{isE4linked ? (
				<div style={{ display: "flex", justifyContent: "flex-end" }} className="mt-5">
					<Link to="/postcard">
						<button type="button" className="light-button">
							+ {t("link.__postcard__")}
						</button>
					</Link>
					<Link to="/profile/6">
						<button type="button" className="dark-button">
							+ {t("common.__message__")}
						</button>
					</Link>
				</div>
			) : null}
			<ModalSlideShow show={show} setShow={setShow} />
		</>
	);
}
