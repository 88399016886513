import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCloudDownload } from "react-icons/bs";

export default function ButtonDownloadPostcard({ canvas }) {
	const { t } = useTranslation("common");
	const [postCard, setPostCard] = useState(null);

	const createPostcard = () => {
		const img = canvas.toDataURL("image/png");
		setPostCard(img);
	};

	return (
		<a href={postCard} download="postcard.png" onClick={() => createPostcard()}>
			<button className="light-button" type="button">
				<BsCloudDownload style={{ marginRight: "5px", verticalAlign: "middle" }} /> {t("common.__download__")}
			</button>
		</a>
	);
}
