import React, { useMemo } from "react";
import { useTable, useFilters, useSortBy, usePagination, useRowSelect } from "react-table";
import "./Table.scss";

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
	return (
		<div className="table-input">
			<input
				type="text"
				value={filterValue || ""}
				onChange={e => {
					setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
				}}
				placeholder={"Recherche..."}
			/>
		</div>
	);
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
	// Calculate the options for filtering
	// using the preFilteredRows
	const options = useMemo(() => {
		const options = new Set();
		preFilteredRows.forEach(row => {
			options.add(row.values[id]);
		});
		return [...options.values()];
	}, [id, preFilteredRows]);

	// Render a multi-select box
	return (
		<div className="custom-select">
			<select
				className="my-select"
				value={filterValue}
				onChange={e => {
					setFilter(e.target.value || undefined);
				}}
			>
				<option value="">Aucun filtre</option>
				{options.map((option, i) => (
					<option key={i} value={option}>
						{option}
					</option>
				))}
			</select>
		</div>
	);
}

export function Table({ columns, data, displayPaginationSelection }) {
	const filterTypes = useMemo(
		() => ({
			text: (rows, id, filterValue) => {
				return rows.filter(row => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
						: true;
				});
			}
		}),
		[]
	);

	const defaultColumn = useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter
		}),
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page, // Instead of using 'rows', we'll use page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		// pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize }
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			filterTypes,
			// We also need to pass this so the page doesn't change
			// when we edit the data.
			// autoResetPage: !skipReset,
			// autoResetSelectedRows: !skipReset,
			initialState: { pageSize: 15 }
		},
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect
	);

	return (
		<div id="table">
			<table {...getTableProps()} className="custom-table">
				<thead>
					{headerGroups.map((headerGroup, i) => (
						<tr {...headerGroup.getHeaderGroupProps()} key={i}>
							{headerGroup.headers.map((column, i) => (
								<th {...column.getHeaderProps()} key={i}>
									<div className="mb-2">
										<span {...column.getSortByToggleProps()}>
											{column.isSorted ? (column.isSortedDesc ? ">" : "<") : null}
											{column.render("Header")}
										</span>
									</div>
									{/* Render the columns filter UI */}
									<div className="filter-options">{column.canFilter ? column.render("Filter") : null}</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						// page instead of rows for pagination
						prepareRow(row); // This line is necessary to prepare the rows and get the row props from react-table dynamically

						// Each row can be rendered directly as a string using the react-table render method
						return (
							<tr {...row.getRowProps()} key={i}>
								{row.cells.map((cell, i) => {
									return (
										<td
											{...cell.getCellProps({
												style: {
													minWidth: cell.column.minWidth,
													width: cell.column.width,
													maxWitdh: cell.column.maxWidth
												}
											})}
											key={i}
										>
											{cell.render("Cell")}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="table-pagination">
				{/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
                </button>{" "} */}
				<button onClick={() => previousPage()} disabled={!canPreviousPage} className="table-button">
					{"<"}
				</button>{" "}
				<button onClick={() => nextPage()} disabled={!canNextPage} className="table-button">
					{">"}
				</button>{" "}
				{/* <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {">>"}
                </button>{" "} */}
				<span>
					Page {pageIndex + 1} of {pageOptions.length}{" "}
				</span>
				<span>
					| Aller à la page :{" "}
					<input
						type="number"
						defaultValue={pageIndex + 1}
						onChange={e => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0;
							gotoPage(page);
						}}
						style={{ width: "10rem" }}
					/>
				</span>{" "}
				{displayPaginationSelection && (
					<select
						// className="form-control form-control-sm"
						value={pageSize}
						onChange={e => {
							setPageSize(Number(e.target.value));
						}}
					>
						{[25, 50, 100].map(pageSize => (
							<option key={pageSize} value={pageSize}>
								Afficher {pageSize} entrées par page
							</option>
						))}
					</select>
				)}
			</div>
		</div>
	);
}
