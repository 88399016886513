import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import MyInputTextarea from "../../utils/form/MyInputTextarea";
import * as url from "../../Constants/Url";
import http from "../../Services/Interceptor";
import {UserStateInterface} from "../../interfaces/UserStateInterface";
import { formatedDateFromString } from "../../utils/Datetime";
import { MessageInterface } from "../../interfaces/MessagesInterface";
import { BsXLg } from "react-icons/bs";

interface CancelVisioProps {
	show: {
		show: boolean;
		info: MessageInterface | null;
	};
	setShow: Function;
	userDispatch: Function;
	seniorSelectedId: number;
	userState: UserStateInterface;
}

interface InputValues {
	motif: string;
}

const CancelVisio = ({ show, setShow, userDispatch, seniorSelectedId, userState }: CancelVisioProps) => {
	const { t } = useTranslation("common");
	const { handleSubmit } = useFormContext<InputValues>();
	const msgId = show.info?.id;
	const visio = show.info?.visio_datetime;
	const [error, setError] = useState(false);

	const onSubmit = (values: InputValues) => {
		setError(false);
		const data = {
			message_id: msgId,
			message: values.motif
		};
		http
			.post(`${url.BASEURL}/${url.THREAD}/visio/cancel`, data)
			.then(() => {
				if (show.info?.unread) {
					userDispatch({
						type: "READ_MESSAGE",
						payload: { senior: seniorSelectedId, nb: 1 }
					});
				}
				// msgDispatch({
				// 	type: "FAMILY_CANCEL_VISIO",
				// 	payload: msgId
				// });
				setShow({ show: false, info: null });
			})
			.catch(() => setError(true));
	};

	return (
		<>
			<h4 className="center">
				<strong>{t("mailbox.__cancelVisio__")}</strong>
			</h4>
			<p>
				{t("mailbox.__cancelThisVisio__")} :
				<br />
				<b>{visio ? formatedDateFromString(visio, "PPPPp", userState.locale) : null} </b>
			</p>

			{error ? (
				<p className="alert">
					<span className="right close-btn">
						<BsXLg style={{ verticalAlign: "middle" }} role="button" onClick={() => setError(false)} />
					</span>
					{t("common.__errorOccured__")}
				</p>
			) : null}
			<form onSubmit={handleSubmit(onSubmit)}>
				<MyInputTextarea label="Veuillez saisir un motif :" name="motif" behavior={{ required: true }} rows="4" />
				<button className="dark-button">{t("common.__send__")}</button>
			</form>
		</>
	);
};

export default CancelVisio;
