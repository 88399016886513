import { DocumentInterface, MessageInterface } from "../../interfaces/MessagesInterface";

interface ActionProps {
	type: string;
	payload: any;
}

interface StateProps {
	loading: boolean;
	error: boolean;
	success: boolean;
	msg: string | null;
	messages: MessageInterface[];
	documents: DocumentInterface[];
	seniorDocuments: DocumentInterface[];
}

const MailBoxReducer = (state: StateProps, action: ActionProps) => {
	switch (action.type) {
		case "LOADING":
			return {
				...state,
				loading: true
			};

		case "GET_MESSAGES":
			return {
				...state,
				loading: false,
				messages: action.payload
			};

		case "DISPLAY_ERROR":
			return {
				...state,
				error: true,
				success: false,
				msg: action.payload
			};
		case "DISPLAY_SUCCESS":
			return {
				...state,
				success: true,
				error: false
			};

		case "MARK_AS_READ": {
			const msgId = action.payload;
			const messagesUpdated = state.messages.map(msg => {
				return {
					...msg,
					unread: msgId === msg.id ? false : msg.unread
				};
			});
			return {
				...state,
				messages: messagesUpdated
			};
		}

		case "FAMILY_ACCEPT_VISIO": {
			const msgId = action.payload;
			const messagesUpdated = state.messages.map(msg => {
				return {
					...msg,
					type: msgId === msg.id ? 14 : msg.type,
					unread: msgId === msg.id ? false : msg.unread
				};
			});
			return {
				...state,
				messages: messagesUpdated
			};
		}

		case "FAMILY_REJECT_VISIO": {
			const msgId = action.payload;
			const messagesUpdated = state.messages.map(msg => {
				return {
					...msg,
					type: msgId === msg.id ? 15 : msg.type,
					unread: msgId === msg.id ? false : msg.unread
				};
			});
			return {
				...state,
				messages: messagesUpdated
			};
		}

		case "FAMILY_CANCEL_VISIO": {
			const msgId = action.payload;
			const messagesUpdated = state.messages.map(msg => {
				return {
					...msg,
					type: msgId === msg.id ? 6 : msg.type,
					unread: msgId === msg.id ? false : msg.unread
				};
			});
			return {
				...state,
				messages: messagesUpdated
			};
		}

		case "RESET_ERROR":
			return {
				...state,
				error: false
			};

		case "RESET_ES":
			return {
				...state,
				error: false,
				success: false,
				msg: null
			};
		case "GET_DOCUMENTS":
			return {
				...state,
				documents: action.payload,
				error: false
			};
		case "GET_PERSONAL_DOCUMENTS":
			return {
				...state,
				seniorDocuments: action.payload,
				error: false
			};
		case "OPEN_DOCUMENT": {
			const updatedDocument = {
				...action.payload,
				unread: false
			};
			const documentUpdated = state.documents.map(document => {
				if (document.id === updatedDocument.id) {
					return updatedDocument;
				}
				return document;
			});
			return {
				...state,
				documents: documentUpdated
			};
		}

		case "OPEN_PERSONAL_DOCUMENT": {
			const updatedDocument = {
				...action.payload,
				unread: false
			};
			const documentUpdated = state.seniorDocuments.map(document => {
				if (document.id === updatedDocument.id) {
					return updatedDocument;
				}
				return document;
			});
			return {
				...state,
				seniorDocuments: documentUpdated
			};
		}
		default:
			return state;
	}
};

export default MailBoxReducer;
