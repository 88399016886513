import React from "react";
import { useTranslation } from "react-i18next";
import * as constants from "../../Constants/MessagesConstants";
import {UserStateInterface} from "../../interfaces/UserStateInterface";
import { MessageInterface } from "../../interfaces/MessagesInterface";
import NoDataAvailable from "../../Layout/NoDataAvailable";
import { formatedDateFromString } from "../../utils/Datetime";

interface NotificationDetailsProps {
	openResponseForm: boolean;
	setOpenResponseForm: Function;
	thread: MessageInterface[];
	error: boolean;
	subject: string;
	userState: UserStateInterface;
}

const NotificationDetails = ({
	userState,
	openResponseForm,
	setOpenResponseForm,
	thread,
	error,
	subject
}: NotificationDetailsProps) => {
	const { t } = useTranslation("common");

	const handleMsgClassName = (type: number, unread: boolean, length: number) => {
		if (length === 1) {
			return "flow-card";
		} else if (type === constants.MSG_FROM_FAMILY_TO_USER && !unread) {
			return "family-card";
		} else if (type === constants.MSG_FROM_FAMILY_TO_USER && unread) {
			return "family-card-unread";
		} else if (type === constants.MSG_FROM_USER_TO_FAMILY && !unread) {
			return "response-card";
		} else if (type === constants.MSG_FROM_USER_TO_FAMILY && unread) {
			return "response-card-unread";
		} else {
			return "response-card";
		}
	};

	return (
		<>
			<div className="space-between">
				<p>
					{openResponseForm ? (
						t("mailbox.__thread__")
					) : (
						<b>
							{t("link.__msgObject__")} : {subject}
						</b>
					)}
				</p>
				{thread.length > 0 && thread[thread.length - 1].type === constants.WELCOME_MESSAGE ? null : openResponseForm ? null : (
					<button type="button" className="dark-button" onClick={() => setOpenResponseForm(true)}>
						{t("mailbox.__answer__")}
					</button>
				)}
			</div>
			{error ? (
				<NoDataAvailable />
			) : (
				<div className="mt-2">
					<ul className="msgDetailsList">
						{thread.map(msg => (
							<li key={msg.id}>
								<div className={handleMsgClassName(msg.type, msg.unread, thread.length)}>
									<p className="meta-date right">{formatedDateFromString(msg.datetime, "PPPPp", userState.locale)}</p>
									{msg.type === constants.MSG_FROM_USER_TO_FAMILY ||
									msg.type === constants.DUPLICATED_MSG_FROM_USER_TO_FAMILY ||
									msg.type === constants.MULTI_MSG_FROM_USER_TO_FAMILY ? (
										<p className="meta">
											{t("common.__from__")} : {msg.user.first_name} {msg.user.last_name}
										</p>
									) : msg.type === constants.WELCOME_MESSAGE ? (
										<p className="meta">{t("home.__home__")}</p>
									) : (
										<p className="meta">
											{t("common.__from__")} : {msg.family_user.first_name} {msg.family_user.last_name}
										</p>
									)}
									<div className="meta-underliner"></div>
									<p className="bullet-message mt-2">{msg.message}</p>
								</div>
							</li>
						))}
					</ul>
				</div>
			)}
		</>
	);
};

export default NotificationDetails;
