import React from "react";
import { useTranslation } from "react-i18next";
import { StoryInterface } from "../../../interfaces/StoryInterface";

interface Props {
	deleteStory: Function;
	story: StoryInterface | null;
	setShow: Function;
}

export default function RemoveStoryConfirmation({ deleteStory, story, setShow }: Props) {
	const { t } = useTranslation("common");

	const confirmDeleteStory = () => {
		if (!story) return;
		deleteStory(story.life_story, story.id);
		setShow({ show: false, info: null });
	};

	return story ? (
		<div>
			<h3>{t("link.__confirmationRemoval__")}</h3>
			<p>
				<b>{t("common.__message__")} : </b>
				{story.life_story}
			</p>
			<br />
			<button type="button" className="light-button" onClick={() => setShow({ show: false, info: null })}>
				{t("common.__cancel__")}
			</button>
			<button type="button" className="red-button" onClick={confirmDeleteStory}>
				{t("common.__delete__")}
			</button>
		</div>
	) : null;
}
