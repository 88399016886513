import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../App/App.scss";
import ForgotPassword from "../components/UserSettings/ForgotPassword";
import Footer from "../Layout/Footer";

const LostPassword = () => {
	const { t } = useTranslation("common");

	return (
		<>
			<div>
				{process.env.REACT_APP_EFORLINK_LOGO === "true" ? (
					<Link to="/">
						<img src="/img/Logo-eforlink.png" alt="Logo eforlink" className="home-logo" />
					</Link>
				) : (
					<Link to="/">
						<img src="/img/amd/amdlink.png" alt="Logo AMD link" className="home-logo" />
					</Link>
				)}
				<div id="login-module">
					<h1>{t("home.__slogan__")}</h1>
					<p className="slogan">{t("home.__sloganKeywords__")}</p>
				</div>
			</div>

			<section id="login-form">
				<ForgotPassword displayTitle={true} />
			</section>
			<Footer />
		</>
	);
};

export default LostPassword;
