import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { BsGear, BsBoxArrowRight, BsXCircle } from "react-icons/bs";
import { AiOutlineQrcode } from "react-icons/ai";
import QrCode from "./QrCode";
import useLogout from "../../hooks/useLogout";
import useWindowSize from "../../hooks/useWindowSize";
interface UserNavProps {
	userDispatch: Function;
}

interface QrCodeModalInterface {
	show: boolean;
	setShow: Function;
}

export default function UserNav({ userDispatch }: UserNavProps) {
	const { t } = useTranslation("common");
	const { component } = useParams<{ component?: string }>();
	const [show, setShow] = useState(false);
	const logout = useLogout({ userDispatch });
	const size = useWindowSize();

	const QrCodeModal = ({ show, setShow }: QrCodeModalInterface) => {
		return show ? (
			<div className="overlay">
				<div className="modal">
					<div className="modal-close" role="button" onClick={() => setShow(false)}>
						<BsXCircle />
					</div>
					<div className="modal-body">
						<QrCode />
					</div>
				</div>
			</div>
		) : null;
	};

	return (
		<>
			<div className="responsive-nav-link">
				<Link
					to={`/settings/myaccount`}
					className={
						component === "myaccount" || component === "editmyaccount" ? "link-nav-button-hover center" : "link-nav-button center"
					}
				>
					<BsGear color="#4A4E64" className="icon-actions center" />
					{size.width > 500 ? <p className="meta center">{t("common.__myAccount__")}</p> : null}
				</Link>
				<div className="link-nav-button center" onClick={() => setShow(true)}>
					<AiOutlineQrcode color="#4A4E64" className="icon-actions center" />
					{size.width > 500 ? <p className="meta center">{t("user.__displayQrCode__")}</p> : null}
				</div>

				<div className="link-nav-button center" onClick={logout}>
					<BsBoxArrowRight color="#4A4E64" className="icon-actions center" />
					{size.width > 500 ? <p className="meta center">{t("user.__logout__")}</p> : null}
				</div>
			</div>
			<QrCodeModal show={show} setShow={setShow} />
		</>
	);
}
