import React from "react";
import i18n from "../../../i18n/i18next";
import MicRecorder from "mic-recorder-to-mp3";
import { BsBackspaceFill, BsFillCircleFill, BsFillSquareFill } from "react-icons/bs";
import Chrono from "./Chrono";

const Mp3Recorder = new MicRecorder({ bitRate: 64 });
class VoiceRecorderForAndroid extends React.Component {
	constructor(props) {
		super(props);
		window.AudioContext = window.AudioContext || window.webkitAudioContext;

		this.state = {
			isRecording: false,
			isPaused: false,
			blobURL: [],
			isBlocked: false,
			isStopped: true,
			file: null
		};
	}

	startRecording = () => {
		if (this.state.isBlocked) {
			console.log("Please give permission for the microphone to record audio.");
		} else {
			Mp3Recorder.start()
				.then(() => {
					this.setState({ isRecording: true });
					this.setState({ isStopped: false });
				})
				.catch(e => console.error(e));
		}
	};

	stopRecording = () => {
		this.setState({ isRecording: false });
		this.setState({ isStopped: true });
		Mp3Recorder.stop()
			.getMp3()
			.then(async ([buffer, blob]) => {
				const blobSRC = URL.createObjectURL(blob);
				this.setState({
					blobURL: [...this.state.blobURL, blobSRC],
					isRecording: false
				});
				const fileToSave = new File(buffer, `e4link_${this.props.index}.mp3`, {
					type: blob.type,
					lastModified: Date.now()
				});
				this.setState({
					file: fileToSave
				});
				this.props.setAudioBlob([...this.props.audioBlob, fileToSave]);
			})
			.catch(e => console.log(e));
	};

	checkPermissionForAudio = () => {
		if (navigator.mediaDevices === undefined) {
			navigator.mediaDevices = {};
		}
		if (navigator.mediaDevices.getUserMedia === undefined) {
			navigator.mediaDevices.getUserMedia = function (constraints) {
				// First get ahold of the legacy getUserMedia, if present
				var getUserMedia =
					// navigator.getUserMedia ||
					navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

				// Some browsers just don't implement it - return a rejected promise with an error
				// to keep a consistent interface
				if (!getUserMedia) {
					return Promise.reject(new Error("getUserMedia is not implemented in this browser"));
				}
				// Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
				return new Promise(function (resolve, reject) {
					getUserMedia.call(navigator, constraints, resolve, reject);
				});
			};
		}
		navigator.mediaDevices
			.getUserMedia({ audio: true })
			.then(stream => {
				console.log(stream);
				this.setState({ isBlocked: false });
			})
			.catch(err => {
				this.setState({ isBlocked: true });
				console.log("Please give permission for the microphone to record audio.");
				console.log(err.name + ": " + err.message);
			});
	};

	componentDidMount() {
		this.checkPermissionForAudio();
	}

	removeAudio = audioBlob => {
		this.props.removeAudioFile(audioBlob, this.props.index);
		this.setState({
			blobURL: [],
			file: null
		});
	};

	render() {
		const { blobURL } = this.state;

		return (
			<div>
				{blobURL.length > 0 ? (
					<>
						<div className="mood-card">
							<BsBackspaceFill
								className="red fake-link right"
								style={{ height: "1.5rem", width: "auto", verticalAlign: "auto", margin: "0 0 1rem 1rem" }}
								role="button"
								onClick={() => this.removeAudio(this.state.file)}
							/>
							<p>Ecoutez votre enregistrement :</p>
							<audio controls="controls" src={blobURL || ""} />
						</div>
					</>
				) : (
					<div className="mood-card">
						{this.state.isBlocked ? i18n.t("common:link.__audioError__") : null}
						<button type="button" onClick={this.startRecording} className="light-button" disabled={this.state.isRecording}>
							<BsFillCircleFill
								style={
									this.state.isRecording
										? { color: "red", verticalAlign: "middle", marginRight: "0.5rem", height: "1.1rem", width: "auto" }
										: { verticalAlign: "middle", marginRight: "0.5rem", height: "1.1rem", width: "auto" }
								}
							/>
							Démarrer l'enregistrement
						</button>
						<button
							onClick={this.stopRecording}
							className="light-button"
							disabled={!this.state.isRecording}
							style={{ marginRight: "1rem" }}
							type="button"
						>
							<BsFillSquareFill style={{ verticalAlign: "middle", marginRight: "0.5rem", height: "1rem", width: "auto" }} />
							Stop
						</button>
						<Chrono isRecording={this.state.isRecording} isStopped={this.state.isStopped} />
					</div>
				)}
			</div>
		);
	}
}

export default VoiceRecorderForAndroid;
