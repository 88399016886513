import React, { useState, useEffect } from "react";
import "./Weather.scss";
import { Row, Col } from "react-grid-system";
import { getWeatherSentence, getWeatherIcon } from "./WeatherData";
import http from "../../Services/Interceptor";
import * as constant from "../../Constants/Url";

interface WeatherOfTheDayProps {
	homeId: number;
}

export default function WeatherOfTheDay({ homeId }: WeatherOfTheDayProps) {
	const [todayWeather, setTodayWeather] = useState({
		icon_id: 0,
		temp: null
	});

	useEffect(() => {
		const fetchWeatherOfTheDay = () => {
			http.get(`${constant.HOMES}/${homeId}/weather`).then(res => {
				setTodayWeather({
					icon_id: res.data.weather.icon_id,
					temp: res.data.weather.temp
				});
			});
		};
		if (homeId) {
			fetchWeatherOfTheDay();
		}
	}, [homeId]);

	return todayWeather.temp ? (
		<Row>
			<Col xs={6} sm={6} md={6} lg={6}>
				<div className="center">
					<img src={getWeatherIcon(todayWeather.icon_id)} className="weather-icon" alt="météo du jour" />
				</div>
			</Col>
			<Col xs={6} sm={6} md={6} lg={6}>
				<h2>{todayWeather.temp}°</h2>
				<p className="meta weatherSentence">{getWeatherSentence(todayWeather.icon_id)}</p>
			</Col>
		</Row>
	) : null;
}
