import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import HomeLogo from "./HomeLogo";
import {UserStateInterface} from "../../interfaces/UserStateInterface";

interface HomeInfoProps {
	userState: UserStateInterface;
	seniorHome: {
		address: string;
		group_id: number;
		id: number;
		latitude: number;
		longitude: number;
		name: string;
		url: string;
		phone: string;
	};
}
interface MyRoomInterface {
	id: number;
	name: string;
}

interface MyHomeUnitInterface {
	id: number;
	name: string;
}

interface SupervisorsInterface {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
	phone: string;
}

const HomeInfo = ({ userState, seniorHome }: HomeInfoProps) => {
	const { t } = useTranslation("common");
	const seniorSelectedId = userState.seniorSelectedId;
	const [myRoom, setMyRoom] = useState<MyRoomInterface | null>(null);
	const [myHomeUnit, setMyHomeUnit] = useState<MyHomeUnitInterface | null>(null);
	const [supervisors, setSupervisors] = useState<SupervisorsInterface[]>([]);

	useEffect(() => {
		if (seniorSelectedId) {
			http
				.get(`${url.BASEURL}/seniors/${seniorSelectedId}?supervisors=true`)
				.then(res => setSupervisors(res.data.senior.supervisors))
				.catch(() => setSupervisors([]));
		}
	}, [seniorSelectedId]);

	useEffect(() => {
		if (seniorSelectedId) {
			http.get(`${url.SENIORS}/${seniorSelectedId}?room=true&home_unit=true`).then(res => {
				setMyHomeUnit(res.data.senior.home_unit);
				setMyRoom(res.data.senior.room);
			});
		}
	}, [seniorSelectedId]);

	return (
		<div className="center">
			<div className="mb-5">
				<div className="card">
					<HomeLogo seniorHome={seniorHome} />
					<p className="mt-2">{seniorHome.address}</p>
					<p>
						{t("common.__phone__")} : {seniorHome.phone}
					</p>
					<p className="meta">
						<a href={`${seniorHome.url}`}>{seniorHome.url}</a>
					</p>
				</div>
			</div>
			{myHomeUnit ? (
				<h3>
					{t("home.__homeUnit__")} : {myHomeUnit.name}
				</h3>
			) : null}
			{myRoom ? (
				<h3>
					{t("home.__room__")} : {myRoom.name}
				</h3>
			) : null}
			{supervisors.length > 0
				? supervisors.map(supervisor => (
						<div key={supervisor.id} className="mt-5">
							<p>
								<strong>
									{supervisor.first_name} {supervisor.last_name}
								</strong>
							</p>
							<p>
								{t("common.__phone__")} : <a href="tel:{supervisor.phone}">{supervisor.phone}</a>
							</p>
							<p>
								{t("common.__mail__")} :{" "}
								<a href="mailto:{supervisor.email}" target="_blank" rel="noreferrer">
									{supervisor.email}
								</a>
							</p>
						</div>
				  ))
				: null}
		</div>
	);
};
export default HomeInfo;
