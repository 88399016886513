import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-grid-system";
import ButtonSendPostcard from "./ButtonSendPostcard";
import ButtonSendToPhotoframe from "./ButtonSendToPhotoframe";
import ButtonColorPicker from "./ButtonColorPicker";
import { BsCamera } from "react-icons/bs";
import ButtonDownloadPostcard from "./ButtonDownloadPostcard";

export default function ThreePictsTemplate({ seniorSelectedId, setNextStep, setError }) {
	const { t } = useTranslation("common");
	const canvasRef = useRef(null);
	const [title, setTitle] = useState(null);
	const [messages, setMessages] = useState("");
	const [signature, setSignature] = useState("");
	const [color, setColor] = useState({
		title: "#9e9e9e",
		messages: "#9e9e9e",
		signature: "#9e9e9e",
		background: "#e9e9e9"
	});
	const [firstInputFile, setFirstInputFile] = useState(null);
	const [secondInputFile, setSecondInputFile] = useState(null);
	const [thirdInputFile, setThirdInputFile] = useState(null);
	const [size, setSize] = useState({ title: null, messages: null, signature: null });

	const wrapText = (context, text, x, y, maxWidth, lineHeight) => {
		var words = text.split(" ");
		var line = "";

		for (var n = 0; n < words.length; n++) {
			var testLine = line + words[n] + " ";
			var metrics = context.measureText(testLine);
			var testWidth = metrics.width;
			if (testWidth > maxWidth && n > 0) {
				context.fillText(line, x, y);
				line = words[n] + " ";
				y += lineHeight;
			} else {
				line = testLine;
			}
		}
		context.fillText(line, x, y);
	};

	const position = (imgPosition, canvasWidth) => {
		if (imgPosition === 1) {
			return canvasWidth;
		} else if (imgPosition === 2) {
			return canvasWidth * 2;
		} else {
			return 0;
		}
	};

	const fitCanvas = (img, canvas, ctx, imgPosition) => {
		const canvasWidth = canvas.width / 3;
		if (img.width > img.height) {
			const ratio = img.width / img.height;
			const startingPoint = img.width > canvas.width ? (img.width - canvas.width) / 3 : 0;
			return ctx.drawImage(
				img,
				startingPoint,
				0,
				img.width / ratio,
				img.height,
				position(imgPosition, canvasWidth),
				32,
				canvasWidth - 2,
				canvasWidth
			);
		} else if (img.width < img.height) {
			const ratio = img.height / img.width;
			const startingPoint = img.height > canvas.height ? (img.height - canvas.height) / 3 : 0;
			return ctx.drawImage(
				img,
				0,
				startingPoint,
				img.width,
				img.height / ratio,
				position(imgPosition, canvasWidth),
				32,
				canvasWidth - 2,
				canvasWidth
			);
		} else {
			return ctx.drawImage(
				img,
				0,
				0,
				img.width,
				img.height,
				position(imgPosition, canvasWidth),
				32,
				canvasWidth - 2,
				canvasWidth
			);
		}
	};

	useEffect(() => {
		let mounted = true;

		const canvas = canvasRef.current;
		const ratio = Math.ceil(window.devicePixelRatio);
		canvas.width = 1024 * ratio;
		canvas.height = 760 * ratio;
		canvas.style.letterSpacing = "0.5px";
		const ctx = canvas.getContext("2d");

		ctx.fillStyle = "white";
		ctx.fillRect(0, 0, canvas.width, canvas.height);
		ctx.fill();

		ctx.fillStyle = color.background;
		ctx.fillRect(0, 30, canvas.width, canvas.width / 3 + 4);
		ctx.fill();

		ctx.strokeRect(0, 0, canvas.width, canvas.height);

		if (mounted && title) {
			ctx.font = size.title ? `${size.title} 36pt Courrier` : "36pt Courrier";
			ctx.fillStyle = color.title;
			ctx.textBaseline = "middle";
			ctx.textAlign = "center";
			const canvasHeight = canvas.height / 2 + 50;
			ctx.fillText(title, canvas.width / 2, canvasHeight);
		}

		if (mounted && messages) {
			ctx.font = size.messages ? `${size.messages} 26pt Courrier` : "26pt Courrier";
			ctx.fillStyle = color.messages;
			ctx.textBaseline = "middle";
			ctx.textAlign = "center";
			const canvasHeight = canvas.height / 2 + 125;
			wrapText(ctx, messages, canvas.width / 2, canvasHeight, canvas.width - 100, 35);
		}

		if (mounted && signature) {
			ctx.font = size.signature ? `${size.signature} 20pt Courrier` : "20pt Courrier";
			ctx.fillStyle = color.signature;
			ctx.textBaseline = "middle";
			ctx.textAlign = "center";
			const canvasHeight = canvas.height - 50;
			ctx.fillText(signature, canvas.width / 2, canvasHeight);
		}

		if (mounted && firstInputFile) {
			const reader = new FileReader();
			reader.onload = function (firstInputFile) {
				let img = new Image();
				img.onload = function () {
					fitCanvas(img, canvas, ctx, 0);
				};
				img.src = firstInputFile.target.result;
			};
			reader.readAsDataURL(firstInputFile.target.files[0]);
		}
		if (mounted && secondInputFile) {
			const reader = new FileReader();
			reader.onload = function (secondInputFile) {
				let img = new Image();
				img.onload = function () {
					fitCanvas(img, canvas, ctx, 1);
				};
				img.src = secondInputFile.target.result;
			};
			reader.readAsDataURL(secondInputFile.target.files[0]);
		}
		if (mounted && thirdInputFile) {
			const reader = new FileReader();
			reader.onload = function (thirdInputFile) {
				let img = new Image();
				img.onload = function () {
					fitCanvas(img, canvas, ctx, 2);
				};
				img.src = thirdInputFile.target.result;
			};
			reader.readAsDataURL(thirdInputFile.target.files[0]);
		}
		return () => (mounted = false);
	}, [title, color, size, messages, firstInputFile, secondInputFile, thirdInputFile, signature]);

	return (
		<Row>
			<Col xs={2} sm={1} md={1} lg={1}>
				<p className="step-number mt-5">2</p>
			</Col>
			<Col xs={10} sm={11} md={11} lg={11}>
				<p className="mt-5">
					<b>{t("link.__customizeCard__")}</b>
				</p>
			</Col>
			<Col xs={3} sm={2} md={2} lg={2}>
				<ButtonColorPicker color={color} setColor={setColor} elt={"background"} displaySize={false} />
			</Col>
			<Col xs={3} sm={3} md={3} lg={3}>
				<input
					type="file"
					id="customFileOne"
					accept=".jpg, .jpeg, .png"
					className="inputfile"
					onChange={e => setFirstInputFile(e)}
				/>
				<label htmlFor="customFileOne">
					<BsCamera className="nav-icon" /> {t("link.__selectPict__")}
				</label>
			</Col>
			<Col xs={3} sm={3} md={3} lg={3}>
				<input
					type="file"
					id="customFileTwo"
					accept=".jpg, .jpeg, .png"
					className="inputfile"
					onChange={e => setSecondInputFile(e)}
				/>
				<label htmlFor="customFileTwo">
					<BsCamera className="nav-icon" /> {t("link.__selectPict__")}
				</label>
			</Col>
			<Col xs={3} sm={3} md={3} lg={3}>
				<input
					type="file"
					id="customFileThree"
					accept=".jpg, .jpeg, .png"
					className="inputfile"
					onChange={e => setThirdInputFile(e)}
				/>
				<label htmlFor="customFileThree">
					<BsCamera className="nav-icon" /> {t("link.__selectPict__")}
				</label>
			</Col>
			<Col xs={0} sm={1} md={1} lg={1}></Col>
			<Col xs={2} sm={2} md={2} lg={2}>
				<ButtonColorPicker
					color={color}
					setColor={setColor}
					elt={"title"}
					size={size}
					setSize={setSize}
					displaySize={true}
				/>
			</Col>
			<Col xs={10} sm={10} md={10} lg={10}>
				<input type="text" placeholder={t("link.__addTitle__")} onChange={e => setTitle(e.target.value)} maxLength="40" />
			</Col>

			<Col xs={2} sm={2} md={2} lg={2}>
				<ButtonColorPicker
					color={color}
					setColor={setColor}
					elt={"messages"}
					size={size}
					setSize={setSize}
					displaySize={true}
				/>
			</Col>

			<Col xs={10} sm={10} md={10} lg={10}>
				<textarea
					placeholder={t("link.__addMessage__")}
					onChange={e => setMessages(e.target.value)}
					rows="5"
					maxLength="300"
				/>
			</Col>
			<Col xs={2} sm={2} md={2} lg={2}>
				<ButtonColorPicker
					color={color}
					setColor={setColor}
					elt={"signature"}
					size={size}
					setSize={setSize}
					displaySize={true}
				/>
			</Col>
			<Col xs={10} sm={10} md={10} lg={10}>
				<input
					type="text"
					placeholder={t("link.__signature__")}
					onChange={e => setSignature(e.target.value)}
					maxLength="75"
				/>
			</Col>
			<Col xs={2} sm={2} md={2} lg={2}>
				<p className="step-number mt-5">3</p>
			</Col>
			<Col xs={10} sm={10} md={10} lg={10}>
				<p className="mt-5">
					<b>{t("link.__previewYourCard__")}</b>
				</p>
			</Col>
			<Col xs={12} sm={12} md={12} lg={12}>
				<canvas ref={canvasRef} style={{ width: "100%", marginTop: "10px" }} />
			</Col>
			<Col xs={2} sm={1} md={1} lg={1}>
				<p className="step-number mt-5">4</p>
			</Col>
			<Col xs={10} sm={11} md={11} lg={11}>
				<p className="mt-5">
					<b>{t("common.__share__")}</b>
				</p>
			</Col>
			<Col xs={12} sm={12} md={12} lg={12}>
				<div className="center">
					<button type="button" className="light-button" onClick={() => setNextStep(false)}>
						{t("common.__back__")}
					</button>

					<ButtonDownloadPostcard canvas={canvasRef.current} />
					<ButtonSendToPhotoframe seniorSelectedId={seniorSelectedId} canvasRef={canvasRef} setError={setError} />
					<ButtonSendPostcard seniorSelectedId={seniorSelectedId} canvasRef={canvasRef} setError={setError} />
				</div>
			</Col>
			<Col xs={12} sm={12} md={12} lg={12}>
				<div className="space"></div>
			</Col>
		</Row>
	);
}
