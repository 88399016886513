import React from "react";
import { useTranslation } from "react-i18next";
import { BsExclamationDiamond } from "react-icons/bs";
import { useHistory } from "react-router-dom";

export default function NoDataAvailable() {
	const { t } = useTranslation("common");
	const history = useHistory();

	return (
		<div className="mt-5">
			<h3>
				<BsExclamationDiamond
					style={{ verticalAlign: "middle", color: "#00a0e3", height: "1.75rem", width: "auto", marginRight: "0.7rem" }}
				/>
				{t("common.__noData__")}
			</h3>
			<button className="light-button mt-5" onClick={() => history.goBack()}>
				{t("common.__back__")}
			</button>
		</div>
	);
}
