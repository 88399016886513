import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { BsPersonCircle, BsChatDots, BsCamera, BsImages } from "react-icons/bs";
import useWindowSize from "../../hooks/useWindowSize";

interface LinkNavProps {
	isPrincipal: boolean;
}

export default function LinkNav({ isPrincipal }: LinkNavProps) {
	const { t } = useTranslation("common");
	const { component } = useParams<{ component?: string }>();
	const size = useWindowSize();

	return (
		<div className="responsive-nav-link">
			<Link
				to={{ pathname: `/profile/1` }}
				className={component === "1" || component === "7" ? "link-nav-button-hover center" : "link-nav-button center"}
			>
				<BsChatDots className="icon-actions center" />
				{size.width > 500 ? <p className="meta center">{t("link.__privateMessage__")}</p> : null}
			</Link>

			<Link
				to={{ pathname: `/profile/3` }}
				className={component === "3" ? "link-nav-button-hover center" : "link-nav-button center"}
			>
				<BsImages color="#4A4E64" className="icon-actions center" />
				{size.width > 500 ? <p className="meta center">{t("link.__photoFrame__")}</p> : null}
			</Link>
			{isPrincipal ? (
				<Link
					to={{ pathname: `/profile/5` }}
					className={component === "5" ? "link-nav-button-hover center" : "link-nav-button center"}
				>
					<BsCamera color="#4A4E64" className="icon-actions center" />
					{size.width > 500 ? <p className="meta center">{t("link.__avatar__")}</p> : null}
				</Link>
			) : null}
			<Link
				to={{ pathname: `/profile/2` }}
				className={component === "2" ? "link-nav-button-hover center" : "link-nav-button center"}
			>
				<BsPersonCircle color="#4A4E64" className="icon-actions center" />
				{size.width > 500 ? <p className="meta center">{t("link.__bio__")}</p> : null}
			</Link>
		</div>
	);
}
