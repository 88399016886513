import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatedDate, formatedDateFromString } from "./Datetime";
import * as types from "../Constants/MessagesConstants";
import { BsCheckLg, BsClock, BsFillReplyFill, BsEnvelope, BsArrowRightCircle, BsEnvelopeOpen } from "react-icons/bs";
import { FiVideoOff, FiVideo } from "react-icons/fi";
import http from "../Services/Interceptor";
import * as url from "../Constants/Url";
import { MessageInterface } from "../interfaces/MessagesInterface";

export const displayMessages = (message: MessageInterface, userLocale: string) => {
	const { t } = useTranslation("common");

	const type = message.type;
	const visio_datetime = message.visio_datetime;
	const unread = message.unread;
	const id = message.id;
	const subject = message.subject;
	const body = message.message;

	switch (type) {
		case types.USER_ACCEPT_VISIO:
			return (
				<p>
					{t("mailbox.__visioAccepted__")} : {t("mailbox.__appointment__")}{" "}
					{visio_datetime ? (
						<>
							: <b> {formatedDateFromString(visio_datetime, "PPPp", userLocale)}</b>
						</>
					) : null}
				</p>
			);
		case types.FAMILY_ACCEPT_VISIO:
			return (
				<p>
					{t("mailbox.__familyAcceptVisio__")}{" "}
					{visio_datetime ? (
						<>
							: <b>{formatedDateFromString(visio_datetime, "PPPp", userLocale)}</b>
						</>
					) : null}
				</p>
			);
		case types.FAMILY_REJECT_VISIO:
			return (
				<p>
					{t("mailbox.__visioRefusedByFamily__")}{" "}
					{visio_datetime ? <>: {formatedDateFromString(visio_datetime, "PPPp", userLocale)}</> : null}
					<br />
					<span className={unread ? "visio-comment unread" : "visio-comment read"}>
						<b>{t("common.__message__")}</b> : {body}
					</span>
				</p>
			);
		case types.FAMILY_CANCEL_VISIO:
			return (
				<p>
					{t("mailbox.__visioCanceledSuccess__")}{" "}
					{visio_datetime ? <>: {formatedDateFromString(visio_datetime, "PPPp", userLocale)}</> : null}
					<br />
					<span className={unread ? "visio-comment unread" : "visio-comment read"}>
						<b>{t("common.__message__")}</b> : {body}
					</span>
				</p>
			);
		case types.USER_CANCEL_VISIO:
			return (
				<p>
					{t("mailbox.__visioCanceledByUser__", { date: formatedDateFromString(visio_datetime, "PPPp", userLocale) })}
					<br />
					<span className={unread ? "visio-comment unread" : "visio-comment read"}>
						<b>{t("common.__message__")}</b> : {body}
					</span>
				</p>
			);
		case types.USER_REJECT_VISIO:
			return (
				<p>
					{t("mailbox.__visioRefused__")}{" "}
					{visio_datetime ? <>: {formatedDateFromString(visio_datetime, "PPPp", userLocale)}</> : null}
					<br />
					<span className={unread ? "visio-comment unread" : "visio-comment read"}>
						<b>{t("common.__message__")}</b> : {body}
					</span>
				</p>
			);
		case types.FAMILY_ASK_FOR_VISIO:
			return (
				<p>
					{t("mailbox.__visioSent__", { date: formatedDateFromString(visio_datetime, "PPPPp", userLocale) })}
					<br />
					<span className={unread ? "visio-comment unread" : "visio-comment read"}>{t("mailbox.__requestPending__")}</span>
				</p>
			);
		case types.USER_ASK_FOR_VISIO:
			return (
				<p>
					{t("mailbox.__visioRequestReceived__")}{" "}
					{visio_datetime ? (
						<>
							: <b>{formatedDateFromString(visio_datetime, "PPPp", userLocale)}</b>
						</>
					) : null}
				</p>
			);
		case types.MSG_FROM_FAMILY_TO_USER:
		case types.MSG_FROM_USER_TO_FAMILY:
		case types.DUPLICATED_MSG_FROM_USER_TO_FAMILY:
			return (
				<Link to={`/messages/1/thread/${id}`}>
					<p className="blue mt-2">
						<strong>
							{t("link.__msgObject__")} : {subject}
						</strong>
					</p>
				</Link>
			);

		case types.WELCOME_MESSAGE:
			return (
				<Link to={`/messages/1/thread/${id}`}>
					<p className="blue">
						<strong>
							{t("link.__msgObject__")} : {subject}
						</strong>
					</p>
				</Link>
			);
		default:
			return;
	}
};

export const handleResponse = (
	type: number,
	id: number,
	unread: boolean,
	familyUserId: number,
	visioDatetime: string,
	userConnectedId: number,
	seniorSelectedId: number,
	setShowRejectVisio: Function,
	userDispatch: Function,
	isFlow: boolean,
	msgDispatch?: Function,
	visioIsAccepted?: Function,
	setShowCancelVisio?: Function
) => {
	const { t } = useTranslation("common");
	const day = new Date();
	const today = formatedDate(day, "yyyy-MM-dd HH:mm:ss");
	const history = useHistory();

	const markVisioAsRead = (visioId: number, seniorSelectedId: number) => {
		if (visioId) {
			http.get(`${url.BASEURL}${url.THREAD}/${visioId}/visio/check`).then(() => {
				userDispatch({
					type: "READ_MESSAGE",
					payload: { senior: seniorSelectedId, nb: 1 }
				});
				if (!isFlow && msgDispatch) {
					msgDispatch({
						type: "MARK_AS_READ",
						payload: visioId
					});
				}
			});
		}
	};

	const markVisioAsReadAndRedirect = (unread: boolean, visioId: number, seniorSelectedId: number, to: number) => {
		if (unread) {
			markVisioAsRead(visioId, seniorSelectedId);
		}
		history.push(`/messages/${to}`);
	};

	switch (type) {
		case types.MSG_FROM_FAMILY_TO_USER:
		case types.MSG_FROM_USER_TO_FAMILY:
		case types.DUPLICATED_MSG_FROM_USER_TO_FAMILY:
		case types.MULTI_MSG_FROM_USER_TO_FAMILY:
			return isFlow ? (
				<Link to={`/messages/1/thread/${id}`}>
					<BsArrowRightCircle className="read-more-icon" role="button" />
				</Link>
			) : (
				<Link to={`/messages/1/answer/${id}`}>
					<button type="button" className="dark-button">
						<BsFillReplyFill className="answer-icon" /> {t("mailbox.__answer__")}
					</button>
				</Link>
			);
		case types.WELCOME_MESSAGE:
			return isFlow ? (
				<Link to={`/messages/1/answer/${id}`}>
					<BsArrowRightCircle className="read-more-icon" role="button" />
				</Link>
			) : null;
		case types.FAMILY_ASK_FOR_VISIO:
			return isFlow ? (
				<BsArrowRightCircle
					className="read-more-icon"
					role="button"
					onClick={() => markVisioAsReadAndRedirect(unread, id, seniorSelectedId, 1)}
				/>
			) : familyUserId === userConnectedId ? (
				today < visioDatetime && setShowCancelVisio ? (
					<button
						className="pink-button"
						onClick={() => setShowCancelVisio({ show: true, id: id, visio: visioDatetime, unread: unread })}
					>
						{t("mailbox.__cancelVisio__")}
					</button>
				) : (
					<BsClock className="type-notification grey" />
				)
			) : unread ? (
				<button type="button" className="light-button" onClick={() => markVisioAsRead(id, seniorSelectedId)}>
					<BsCheckLg className="check-icon" /> {t("mailbox.__markAsRead__")}
				</button>
			) : null;
		case types.USER_REJECT_VISIO:
		case types.USER_CANCEL_VISIO:
		case types.FAMILY_CANCEL_VISIO:
		case types.FAMILY_REJECT_VISIO:
			return isFlow ? (
				<BsArrowRightCircle
					className="read-more-icon"
					role="button"
					onClick={() => markVisioAsReadAndRedirect(unread, id, seniorSelectedId, 2)}
				/>
			) : unread ? (
				<button type="button" className="light-button" onClick={() => markVisioAsRead(id, seniorSelectedId)}>
					<BsCheckLg className="check-icon" /> {t("mailbox.__markAsRead__")}
				</button>
			) : null;
		case types.USER_ACCEPT_VISIO:
			return isFlow ? (
				<BsArrowRightCircle
					className="read-more-icon"
					role="button"
					onClick={() => markVisioAsReadAndRedirect(unread, id, seniorSelectedId, 1)}
				/>
			) : (
				<>
					{familyUserId === userConnectedId && today < visioDatetime && setShowCancelVisio ? (
						<button
							className="pink-button"
							onClick={() => setShowCancelVisio({ show: true, id: id, visio: visioDatetime, unread: unread })}
						>
							{t("mailbox.__cancelVisio__")}
						</button>
					) : null}
					{unread ? (
						<button type="button" className="light-button" onClick={() => markVisioAsRead(id, seniorSelectedId)}>
							<BsCheckLg className="check-icon" /> {t("mailbox.__markAsRead__")}
						</button>
					) : null}
				</>
			);
		case types.FAMILY_ACCEPT_VISIO:
			return isFlow ? (
				<BsArrowRightCircle
					className="read-more-icon"
					role="button"
					onClick={() => markVisioAsReadAndRedirect(unread, id, seniorSelectedId, 1)}
				/>
			) : unread ? (
				<button type="button" className="light-button" onClick={() => markVisioAsRead(id, seniorSelectedId)}>
					<BsCheckLg className="check-icon" /> {t("mailbox.__markAsRead__")}
				</button>
			) : null;
		case types.USER_ASK_FOR_VISIO:
			return isFlow ? (
				<BsArrowRightCircle className="read-more-icon" role="button" onClick={() => history.push(`/messages/1`)} />
			) : familyUserId === userConnectedId ? (
				visioIsAccepted ? (
					<>
						<button
							type="button"
							className="light-button"
							onClick={() => setShowRejectVisio({ show: true, info: { visioId: id, visioDate: visioDatetime } })}
						>
							{t("mailbox.__no__")}
						</button>
						<button type="button" className="dark-button" onClick={() => visioIsAccepted(id, seniorSelectedId)}>
							{t("mailbox.__ok__")}
						</button>
					</>
				) : null
			) : unread ? (
				<button type="button" className="light-button" onClick={() => markVisioAsRead(id, seniorSelectedId)}>
					<BsCheckLg className="check-icon" /> {t("mailbox.__markAsRead__")}
				</button>
			) : null;
		default:
			return;
	}
};

export const handleIcon = (
	type: number,
	unread: boolean,
	subject: string,
	isFlow: boolean,
	familyUser: { id: number; first_name: string; last_name: string },
	userConnectedId: number
) => {
	const { t } = useTranslation("common");

	switch (type) {
		case types.MSG_FROM_FAMILY_TO_USER:
		case types.MSG_FROM_USER_TO_FAMILY:
		case types.WELCOME_MESSAGE:
		case types.MULTI_MSG_FROM_USER_TO_FAMILY:
		case types.DUPLICATED_MSG_FROM_USER_TO_FAMILY:
			return isFlow ? (
				<div className={unread ? "red" : ""}>
					{unread ? (
						<BsEnvelope className="nav-icon red" style={{ marginRight: "0.5rem" }} />
					) : (
						<BsEnvelopeOpen className="nav-icon" style={{ marginRight: "0.5rem" }} />
					)}
					<b>
						{familyUser.id !== userConnectedId
							? t("mailbox.__msgForPrincipal__")
							: unread
							? t("link.__newMsg__")
							: t("common.__message__")}
					</b>
				</div>
			) : unread ? (
				<BsEnvelope className="nav-icon red" />
			) : (
				<BsEnvelopeOpen className="nav-icon" />
			);
		case types.FAMILY_REJECT_VISIO:
		case types.USER_REJECT_VISIO:
		case types.FAMILY_CANCEL_VISIO:
		case types.USER_CANCEL_VISIO:
			return isFlow ? (
				<div className={unread ? "red" : ""}>
					<FiVideoOff className="nav-icon" style={{ marginRight: "0.5rem" }} /> <b>{subject}</b>
				</div>
			) : (
				<FiVideoOff className={unread ? "nav-icon red" : "nav-icon"} />
			);
		case types.FAMILY_ASK_FOR_VISIO:
		case types.USER_ASK_FOR_VISIO:
		case types.FAMILY_ACCEPT_VISIO:
		case types.USER_ACCEPT_VISIO:
			return isFlow ? (
				<div className={unread ? "red" : ""}>
					<FiVideo className={unread ? "nav-icon red" : "nav-icon"} style={{ marginRight: "0.5rem" }} /> <b>{subject}</b>
				</div>
			) : (
				<FiVideo className={unread ? "nav-icon red" : "nav-icon"} />
			);
		default:
			return;
	}
};
