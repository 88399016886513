import React from "react";
import { useParams } from "react-router-dom";
import Moodweek from "../components/Moodweek/Moodweek";
import FilteredTimelineContainer from "../components/Timeline/FilteredTimelineContainer";
import ContentLayout from "../Layout/ContentLayout";
import withActiveAndE4linkedAccess from "../Layout/withActiveAndE4linkedAccess";
import { UserStateInterface } from "../interfaces/UserStateInterface";

interface TimelineProps {
	userState: UserStateInterface;
	userDispatch: React.Dispatch<any>;
}

const Timeline = ({ userState, userDispatch }: TimelineProps) => {
	const { filter } = useParams<{ filter?: string }>();

	return (
		<ContentLayout userState={userState} userDispatch={userDispatch}>
			<Moodweek seniorSelectedId={userState.seniorSelectedId} />
			<FilteredTimelineContainer userState={userState} filter={filter} />
		</ContentLayout>
	);
};
export default withActiveAndE4linkedAccess(Timeline);
