import React, { useCallback } from "react";
import { BsBellFill } from "react-icons/bs";
import { SeniorInterface } from "../interfaces/UserStateInterface";
import { displayAvatar } from "../utils/DisplayAvatar";

interface Props {
	seniorsUnselected: SeniorInterface[];
	seniorSelectedId: number;
	mySeniors: SeniorInterface[];
	userDispatch: React.Dispatch<any>;
	isResponsive: boolean;
}

export default function SidebarSelectSenior({
	seniorsUnselected,
	seniorSelectedId,
	mySeniors,
	userDispatch,
	isResponsive
}: Props) {
	const selectSenior = useCallback((id: number) => {
		localStorage.setItem("seniorSelectedId", JSON.stringify(id));
		userDispatch({
			type: "SET_SENIOR",
			payload: id
		});
	}, []);

	return (
		<>
			{seniorsUnselected.map(senior => (
				<div
					className={isResponsive ? "sidebar-responsive-card-unselected" : "sidebar-unselected-senior-card"}
					key={senior.id}
					onClick={() => selectSenior(senior.id)}
					role="button"
				>
					<div className="align-items-center">
						{displayAvatar(senior.avatar_updated, senior.gender, senior.id, "avatar-nav left")}
						<div className="space-between align-items-center">
							<span>
								{senior.first_name} {senior.last_name}
							</span>
							{mySeniors.length > 1 ? (
								senior.nb_unread_message > 0 ||
								senior.nb_unread_senior_document > 0 ||
								senior.nb_unread_home_document > 0 ||
								senior.nb_unread_senior_message > 0 ? (
									<BsBellFill className={seniorSelectedId === senior.id ? "red" : "grey"} />
								) : null
							) : null}
						</div>
					</div>
				</div>
			))}
		</>
	);
}
