import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-grid-system";
import ButtonSendPostcard from "./ButtonSendPostcard";
import ButtonSendToPhotoframe from "./ButtonSendToPhotoframe";
import ButtonColorPicker from "./ButtonColorPicker";
import { BsCamera } from "react-icons/bs";
import ButtonDownloadPostcard from "./ButtonDownloadPostcard";

export default function OnePictTemplate({ seniorSelectedId, setNextStep, setError }) {
	const { t } = useTranslation("common");
	const canvasRef = useRef(null);
	const [title, setTitle] = useState("");
	const [messages, setMessages] = useState("");
	const [signature, setSignature] = useState("");
	const [color, setColor] = useState({ title: "#9e9e9e", messages: "#9e9e9e", signature: "#9e9e9e" });
	const [size, setSize] = useState({ title: null, messages: null, signature: null });
	const [inputFile, setInputFile] = useState(null);

	const wrapText = (context, text, x, y, maxWidth, lineHeight) => {
		var words = text.split(" ");
		var line = "";
		for (var n = 0; n < words.length; n++) {
			var testLine = line + words[n] + " ";
			var metrics = context.measureText(testLine);
			var testWidth = metrics.width;
			if (testWidth > maxWidth && n > 0) {
				context.fillText(line, x, y);
				line = words[n] + " ";
				y += lineHeight;
			} else {
				line = testLine;
			}
		}
		context.fillText(line, x, y);
	};

	const fitCanvas = (img, canvas, ctx) => {
		const canvasWidth = canvas.width / 2;
		if (img.width > img.height) {
			const scale = img.height / canvas.height;
			const startingPoint = img.width > canvas.width ? (img.width - canvas.width) / 3 : 100;
			return ctx.drawImage(img, startingPoint, 0, canvasWidth * scale, img.height, 0, 0, canvasWidth, canvas.height);
		} else if (img.width < img.height) {
			const hRatio = canvas.width / img.width;
			const vRatio = canvas.height / img.height;
			const ratio = Math.min(hRatio, vRatio);
			return ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width * ratio, img.height * ratio);
		} else {
			return ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvasWidth, canvas.height);
		}
	};

	useEffect(() => {
		let mounted = true;
		const canvas = canvasRef.current;
		const ratio = Math.ceil(window.devicePixelRatio);
		canvas.width = 1024 * ratio;
		canvas.height = 760 * ratio;
		canvas.style.letterSpacing = "0.5px";
		const ctx = canvas.getContext("2d");

		ctx.fillStyle = "#b5b5b5";
		ctx.fillRect(0, 0, canvas.width / 2, canvas.height);
		ctx.fill();

		ctx.fillStyle = "white";
		ctx.fillRect(canvas.width / 2, 0, canvas.width / 2, canvas.height);
		ctx.fill();
		ctx.strokeRect(0, 0, canvas.width, canvas.height);

		if (mounted && title) {
			ctx.font = size.title ? `${size.title} 36pt Courrier` : "36pt Courrier";
			ctx.fillStyle = color.title;
			const canvasWidth = canvas.width / 2 + 30;
			ctx.fillText(title, canvasWidth, 140);
		}

		if (mounted && messages) {
			ctx.font = size.messages ? `${size.messages} 25pt Courrier` : "25pt Courrier";
			ctx.fillStyle = color.messages;
			const canvasWidth = canvas.width / 2;
			wrapText(ctx, messages, canvasWidth + 35, 250, canvasWidth - 50, 35);
		}

		if (mounted && signature) {
			ctx.font = size.signature ? `${size.signature} 21pt Courrier` : "21pt Courrier";
			ctx.fillStyle = color.signature;
			const canvasWidth = canvas.width / 2 + 30;
			ctx.fillText(signature, canvasWidth, 660);
		}

		if (mounted && inputFile) {
			const reader = new FileReader();
			reader.onload = function (inputFile) {
				let img = new Image();
				img.onload = function () {
					fitCanvas(img, canvas, ctx);
				};
				img.src = inputFile.target.result;
			};
			reader.readAsDataURL(inputFile.target.files[0]);
		}
		return () => (mounted = false);
	}, [title, color, size, messages, inputFile, signature]);

	return (
		<Row>
			<Col xs={2} sm={1} md={1} lg={1}>
				<p className="step-number mt-5">2</p>
			</Col>
			<Col xs={5} sm={6} md={5} lg={5}>
				<p className="mt-5">
					<b>{t("link.__customizeCard__")}</b>
				</p>
			</Col>
			<Col xs={5} sm={5} md={6} lg={6}>
				<input
					type="file"
					id="customFile"
					name="file"
					accept=".jpg, .jpeg, .png"
					className="inputfile"
					onChange={e => setInputFile(e)}
				/>
				<label htmlFor="customFile">
					<BsCamera className="nav-icon" /> {t("link.__selectPict__")}
				</label>
			</Col>

			<Col xs={2} sm={2} md={2} lg={2}>
				<ButtonColorPicker
					color={color}
					setColor={setColor}
					elt={"title"}
					size={size}
					setSize={setSize}
					displaySize={true}
				/>
			</Col>
			<Col xs={10} sm={10} md={10} lg={10}>
				<input type="text" placeholder={t("link.__addTitle__")} onChange={e => setTitle(e.target.value)} maxLength="20" />
			</Col>

			<Col xs={2} sm={2} md={2} lg={2}>
				<ButtonColorPicker
					color={color}
					setColor={setColor}
					elt={"messages"}
					size={size}
					setSize={setSize}
					displaySize={true}
				/>
			</Col>

			<Col xs={10} sm={10} md={10} lg={10}>
				<textarea
					placeholder={t("link.__addMessage__")}
					onChange={e => setMessages(e.target.value)}
					rows="5"
					maxLength="250"
				/>
			</Col>
			<Col xs={2} sm={2} md={2} lg={2}>
				<ButtonColorPicker
					color={color}
					setColor={setColor}
					elt={"signature"}
					size={size}
					setSize={setSize}
					displaySize={true}
				/>
			</Col>
			<Col xs={10} sm={10} md={10} lg={10}>
				<input
					type="text"
					placeholder={t("link.__signature__")}
					onChange={e => setSignature(e.target.value)}
					maxLength="32"
				/>
			</Col>
			<Col xs={2} sm={2} md={1} lg={1}>
				<p className="step-number mt-5">3</p>
			</Col>
			<Col xs={10} sm={10} md={11} lg={11}>
				<p className="mt-5">
					<b>{t("link.__previewYourCard__")}</b>
				</p>
			</Col>
			<Col xs={12} sm={12} md={12} lg={12}>
				<canvas ref={canvasRef} style={{ width: "100%", marginTop: "10px" }} />
			</Col>
			<Col xs={2} sm={2} md={1} lg={1}>
				<p className="step-number mt-5">4</p>
			</Col>
			<Col xs={10} sm={10} md={11} lg={11}>
				<p className="mt-5">
					<b>{t("common.__share__")}</b>
				</p>
			</Col>
			<Col xs={12} sm={12} md={12} lg={12}>
				<div className="center">
					<button type="button" className="light-button" onClick={() => setNextStep(false)}>
						{t("common.__back__")}
					</button>

					<ButtonDownloadPostcard canvas={canvasRef.current} />
					<ButtonSendToPhotoframe seniorSelectedId={seniorSelectedId} canvasRef={canvasRef} setError={setError} />
					<ButtonSendPostcard seniorSelectedId={seniorSelectedId} canvasRef={canvasRef} setError={setError} />
				</div>
			</Col>
			<Col xs={12} sm={12} md={12} lg={12}>
				<div className="space"></div>
			</Col>
		</Row>
	);
}
