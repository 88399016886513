import React from "react";
import { useTranslation } from "react-i18next";
import { BsEmojiFrown, BsEmojiNeutral, BsEmojiSmile } from "react-icons/bs";
import { formatedDate } from "../../utils/Datetime";
import "./Moodweek.scss";

interface Props {
	mood: {
		value: number;
		date: string;
	}[];
}

const MoodweekContainer = ({ mood }: Props) => {
	const { t } = useTranslation("common");

	const handleMood = (value: number) => {
		switch (value) {
			case 1:
			case 2:
				return <BsEmojiFrown className="mood-emoticon" data-testid="sad" />;
			case 3:
				return <BsEmojiNeutral className="mood-emoticon" data-testid="neutral" />;
			case 4:
			case 5:
				return <BsEmojiSmile className="mood-emoticon" data-testid="smile" />;
			default:
				return <img src="/img/mood/0-absent.png" alt="no-mood" className="mood-emoticon" />;
		}
	};

	return mood.length > 0 ? (
		<div className="mood-card" data-testid="moodweek-element">
			<p className="meta center">{t("timeline.__moodWeek__")}</p>
			<ul className="moodweek">
				{mood.map((m, index) => (
					<li className="center" key={index}>
						<p data-testid={`mood-icon-${index}`}>{handleMood(m.value)}</p>
						<p className="small-meta">{formatedDate(new Date(m.date), "dd/MM")}</p>
					</li>
				))}
			</ul>
		</div>
	) : null;
};

export default MoodweekContainer;
