import React from "react";
import { setConfiguration } from "react-grid-system";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ErrorSuccessProvider } from "../contexts/ErrorSuccessContext";
import { FamilyUserProvider } from "../contexts/FamilyUserContext";
import ScrollToTop from "../hooks/ScrollToTop";
import Home from "../pages/Login";
import Welcome from "../pages/Welcome";
import NewPassword from "../components/UserSettings/NewPassword";
import LostPassword from "../pages/LostPassword";
import ProtectedApp from "./ProtectedApp";
import "./App.scss";
import MainLayout from "../Layout/MainLayout";


const App = () => {
	setConfiguration({
		breakpoints: [576, 768, 800, 1200, 1600],
		defaultScreenClass: "md",
		gutterWidth: 10
	});

	return (
		<FamilyUserProvider>
			<ErrorSuccessProvider>
				<BrowserRouter>
						<ScrollToTop>
							<Switch>
								<Route exact path="/" component={Home} />
								<Route path="/welcome/:token" component={Welcome} />
								<Route exact path="/newpassword/:token" component={NewPassword} />
								<Route exact path="/lostpassword" component={LostPassword} />
								<MainLayout><ProtectedApp/></MainLayout>
							</Switch>
						</ScrollToTop>
				</BrowserRouter>
			</ErrorSuccessProvider>
		</FamilyUserProvider>
	);
};

export default App;
