import React, { useReducer } from "react";
import { BsCloudUpload, BsExclamationTriangleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

interface FileInterface {
	name: string;
	size: number;
	preview: string;
	lastModified: number;
	lastModifiedDate: Date;
	type: string;
}

export default function useUploadPicts() {
	const { t } = useTranslation("common");

	const initialState = {
		inDropZone: false,
		fileList: [],
		fileSize: 0
	};

	const reducer = (
		state: { inDropZone: boolean; fileList: FileInterface[]; fileSize: number },
		action: { type: string; payload: any }
	) => {
		switch (action.type) {
			case "ADD_TO_DROPZONE":
				return {
					...state,
					inDropZone: action.payload
				};
			case "ADD_TO_LIST": {
				const files = action.payload;
				const sizes = files.map((file: FileInterface) => file.size);
				return {
					...state,
					fileList: state.fileList.concat(action.payload),
					fileSize: sizes.reduce((sum: number, current: number) => sum + current, state.fileSize)
				};
			}
			case "REMOVE_TO_LIST": {
				const fileToRemove = action.payload;
				return {
					...state,
					fileList: state.fileList.filter(file => file.name !== fileToRemove.name),
					fileSize: state.fileSize - parseInt(fileToRemove.size)
				};
			}
			default:
				return state;
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);
	const pictsToUpload = state.fileList;
	const validTypes = ["jpeg", "jpg", "png", "JPG", "PNG"];

	const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		dispatch({ type: "ADD_TO_DROPZONE", payload: true });
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.dataTransfer.dropEffect = "copy";
		dispatch({ type: "ADD_TO_DROPZONE", payload: true });
	};

	const handleDrop = (e: any) => {
		e.preventDefault();
		let files = [...e.dataTransfer.files];
		let files_with_preview = [];

		files.map(file => {
			file["preview"] = URL.createObjectURL(file);
			files_with_preview.push(file);
		});

		if (files) {
			dispatch({ type: "ADD_TO_LIST", payload: files });
			dispatch({ type: "ADD_TO_DROPZONE", payload: false });
		}
	};

	const fileType = (fileName: string) => {
		return fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) || fileName;
	};

	const fileSize = (size: number) => {
		if (size === 0) return "0 Bytes";
		const k = 1024;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
		const i = Math.floor(Math.log(size) / Math.log(k));
		return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
	};

	const onChange = (e: any) => {
		if (e.target.files && e.target.files.length > 0) {
			let files = [...e.target.files];
			let files_with_preview = [];

			files.map(file => {
				file["preview"] = URL.createObjectURL(file);
				files_with_preview.push(file);
			});

			dispatch({ type: "ADD_TO_LIST", payload: files });
			dispatch({ type: "ADD_TO_DROPZONE", payload: false });
		}
	};

	const removeFile = (file: FileInterface) => {
		dispatch({ type: "REMOVE_TO_LIST", payload: file });
	};

	const displayDropzoneUpload = () => {
		return (
			<div
				className="upload-zone"
				onDrop={e => handleDrop(e)}
				onDragOver={e => handleDragOver(e)}
				onDragEnter={e => handleDragEnter(e)}
			>
				<div className="title">
					<BsCloudUpload color="#4a4e64" className="dropfile-icon" />

					<p className="center">{t("link.__pictsDropZone__")}</p>
					<input
						type="file"
						id="customFile"
						name="file"
						accept="image/*"
						className="customInputfile"
						onChange={onChange}
						multiple
					/>
					<label htmlFor="customFile">{t("link.__pictsDownloadBtn__")}</label>

					{state.fileSize > 0 ? (
						<div className="limit-size">
							<div className="limit-title">
								<span>{t("common.__limitSize__")}</span>
							</div>
							<div className="limit-bar ">
								<div className="progress-bar-container">
									<div className="progress-bar">
										<div className="progress-filler" style={{ width: `${(state.fileSize * 100) / 50000000}%` }}>
											<span className="progress-label">{Math.round((state.fileSize * 100) / 50000000)}%</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : null}
				</div>

				<ul className="files-zone">
					{state.fileList.map((file, index) => {
						return (
							<li key={index} className="preview-list">
								<button type="button" className="suppr-btn" onClick={() => removeFile(file)}>
									X
								</button>
								<div className="center">
									<img src={file.preview} alt="" className="preview-photobox" />
									<p>_</p>
									<p className="meta">{file.name}</p>
									{validTypes.includes(fileType(file.name)) ? (
										<p className="meta">
											{t("link.__pictSize__")} : {fileSize(file.size)}
										</p>
									) : (
										<p className="red">
											<BsExclamationTriangleFill color="#FF846E" className="type-notification" />
											<b>
												{t("link.__addPictsTypeError__")} : {fileType(file.name)}
											</b>
										</p>
									)}
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		);
	};

	return { displayDropzoneUpload, pictsToUpload };
}
