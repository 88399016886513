import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import ErrorContainer from "../../Layout/ErrorContainer";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HandleLocale from "../../utils/HandleLocale";
import { BsXLg } from "react-icons/bs";
import { formatedDate, formatedDateFromString } from "../../utils/Datetime";

interface ModalProps {
	setShow: Function;
	visioDate: string;
	locale: string;
	seniorSelectedId: number;
}

const ModalVisioRequest = ({ setShow, visioDate, locale, seniorSelectedId }: ModalProps) => {
	const { t } = useTranslation("common");
	const { handleSubmit } = useFormContext();
	const [httpResponse, setHttpResponse] = useState<{ error: boolean; msg: any } | null>(null);
	const [visioRequestError, setVisioRequestError] = useState(false);
	const [recipients, setRecipients] = useState([]);
	const [recipientError, setRecipientError] = useState(false);
	const [myRecipient, setMyRecipient] = useState<string | null>(null);
	const [startDate, setStartDate] = useState(new Date());
	const userLocale = HandleLocale(locale);

	interface RecipientsInterface {
		id: number;
		first_name: string;
		last_name: string;
		designation: string;
		name: string;
	}

	useEffect(() => {
		http
			.get(`${url.SENIORS}/${seniorSelectedId}/visio/recipients`)
			.then(res => {
				const recipients = res.data.user_recipients;
				const fullDesignation = recipients.map((recipient: RecipientsInterface) => {
					return {
						...recipient,
						name: recipient.designation
							? `${recipient.first_name} ${recipient.last_name}, ${recipient.designation}`
							: `${recipient.first_name} ${recipient.last_name}`
					};
				});
				setRecipients(fullDesignation);
			})
			.catch(() => setRecipients([]));
	}, [seniorSelectedId]);

	const onSubmit = () => {
		if (!myRecipient) {
			setRecipientError(true);
		} else {
			setVisioRequestError(false);
			setHttpResponse(null);
			const selectTime = formatedDate(startDate, "hh:mm:ss");
			const data = {
				senior_id: seniorSelectedId,
				user_id: parseInt(myRecipient),
				visio_datetime: visioDate + " " + selectTime
			};
			http
				.post(`${url.HOMES}/visio/new`, data)
				.then(() => setShow(false))
				.catch(err => {
					if (err.request.status === 400) {
						setVisioRequestError(true);
					} else {
						setVisioRequestError(false);
						setHttpResponse({ error: true, msg: null });
					}
				});
		}
	};

	return (
		<>
			<br />
			<h4 className="center">
				{t("calendar.__visioRequest__")} <br />
				<b>{formatedDateFromString(visioDate, "PPPP", locale)}</b>
			</h4>
			{visioRequestError ? (
				<p className="alert">
					<span className="right error close-btn" onClick={() => setVisioRequestError(false)}>
						<BsXLg style={{ verticalAlign: "middle" }} />
					</span>
					<span style={{ paddingLeft: "0.7rem" }}>{t("calendar.__visioAlreadyExist__")}</span>
				</p>
			) : null}

			<ErrorContainer
				alert={t("calendar.__visioErrorTryAgain__")}
				success={t("calendar.__visioSuccess__")}
				httpResponse={httpResponse}
				setHttpResponse={setHttpResponse}
			/>

			<form onSubmit={handleSubmit(onSubmit)}>
				<p>{t("calendar.__recipientSelection__")}</p>

				{recipientError ? <p className="error">{t("mailbox.__pleaseSelectRecipient__")}</p> : null}

				{recipients.length > 0 ? (
					<select name="recipient" onChange={e => setMyRecipient(e.target.value)}>
						<option>{t("mailbox.__selectRecipient__")}</option>
						{recipients.map((recipient: RecipientsInterface) => (
							<option key={recipient.id} value={recipient.id}>
								{recipient.name}
							</option>
						))}
					</select>
				) : (
					<p className="red">{t("mailbox.__noReferentRegistred__")}</p>
				)}

				<div className="space"></div>
				<p>{t("calendar.__timeSelection__")}</p>

				<DatePicker
					selected={startDate}
					onChange={(date: Date) => setStartDate(date)}
					showTimeSelect
					showTimeSelectOnly
					timeIntervals={15}
					timeCaption={t("calendar.__hour__")}
					timeFormat="HH:mm"
					dateFormat="HH:mm"
					locale={userLocale}
				/>

				<div className="space"></div>
				<button className="light-button" onClick={() => setShow(false)}>
					{t("common.__cancel__")}
				</button>
				<button type="submit" className="dark-button">
					{t("calendar.__sendRequest__")}
				</button>
			</form>
		</>
	);
};
export default ModalVisioRequest;
