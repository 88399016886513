import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorContainer from "../../Layout/ErrorContainer";
import UploadAvatarContainer from "./UploadAvatarContainer";
import {UserStateInterface} from "../../interfaces/UserStateInterface";
import Loader from "../../Layout/Loader";
import HeaderContainer from "../../Layout/HeaderContainer";

export default function UploadAvatar({ userState }: { userState: UserStateInterface }) {
	const { t } = useTranslation("common");
	const [httpResponse, setHttpResponse] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	return (
		<div className="content-card">
			<HeaderContainer title={t("link.__updateAvatar__")} />

			<p className="mt-5">{t("link.__avatarWarning__")}</p>

			{userState.familyUser.principal ? (
				<>
					<ErrorContainer
						alert={t("link.__updateAvatarError__")}
						success={t("link.__updateAvatarSuccess__")}
						httpResponse={httpResponse}
						setHttpResponse={setHttpResponse}
					/>
					{isLoading ? (
						<Loader />
					) : (
						<UploadAvatarContainer userState={userState} setIsLoading={setIsLoading} setHttpResponse={setHttpResponse} />
					)}
				</>
			) : (
				<p>{t("link.__avatarPrincipaleOnly__")}</p>
			)}
		</div>
	);
}
