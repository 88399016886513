import React, { useState } from "react";
import { BsDownload } from "react-icons/bs";
import { formatedDateFromString } from "../../utils/Datetime";
import { DocumentInterface } from "../../interfaces/MessagesInterface";

interface DocumentContainerProps {
	document: DocumentInterface;
	userLocale: string;
	type: string;
	seniorSelectedId: number;
	userDispatch: Function;
}

export default function DocumentContainer({
	document,
	userLocale,
	type,
	seniorSelectedId,
	userDispatch
}: DocumentContainerProps) {
	const [documentIsUnread, setDocumentIsUnread] = useState(document.unread);

	const markAsRead = (unread: boolean) => {
		if (unread) {
			if (type === "home") {
				userDispatch({
					type: "READ_HOME_DOCUMENT",
					payload: seniorSelectedId
				});
				setDocumentIsUnread(false);
			} else if (type === "senior") {
				userDispatch({
					type: "READ_SENIOR_DOCUMENT",
					payload: seniorSelectedId
				});
				setDocumentIsUnread(false);
			}
		}
	};

	return (
		<div className={documentIsUnread ? "unread-card" : "flow-card"}>
			<div className="space-between">
				<div>
					<p className="meta">
						{documentIsUnread ? (
							<b>{formatedDateFromString(document.datetime, "Pp", userLocale)}</b>
						) : (
							formatedDateFromString(document.datetime, "Pp", userLocale)
						)}
					</p>
					{type === "senior" ? (
						<a
							href={`/storages/seniors/documents/${document.id}`}
							download={`${document.title}-${document.id}`}
							onClick={() => markAsRead(document.unread)}
						>
							<p>
								<b>
									<span className="blue">
										{document.title} - {document.content_type.split("/")[1]}
									</span>
								</b>
							</p>
						</a>
					) : type === "home" ? (
						<a
							href={`/storages/homes/documents/${document.id}`}
							download={`${document.title}-${document.id}`}
							onClick={() => markAsRead(document.unread)}
						>
							<p>
								<b>
									<span className="blue">
										{document.title} - {document.content_type.split("/")[1]}
									</span>
								</b>
							</p>
						</a>
					) : null}
				</div>
				{type === "senior" ? (
					<a
						href={`/storages/seniors/documents/${document.id}`}
						download={`${document.title}-${document.id}`}
						onClick={() => markAsRead(document.unread)}
					>
						<BsDownload className="type-notification mt-2" />
					</a>
				) : null}
				{type === "home" ? (
					<a
						href={`/storages/homes/documents/${document.id}`}
						download={`${document.title}-${document.id}`}
						onClick={() => markAsRead(document.unread)}
					>
						<BsDownload className="type-notification mt-2" />
					</a>
				) : null}
			</div>
		</div>
	);
}
