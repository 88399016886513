import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BsCameraVideo, BsCalendarCheck } from "react-icons/bs";
import format from "date-fns/format";
import { isBefore, isEqual } from "date-fns";
import HandleLocale from "../../utils/HandleLocale";

interface VisioButtonProps {
	seniorId: number;
	userLocale: string;
	handleVisio: Function;
	nextVisio: NextVisioInterface;
}

interface NextVisioInterface {
	visio_datetime: string;
	room: string | null;
	subject: string | null;
	token: string | null;
	state: string;
}

const VisioButtonContainer = ({ handleVisio, nextVisio, seniorId, userLocale }: VisioButtonProps) => {
	const { t } = useTranslation("common");
	const locale = HandleLocale(userLocale);
	const interval = useRef();

	const handleVisioButton = (nextVisio: NextVisioInterface) => {
		if (nextVisio.state === "WORKING") {
			return (
				<div className="visio-card red" data-testid="visio-button">
					<div className="space-between">
						<div>
							<b>
								<BsCameraVideo className="next-visio-icon red mr-2" />
								{t("mailbox.__visioAppointment__")} :{" "}
								{format(new Date(nextVisio.visio_datetime.split(" ").join("T")), "PPp", { locale: locale })}
							</b>
						</div>
						<Link to={`/livemeeting/${nextVisio.subject}/${nextVisio.room}/${nextVisio.token}`} role="button">
							<button type="button" className="red-button">
								{t("common.__startVisio__")}
							</button>
						</Link>
					</div>
				</div>
			);
		} else if (nextVisio.state === "WAITING") {
			const today: string = format(new Date(), "yyyy-MM-dd");
			const aVisioIsPlannedToday = nextVisio
				? isEqual(new Date(nextVisio.visio_datetime.split(" ")[0]), new Date(today))
				: false;

			const visioDateTime = nextVisio.visio_datetime.split(" ").join("T");
			const isTooEarly = aVisioIsPlannedToday && isBefore(new Date(), new Date(visioDateTime));
			if (isTooEarly) {
				// @ts-ignore
				interval.current = setInterval(() => {
					handleVisio(seniorId);
				}, 120000);
			}
			return (
				<div className="visio-card blue" data-testid="visio-button">
					<BsCalendarCheck className="next-visio-icon blue" style={{ marginRight: "0.75rem" }} />

					{nextVisio.visio_datetime ? (
						<b>
							{t("link.__nextLiveMeeting__")} :{" "}
							{format(new Date(nextVisio.visio_datetime.split(" ").join("T")), "Pp", { locale: locale })}
						</b>
					) : null}
				</div>
			);
		} else {
			return null;
		}
	};

	useEffect(
		() => () => {
			if (typeof interval.current === "number") clearInterval(interval.current);
		},
		[]
	);

	return handleVisioButton(nextVisio);
};

export default VisioButtonContainer;
