import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
	BsCalendarWeek,
	BsCamera,
	BsCameraVideo,
	BsChatDots,
	BsClipboardCheck,
	BsEnvelope,
	BsImages,
	BsInfoCircle
} from "react-icons/bs";
import useWindowSize from "../hooks/useWindowSize";
import { SeniorInterface, UserStateInterface } from "../interfaces/UserStateInterface";
import { Tooltip } from "../utils/Tooltip";
import { displayAvatar } from "../utils/DisplayAvatar";
import { FamilyUserContext } from "../contexts/FamilyUserContext";
import SeniorHomeEvents from "../components/HomeInfo/SeniorHomeEvents";
import SidebarSelectSenior from "./SidebarSelectSenior";

interface SidebarProps {
	userState: UserStateInterface;
	userDispatch: React.Dispatch<any>;
}

const Sidebar = ({ userState, userDispatch }: SidebarProps) => {
	const { t } = useTranslation("common");
	const size = useWindowSize();
	const { seniorHome } = useContext(FamilyUserContext);
	const mySeniors: SeniorInterface[] = userState.familyUser.seniors;
	const seniorSelectedId: number = userState.seniorSelectedId;
	const selectedSenior = mySeniors.find(senior => senior.id === seniorSelectedId);
	const seniorSelectedName: string = selectedSenior ? selectedSenior.first_name : "";
	const seniorsUnselected = mySeniors.filter(senior => senior.id !== seniorSelectedId);

	const handleShortcuts = (isE4linked: boolean, isActive: boolean) => {
		return isActive ? (
			<div className="shorcuts-sidebar">
				<Tooltip text={t("mailbox.__askForVisio__")}>
					<Link to="/messages/2">
						<BsCameraVideo className="shortcut-icon" />
					</Link>
				</Tooltip>
				{isE4linked ? (
					<>
						<Tooltip text={t("common.__timeline__")}>
							<Link to="/activities">
								<BsClipboardCheck className="shortcut-icon" />
							</Link>
						</Tooltip>
						<Tooltip text={t("link.__photoFrame__")}>
							<Link to="/profile/3">
								<BsImages className="shortcut-icon" />
							</Link>
						</Tooltip>
						<Tooltip text={`${t("link.__writeTo__")} ${seniorSelectedName}`}>
							<Link to="/profile/6">
								<BsChatDots className="shortcut-icon" />
							</Link>
						</Tooltip>
					</>
				) : (
					<Tooltip text={t("common.__mailbox__")}>
						<Link to="/messages/1">
							<BsEnvelope className="shortcut-icon" />
						</Link>
					</Tooltip>
				)}
				<Tooltip text={t("calendar.__openCalendar__")}>
					<Link to="/calendar">
						<BsCalendarWeek className="shortcut-icon" />
					</Link>
				</Tooltip>
				<Tooltip text={t("home.__home__")}>
					<Link to="/seniorhome">
						<BsInfoCircle className="shortcut-icon" />
					</Link>
				</Tooltip>
			</div>
		) : null;
	};

	return size.width > 768 ? (
		<div className="sidebar">
			{selectedSenior ? (
				<div className="card" key={selectedSenior.id}>
					<div className="center avatar-component">
						{displayAvatar(selectedSenior.avatar_updated, selectedSenior.gender, selectedSenior.id, "avatar")}
						{selectedSenior.active && userState.familyUser.principal ? (
							<Link to="/avatar">
								<BsCamera className="avatar-button" role="button" />
							</Link>
						) : null}
					</div>
					<div className="senior-nav-name">
						<p className="center mb-5">
							<b>
								{selectedSenior.first_name} {selectedSenior.last_name}
							</b>
						</p>
						{selectedSenior.active ? null : (
							<p className="center red mb-5">
								<b>{t("user.__inactiveAccount__")}</b>
							</p>
						)}
					</div>
					{handleShortcuts(selectedSenior.e4linked, selectedSenior.active)}
				</div>
			) : null}
			{seniorsUnselected ? (
				<SidebarSelectSenior
					isResponsive={false}
					seniorsUnselected={seniorsUnselected}
					seniorSelectedId={seniorSelectedId}
					mySeniors={mySeniors}
					userDispatch={userDispatch}
				/>
			) : null}
			<SeniorHomeEvents userState={userState} seniorHome={seniorHome} />
		</div>
	) : (
		<div className="responsive-sidebar">
			{selectedSenior ? (
				<div className="sidebar-responsive-card">
					<div style={{ display: "flex" }}>
						{userState.familyUser.principal ? (
							<Link to="/avatar">
								{displayAvatar(selectedSenior.avatar_updated, selectedSenior.gender, selectedSenior.id, "avatar-sidebar")}
							</Link>
						) : (
							displayAvatar(selectedSenior.avatar_updated, selectedSenior.gender, selectedSenior.id, "avatar-sidebar")
						)}
						<div className="sidebar-responsive-shortcuts" style={{ marginLeft: "0.5rem" }}>
							<p className="senior-nav-name mt-2">
								<b>
									{selectedSenior.first_name} {selectedSenior.last_name}
								</b>
							</p>
							{handleShortcuts(selectedSenior.e4linked, selectedSenior.active)}
						</div>
					</div>
				</div>
			) : null}
			{seniorsUnselected ? (
				<SidebarSelectSenior
					isResponsive
					seniorsUnselected={seniorsUnselected}
					seniorSelectedId={seniorSelectedId}
					mySeniors={mySeniors}
					userDispatch={userDispatch}
				/>
			) : null}
		</div>
	);
};
export default Sidebar;
