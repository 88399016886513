import { useEffect } from "react";

interface IntersectionObserverProps {
	target: any;
	onIntersect: Function;
	enabled: boolean | undefined;
}

export default function useIntersectionObserver({ target, onIntersect, enabled }: IntersectionObserverProps) {
	const rootMargin = "0px";
	const threshold = 0;
	let root: any = null;

	useEffect(() => {
		if (!enabled) {
			return;
		}

		const observer = new IntersectionObserver(
			entries => entries.forEach(entry => entry.isIntersecting && onIntersect()),
			{
				root: root && root.current,
				rootMargin,
				threshold
			}
		);
		const el = target && target.current;

		if (!el) {
			return;
		}

		observer.observe(el);

		return () => {
			observer.unobserve(el);
		};
	}, [target.current, enabled]);
}
