import { useState, useEffect } from "react";
import http from "../Services/Interceptor";

interface useAxiosProps {
	url: string;
}

export default function useAxios({ url }: useAxiosProps) {
	const [data, setData] = useState<any>(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadData = async (url: string) => {
			try {
				const res = await http.get(url);
				setData(res.data);
				setLoading(false);
			} catch (err) {
				setData(null);
				setLoading(false);
			}
		};
		loadData(url);
	}, [url]);

	return { data, loading };
}
