import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { Row, Col } from "react-grid-system";
import { BsArrowRightShort, BsXLg } from "react-icons/bs";
import MyInputText from "../../utils/form/MyInputText";
import MyInputMail from "../../utils/form/MyInputMail";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import { UserStateInterface } from "../../interfaces/UserStateInterface";
import HeaderContainer from "../../Layout/HeaderContainer";
import HandleLanguage from "./HandleLanguage";
import HandleTimezone from "./HandleTimezone";
interface HttpResponseInterface {
	error: boolean;
	msg: string;
}
interface InputValues {
	firstname: string;
	lastname: string;
	login: string;
	email: string;
}

interface AddFamilyUserProps {
	userState: UserStateInterface;
}

const AddFamilyUser = ({ userState }: AddFamilyUserProps) => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const mySeniors = userState.familyUser.seniors;
	const { handleSubmit } = useFormContext<InputValues>();
	const [seniorIds, setSeniorIds] = useState<number[]>([]);
	const [httpResponse, setHttpResponse] = useState<HttpResponseInterface | null>(null);
	const familyUserTimezone = {
		value: userState.familyUser.timezone,
		label: userState.familyUser.timezone
	};
	const [timezone, setTimezone] = useState(familyUserTimezone);
	const [language, setLanguage] = useState(userState.familyUser.locale);

	const onSubmit = (data: InputValues) => {
		setHttpResponse(null);
		if (mySeniors.length > 1 && seniorIds.length === 0) {
			setHttpResponse({ error: true, msg: t("user.__chooseSenior__") });
		} else {
			const userData = {
				family_user: {
					first_name: data.firstname,
					last_name: data.lastname,
					login: data.email,
					password: null,
					email: data.email,
					phone: null,
					senior_ids: mySeniors.length === 1 ? [mySeniors[0].id] : seniorIds,
					locale: language,
					timezone: timezone.value
				}
			};
			http
				.post(`${url.FAMILY_URL}`, userData)
				.then(() => history.push("/settings/myaccount"))
				.catch(err => {
					if (err.request.status === 400) {
						if (err.request.response === `{"errors":{"email":["has already been taken"]}}`) {
							setHttpResponse({ error: true, msg: t("user.__addUserMailError__") });
						} else {
							setHttpResponse({ error: true, msg: t("user.__addUserError__") });
						}
					} else {
						setHttpResponse({ error: true, msg: t("user.__addUserError__") });
					}
				});
		}
	};

	const addSeniorToFollow = (id: number) => {
		if (seniorIds.includes(id)) {
			const ids = seniorIds.filter(sId => sId !== id);
			setSeniorIds(ids);
		} else {
			setSeniorIds([...seniorIds, id]);
		}
	};

	return (
		<>
			<HeaderContainer title={t("user.__addUser__")} />
			<p>{t("user.__addUserWarning__")}</p>
			{httpResponse ? (
				httpResponse.error ? (
					<p className="alert">
						{httpResponse.msg}
						<span className="right error close-btn" onClick={() => setHttpResponse(null)}>
							<BsXLg style={{ verticalAlign: "middle" }} />
						</span>
					</p>
				) : null
			) : null}
			<form onSubmit={handleSubmit(onSubmit)}>
				{mySeniors.length === 1 ? null : (
					<>
						<p className="mt-2">
							<b>{t("user.__chooseSenior__")}</b>
						</p>
						<ul className="affiliates-list">
							{mySeniors.map(senior => (
								<li key={senior.id}>
									<p className="inline">
										<input type="checkbox" name="senior" onChange={() => addSeniorToFollow(senior.id)} /> {senior.first_name}{" "}
										{senior.last_name}
									</p>
								</li>
							))}
						</ul>
					</>
				)}
				<Row>
					<Col xs={12} sm={12} md={6} lg={6}>
						<MyInputText label={t("common.__firstname__")} name="firstname" behavior={{ required: true }} />
					</Col>
					<Col xs={12} sm={12} md={6} lg={6}>
						<MyInputText label={t("common.__name__")} name="lastname" behavior={{ required: true }} />
					</Col>
					<Col xs={12} sm={12} md={12} lg={12}>
						<MyInputMail label={t("common.__mail__")} name="email" behavior={{ required: true, pattern: /^\S+@\S+\.\S+$/ }} />
					</Col>
					<Col xs={12} sm={12} md={6} lg={6}>
						<label>{t("common.__language__")}</label>
						<HandleLanguage language={language} setLanguage={setLanguage} isForAffiliate />
					</Col>
					<Col xs={12} sm={12} md={6} lg={6}>
						<label>{t("common.__timezone__")}</label>
						<HandleTimezone timezone={timezone} setTimezone={setTimezone} familyUserTimezone={familyUserTimezone} />
					</Col>
				</Row>
				<div className="colored-background mt-5">
					<p className="meta">
						<BsArrowRightShort style={{ marginRight: "0.5rem", verticalAlign: "middle", height: "1.2rem", width: "auto" }} />
						{t("user.__addUserWarningPassword__")}
					</p>
					<p className="meta">
						<BsArrowRightShort style={{ marginRight: "0.5rem", verticalAlign: "middle", height: "1.2rem", width: "auto" }} />
						{t("user.__addUserWarningLogin__")}
					</p>
				</div>
				<button type="button" className="light-button" onClick={() => history.goBack()}>
					{t("common.__cancel__")}
				</button>
				<button type="submit" className="dark-button">
					{t("common.__save__")}
				</button>
			</form>
		</>
	);
};
export default AddFamilyUser;
