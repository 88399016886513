import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import WeatherOfTheDay from "./WeatherOfTheDay";
import {UserStateInterface} from "../../interfaces/UserStateInterface";
import { formatedDate, formatedDateFromString } from "../../utils/Datetime";

interface HomeInfoProps {
	userState: UserStateInterface;
	seniorHome: any;
}

interface NextHomeEventInterface {
	info: string;
	begin_datetime: string;
}

interface WorkshopsInterface {
	id: number;
	info: string;
	begin_datetime: string;
}

const SeniorHomeEvents = ({ userState, seniorHome }: HomeInfoProps) => {
	const { t } = useTranslation("common");
	const seniorSelectedId = userState.seniorSelectedId;
	const [nextHomeEvent, setNextHomeEvent] = useState<NextHomeEventInterface | null>(null);
	const [workshops, setWorkshops] = useState<WorkshopsInterface[]>([]);
	const today = new Date();
	const selectedDay = formatedDate(today, "yyyy-MM-dd");
	const selectedDayTime = formatedDate(today, "yyyy-MM-dd'T'23:59:59");

	useEffect(() => {
		if (seniorSelectedId && selectedDay) {
			http
				.get(`${url.SENIORS}/${seniorSelectedId}/calendar_entries/at/${selectedDay}`)
				.then(res => setWorkshops(res.data.calendar_entries))
				.catch(() => setWorkshops([]));
		}
	}, [seniorSelectedId, selectedDay]);

	useEffect(() => {
		if (seniorSelectedId && selectedDayTime) {
			http
				.get(`${url.SENIORS}/${seniorSelectedId}/calendar_entries/from/${encodeURIComponent(selectedDayTime)}/type/2`)
				.then(res => setNextHomeEvent(res.data.calendar_entry))
				.catch(() => setNextHomeEvent(null));
		}
	}, [seniorSelectedId, selectedDayTime]);

	return (
		<>
			<div className="card">
				<h3 className="center">{t("calendar.__today__")}</h3>
				<div className="home-info-separator"></div>
				{seniorHome.latitude === null ? <br /> : <WeatherOfTheDay homeId={userState.seniorHomeId} />}

				{workshops.length > 0 ? (
					<>
						<div className="home-info-separator"></div>
						<h3 className="center">{t("calendar.__workshopsOfTheDay__")}</h3>
						<ul className="eventsOfTheDay">
							{workshops.map(workshop => (
								<li key={workshop.id} className="center">
									<p className="meta">{formatedDateFromString(workshop.begin_datetime, "PPp", userState.locale)}</p>
									<p>{workshop.info}</p>
									<br />
								</li>
							))}
						</ul>
					</>
				) : (
					<>
						<div className="home-info-separator"></div>
						<h3 className="center">{t("calendar.__workshopsOfTheDay__")}</h3>
						<p className="meta center">{t("calendar.__noWorkshopToday__")}</p>
					</>
				)}
			</div>
			{nextHomeEvent ? (
				<div className="card">
					<h3 className="center">{t("calendar.__nextEvent__")}</h3>
					<p className="meta center">{formatedDateFromString(nextHomeEvent.begin_datetime, "PPp", userState.locale)}</p>
					<p className="center">{nextHomeEvent.info}</p>
				</div>
			) : null}
		</>
	);
};
export default SeniorHomeEvents;
