import React from "react";
import { useTranslation } from "react-i18next";
import { formatedDateFromString } from "../../utils/Datetime";

interface ModalProps {
	setShowEvent: Function;
	eventInfo: {
		event: {
			_def: {
				title: string;
				extendedProps: {
					detail: string;
					entry_type_id: number;
					end_datetime: string | null;
					begin_datetime: string;
				};
			};
		};
	};
	locale: string;
}

const ModalEventDetails = ({ setShowEvent, eventInfo, locale }: ModalProps) => {
	const { t } = useTranslation("common");
	let event = eventInfo.event._def;

	const eventTypes = (type: number) => {
		switch (type) {
			case 1:
				return t("calendar.__workshop__");
			case 2:
				return t("calendar.__event__");
			default:
				return t("calendar.__information__");
		}
	};

	return (
		<>
			<h4 className="center mt-5">{eventTypes(event.extendedProps.entry_type_id)}</h4>
			<h3>{event.title}</h3>
			{event.extendedProps.detail === "null" ? null : <p className="meta">{event.extendedProps.detail}</p>}
			<p>
				{t("calendar.__appointment__")} {formatedDateFromString(event.extendedProps.begin_datetime, "PPPPp", locale)}
			</p>
			{event.extendedProps.end_datetime ? (
				<p>
					{t("calendar.__endAppointment__")} {formatedDateFromString(event.extendedProps.end_datetime, "PPPPp", locale)}
				</p>
			) : null}
			<br />
			<button className="light-button" onClick={() => setShowEvent(false)}>
				{t("common.__close__")}
			</button>
		</>
	);
};
export default ModalEventDetails;
