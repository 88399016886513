import React from "react";
import { useTranslation } from "react-i18next";
import { Table, SelectColumnFilter } from "../../../utils/Table";
import { BsCheckCircleFill, BsClock, BsXCircleFill, BsTrash } from "react-icons/bs";
import { StoryInterface } from "../../../interfaces/StoryInterface";
import { formatedDateFromString } from "../../../utils/Datetime";

interface Props {
	stories: StoryInterface[];
	setShowRemoveConfirmation: Function;
	userLocale: string;
	isPrincipal: boolean;
}

export default function SeniorStoriesTable({ stories, setShowRemoveConfirmation, userLocale, isPrincipal }: Props) {
	const { t } = useTranslation("common");

	const columns = [
		{
			Header: t("link.__status__"),
			accessor: "status",
			Filter: SelectColumnFilter,
			maxWidth: 40,
			minWidth: 30,
			width: 35,
			Cell: (row: any) => (
				<div className="center">
					{row.row.original.accepted ? (
						<BsCheckCircleFill className="check-icon blue" />
					) : row.row.original.accepted === false ? (
						<BsXCircleFill className="check-icon red" />
					) : (
						<BsClock className="check-icon grey" />
					)}
				</div>
			)
		},
		{
			Header: t("link.__bio__"),
			accessor: "life_story",
			Cell: (row: any) => (
				<>
					{row.row.original.moderated_at ? (
						<p className="meta">
							{t("link.__moderationDate__")} {formatedDateFromString(row.row.original.moderated_at, "PPP", userLocale)}
						</p>
					) : (
						<p className="meta">{t("link.__pictsPending__")}</p>
					)}
					{row.row.original.life_story}
				</>
			)
		},
		{
			Header: "",
			accessor: "id",
			disableFilters: true,
			disableSortBy: true,
			maxWidth: 20,
			minWidth: 20,
			width: 20,
			Cell: (row: any) =>
				isPrincipal ? (
					<div className="right-menu">
						<BsTrash
							className="check-icon fake-link"
							onClick={() => setShowRemoveConfirmation({ show: true, info: row.row.original })}
						/>
					</div>
				) : null
		}
	];

	return <Table columns={columns} data={stories} displayPaginationSelection={false} />;
}
