import React from "react";
import { useParams } from "react-router-dom";
import {UserStateInterface} from "../interfaces/UserStateInterface";
import useWindowSize from "../hooks/useWindowSize";
import ContentLayout from "../Layout/ContentLayout";
import SocialNews from "../components/Blog/SocialNews";
import SinglePost from "../components/Blog/SinglePost";

interface SocialProps {
	userState: UserStateInterface;
	userDispatch: React.Dispatch<any>;
}

export default function Social({ userState, userDispatch }: SocialProps) {
	const { postId } = useParams<{ postId?: string }>();
	const seniorSelectedId = userState.seniorSelectedId;
	const size = useWindowSize();

	return size.width > 768 ? (
		<ContentLayout userState={userState} userDispatch={userDispatch}>
			{postId ? (
				<SinglePost postId={postId} userState={userState} seniorSelectedId={seniorSelectedId} />
			) : (
				<SocialNews seniorSelectedId={seniorSelectedId} userState={userState} />
			)}
		</ContentLayout>
	) : (
		<div className="content-container">
			{postId ? (
				<SinglePost postId={postId} userState={userState} seniorSelectedId={seniorSelectedId} />
			) : (
				<SocialNews seniorSelectedId={seniorSelectedId} userState={userState} />
			)}
		</div>
	);
}
