import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-grid-system";

interface ProgressBarProps {
	stockageSize: number;
	stockageQuantity: number;
}

export default function ProgressBar({ stockageSize, stockageQuantity }: ProgressBarProps) {
	const { t } = useTranslation("common");
	const [completed, setCompleted] = useState(0);

	useEffect(() => {
		if (stockageSize) {
			const percentage = Math.round((stockageSize * 100) / 200000000);
			setCompleted(percentage);
		}
	}, [stockageSize]);

	return (
		<Row>
			<Col xs={12} sm={12} md={12} lg={12}>
				<p style={{ marginTop: "0.75rem" }}>{t("link.__deviceCapacity__")}</p>
				<p className="meta" style={{ marginBottom: "0.5rem" }}>
					{t("link.__deviceCapacityWarning__")}
				</p>
			</Col>
			<Col xs={12} sm={12} md={4} lg={4}>
				<p className="meta" style={{ marginTop: "0.4rem" }}>
					<b>
						{t("link.__photoCounter__")} {stockageQuantity}
					</b>
				</p>
			</Col>
			<Col xs={12} sm={12} md={8} lg={8}>
				<div className="progress-bar-container">
					<div className="progress-bar">
						<div className="progress-filler" style={{ width: `${completed}%` }}>
							<span className="progress-label">{completed}%</span>
						</div>
					</div>
				</div>
			</Col>
		</Row>
	);
}
