const ErrorSuccessReducer = (state, action) => {
	switch (action.type) {
		case "DISPLAY_ERROR":
			return {
				...state,
				error: true,
				msg: action.payload,
				success: false
			};

		case "DISPLAY_SUCCESS":
			return {
				...state,
				error: false,
				msg: action.payload,
				success: true
			};

		case "RESET_ES":
			return {
				...state,
				error: false,
				msg: null,
				success: false
			};

		default:
			return state;
	}
};

export default ErrorSuccessReducer;
