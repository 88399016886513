import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Link } from "react-router-dom";
import { BsDownload } from "react-icons/bs";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";

export default function UploadAvatarContainer({ userState, setHttpResponse, setIsLoading }) {
	const { t } = useTranslation("common");
	const [upImg, setUpImg] = useState();
	const [imgRef, setImgRef] = useState(null);
	const [crop, setCrop] = useState({ unit: "px", width: 150, aspect: 1 / 1 });
	const [previewUrl, setPreviewUrl] = useState();
	const [config, setConfig] = useState({});
	const [avatarImg, setAvatarImg] = useState(null);
	const [scale, setScale] = useState({ scaleX: 0.0, scaleY: 0.0 });
	const seniorSelectedId = userState.seniorSelectedId;
	const [rgpd, setRgpd] = useState(false);
	const [errorRgpd, setErrorRgpd] = useState(false);

	const onSelectFile = e => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => setUpImg(reader.result));
			reader.readAsDataURL(e.target.files[0]);
			setAvatarImg(e.target.files[0]);
		}
	};

	const onLoad = useCallback(img => {
		setScale({ scaleX: img.naturalWidth / img.width, scaleY: img.naturalHeight / img.height });
		setImgRef(img);
	}, []);

	const makeClientCrop = async crop => {
		if (imgRef && crop.width && crop.height) {
			createCropPreview(imgRef, crop, "avatar.jpeg");
		}
	};

	// const setTheCrop = args => {
	// 	setCrop(args);
	// };

	const createCropPreview = async (image, crop, fileName) => {
		const canvas = document.createElement("canvas");
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext("2d");

		const x = crop.x * scale.scaleX;
		const y = crop.y * scale.scaleY;
		const width = crop.width * scale.scaleX;
		const height = crop.height * scale.scaleY;

		ctx.drawImage(image, x, y, width, height, 0, 0, crop.width, crop.height);

		// configuration : to build avatar in the backend
		setConfig({
			width: width,
			height: height,
			x: x,
			y: y
		});

		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					reject(new Error("Canvas is empty"));
					return;
				}

				blob.name = fileName;
				window.URL.revokeObjectURL(previewUrl);
				setPreviewUrl(window.URL.createObjectURL(blob));
			}, "image/jpeg");
		});
	};

	const onSubmit = e => {
		e.preventDefault();
		setIsLoading(true);
		if (!rgpd) {
			setIsLoading(false);
			setErrorRgpd(true);
		} else {
			setErrorRgpd(false);
			const min = Math.min(Math.floor(config.width), Math.floor(config.height));
			const configToSend = {
				width: min,
				height: min,
				x: Math.floor(config.x),
				y: Math.floor(config.y)
			};

			const avatarData = new FormData();
			avatarData.append("avatar", avatarImg);
			avatarData.append("config", JSON.stringify(configToSend));

			http
				.post(`${url.AVATAR}/${seniorSelectedId}/avatar`, avatarData)
				.then(() => {
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: "smooth"
					});
					setIsLoading(false);
					setHttpResponse({ error: false });
					setAvatarImg(null);
				})
				.catch(() => {
					setIsLoading(false);
					setHttpResponse({ error: true });
				});
		}
	};

	return (
		<form onSubmit={onSubmit} encType="multipart/form-data">
			{errorRgpd ? <p className="alert">{t("common.__rgpdError__")}</p> : null}

			{avatarImg ? (
				<>
					<div className="colored-background">
						<p>
							<b>{t("link.__avatarMoveCircle__")}</b>
							<p className="meta">{t("link.__avatarZoomWarning__")}</p>
						</p>
					</div>
					<div className="space"></div>
					<ReactCrop
						src={upImg}
						onImageLoaded={onLoad}
						crop={crop}
						onChange={c => setCrop(c)}
						onComplete={makeClientCrop}
						circularCrop={true}
						style={{
							borderRadius: "7px"
						}}
					/>

					{previewUrl && (
						<img
							alt="Crop preview"
							src={previewUrl}
							style={{
								borderRadius: "50%",
								textAlign: "center",
								width: "150px",
								height: "150px",
								marginTop: "25px"
							}}
							id="preview-avatar"
						/>
					)}
					<div className="rgpd">
						<p className="inline meta-checkbox">
							<input type="checkbox" checked={rgpd} name="rgpd" onChange={() => setRgpd(prevRgpd => !prevRgpd)} />
							{t("common.__checkRgpd__")} ( <Link to={"/rgpd"}>{t("common.__moreInfo__")}</Link> ).
						</p>
					</div>
					<div className="mb-5">
						<button type="button" className="light-button" onClick={() => setAvatarImg(null)}>
							{t("common.__cancel__")}
						</button>
						<button type="submit" className="dark-button">
							{t("common.__save__")}
						</button>
					</div>
				</>
			) : (
				<div>
					<p className="meta mt-5 mb-5">{t("link.__avatarAdvice__")}</p>
					<input type="file" id="customFile" name="file" accept=".jpg, .jpeg" className="inputfile" onChange={onSelectFile} />
					<label htmlFor="customFile">
						<BsDownload style={{ marginRight: "1rem", verticalAlign: "middle" }} />
						{t("link.__selectPict__")}
					</label>
				</div>
			)}
		</form>
	);
}
