import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ErrorSuccessContext } from "../../../contexts/ErrorSuccessContext";
import AddLifestory from "./AddLifestory";
import HandleErrorSuccess from "../../../Layout/HandleErrorSuccess";
import { UserStateInterface } from "../../../interfaces/UserStateInterface";
import http from "../../../Services/Interceptor";
import { SENIORS } from "../../../Constants/Url";
import HeaderContainer from "../../../Layout/HeaderContainer";
import SeniorStoriesTable from "./SeniorStoriesTable";
import { StoryInterface } from "../../../interfaces/StoryInterface";
import ModalContainer from "../../../Layout/ModalContainer";
import RemoveStoryConfirmation from "./RemoveStoryConfirmation";

const SeniorStories = ({ userState }: { userState: UserStateInterface }) => {
	const { t } = useTranslation("common");
	const { esState, esDispatch } = useContext(ErrorSuccessContext);
	const isPrincipal = userState.familyUser.principal;
	const seniorSelectedId = userState.seniorSelectedId;
	const [update, setUpdate] = useState(false);
	const [allLifestories, setAllLifestories] = useState<StoryInterface[]>([]);
	const [showRemoveConfirmation, setShowRemoveConfirmation] = useState({ show: false, info: null });
	const [showAddStory, setShowAddStory] = useState({ show: false, info: seniorSelectedId });

	useEffect(() => {
		if (seniorSelectedId) {
			http
				.get(`${SENIORS}/${seniorSelectedId}/life_stories`)
				.then(res => {
					const stories = res.data.senior_life_stories;

					const allStories = stories.map((story: StoryInterface) => ({
						...story,
						status:
							story.accepted === true
								? t("link.__bioAccepted__")
								: story.accepted === false
								? t("link.__bioRefused__")
								: t("link.__bioPending__")
					}));

					setAllLifestories(allStories);
				})
				.catch(() => {
					esDispatch({
						type: "DISPLAY_ERROR",
						msg: t("common.__noData__")
					});
				});
		}
		// eslint-disable-next-line
	}, [seniorSelectedId, update]);

	useEffect(() => {
		return function cleanup() {
			esDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	const deleteStory = (lifestory: string, lifestoryId: number) => {
		esDispatch({
			type: "RESET_ES"
		});
		const data = {
			_method: "DELETE",
			senior_id: seniorSelectedId,
			life_story: lifestory
		};

		http
			.post(`${SENIORS}/life_stories/${lifestoryId}`, data)
			.then(() => {
				esDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("link.__removeBioSuccess__")
				});
				setUpdate(prevUpdate => !prevUpdate);
			})
			.catch(() => {
				esDispatch({
					type: "DISPLAY_ERROR",
					payload: t("link.__removeBioError__")
				});
			});
	};

	const addStory = () => {
		esDispatch({
			type: "RESET_ES"
		});
		setShowAddStory({ show: true, info: seniorSelectedId });
	};

	return (
		<>
			<div className="content-card">
				<HeaderContainer title={t("link.__bio__")} />
				<HandleErrorSuccess esState={esState} esDispatch={esDispatch} />

				<div className="space-between">
					<p className="meta mt-2">{t("link.__bioWarning__")}</p>
					{isPrincipal ? (
						<button type="button" className="dark-button" onClick={addStory}>
							{t("common.__add__")}
						</button>
					) : null}
				</div>

				{allLifestories.length > 0 ? (
					<SeniorStoriesTable
						stories={allLifestories}
						setShowRemoveConfirmation={setShowRemoveConfirmation}
						userLocale={userState.locale}
						isPrincipal={isPrincipal}
					/>
				) : null}
			</div>
			<ModalContainer show={showRemoveConfirmation} setShow={setShowRemoveConfirmation}>
				<RemoveStoryConfirmation
					story={showRemoveConfirmation.info}
					setShow={setShowRemoveConfirmation}
					deleteStory={deleteStory}
				/>
			</ModalContainer>
			<ModalContainer show={showAddStory} setShow={setShowAddStory}>
				<AddLifestory
					setShowAddStory={setShowAddStory}
					setUpdate={setUpdate}
					seniorSelectedId={seniorSelectedId}
					esDispatch={esDispatch}
				/>
			</ModalContainer>
		</>
	);
};

export default SeniorStories;
