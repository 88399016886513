const FamilyUserReducer = (state, action) => {
	switch (action.type) {
		case "LOGIN": {
			return {
				...state,
				isLogged: true,
				error: false,
				familyUser: action.payload,
				seniorSelectedId: action.payload.seniors[0].id,
				seniorHomeId: action.payload.seniors[0].home_id,
				locale: action.payload.locale
			};
		}
		case "SET_SENIOR": {
			const mySeniors = state.familyUser.seniors;
			const senior = mySeniors.find(senior => senior.id === action.payload);
			return {
				...state,
				seniorSelectedId: action.payload,
				seniorHomeId: senior.home_id
			};
		}

		case "LOGIN_ERROR":
			return {
				...state,
				error: true,
				isLogged: false
			};

		case "UPDATE_USER": {
			const familyUser = action.payload;
			const seniors = state.familyUser.seniors;
			familyUser.seniors = seniors;
			localStorage.setItem("familyUser", JSON.stringify(familyUser));
			localStorage.setItem("locale", JSON.stringify(action.payload.locale));
			return {
				...state,
				familyUser: familyUser,
				locale: action.payload.locale
			};
		}
		case "LOGOUT":
			return {
				...state,
				isLogged: false,
				error: false,
				familyUser: null
			};

		case "READ_SENIOR_DOCUMENT": {
			const seniorId = action.payload;
			const seniors = state.familyUser.seniors;
			const seniorsUpdated = seniors.map(senior => {
				return {
					...senior,
					nb_unread_senior_document:
						seniorId === senior.id ? senior.nb_unread_senior_document - 1 : senior.nb_unread_senior_document
				};
			});
			return {
				...state,
				familyUser: {
					...state.familyUser,
					seniors: seniorsUpdated
				}
			};
		}
		case "READ_HOME_DOCUMENT": {
			const seniorId = action.payload;
			const seniors = state.familyUser.seniors;
			const seniorSelected = seniors.find(senior => senior.id === parseInt(seniorId));
			const seniorSelectedHomeId = seniorSelected.home_id;
			const seniorsUpdated = seniors.map(senior => {
				return {
					...senior,
					nb_unread_home_document:
						senior.home_id === parseInt(seniorSelectedHomeId) ? senior.nb_unread_home_document - 1 : senior.nb_unread_home_document
				};
			});

			const familyUser = state.familyUser;
			familyUser.seniors = seniorsUpdated;

			return {
				...state,
				familyUser: familyUser
			};
		}

		case "READ_MESSAGE": {
			const seniorId = action.payload.senior;
			const seniors = state.familyUser.seniors;
			const seniorsUpdated = seniors.map(senior => {
				return {
					...senior,
					nb_unread_message: seniorId === senior.id ? senior.nb_unread_message - action.payload.nb : senior.nb_unread_message
				};
			});
			return {
				...state,
				familyUser: {
					...state.familyUser,
					seniors: seniorsUpdated
				}
			};
		}

		case "READ_SENIOR_MESSAGE": {
			const seniorId = action.payload;
			const seniors = state.familyUser.seniors;
			const seniorsUpdated = seniors.map(senior => {
				return {
					...senior,
					nb_unread_senior_message: seniorId === senior.id ? senior.nb_unread_senior_message - 1 : senior.nb_unread_senior_message
				};
			});
			return {
				...state,
				familyUser: {
					...state.familyUser,
					seniors: seniorsUpdated
				}
			};
		}

		case "UPDATE_COUNTERS": {
			const seniorId = action.payload.senior;
			const counters = action.payload.counters;
			const seniors = state.familyUser.seniors;
			const seniorsUpdated = seniors.map(senior => {
				return {
					...senior,
					nb_unread_senior_message: seniorId === senior.id ? counters.nb_unread_senior_message : senior.nb_unread_senior_message,
					nb_unread_message: seniorId === senior.id ? counters.nb_unread_message : senior.nb_unread_message,
					nb_unread_senior_document:
						seniorId === senior.id ? counters.nb_unread_senior_document : senior.nb_unread_senior_document,
					nb_unread_home_document: seniorId === senior.id ? counters.nb_unread_home_document : senior.nb_unread_home_document
				};
			});
			return {
				...state,
				familyUser: {
					...state.familyUser,
					seniors: seniorsUpdated
				}
			};
		}

		default:
			return state;
	}
};

export default FamilyUserReducer;
