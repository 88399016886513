import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import "./UserSettings.scss";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import ErrorContainer from "../../Layout/ErrorContainer";
import { UserStateInterface } from "../../interfaces/UserStateInterface";

interface UpdateAffiliateInterface {
	showUpdateModal: {
		show: boolean;
		affiliate: {
			id: number;
			first_name: string;
			last_name: string;
			email: string;
			login: string;
			seniors: {
				id: number;
				first_name: string;
				last_name: string;
			}[];
		} | null;
	};
	setShowUpdateModal: Function;
	userState: UserStateInterface;
}

interface InputValues {
	firstname: string;
	lastname: string;
	email: string;
}

const UpdateAffiliate = ({ showUpdateModal, setShowUpdateModal, userState }: UpdateAffiliateInterface) => {
	const { t } = useTranslation("common");
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useFormContext<InputValues>();
	const [httpResponse, setHttpResponse] = useState<{ error: boolean; msg: any } | null>(null);
	const mySeniors = userState.familyUser.seniors;
	const affiliateDetails = showUpdateModal.affiliate;
	const seniors = affiliateDetails ? affiliateDetails.seniors : [];
	const [affiliateSeniors, setAffiliateSeniors] = useState(seniors);
	const seniorIds = affiliateSeniors.map(senior => senior.id);
	const [seniorsList, setSeniorsList] = useState(mySeniors.filter(senior => !seniorIds.includes(senior.id)));

	const handleRemoveSenior = (senior: any) => {
		setAffiliateSeniors(affiliateSeniors.filter(user => user.id !== senior.id));
		setSeniorsList([...seniorsList, senior]);
	};

	const handleAddSenior = (senior: any) => {
		setAffiliateSeniors([...affiliateSeniors, senior]);
		setSeniorsList(seniorsList.filter(user => user.id !== senior.id));
	};

	const onSubmit = (data: InputValues) => {
		setHttpResponse(null);
		const senior_ids = affiliateSeniors.map(senior => senior.id);
		const affiliateId = affiliateDetails ? affiliateDetails.id : null;
		if (affiliateId) {
			const userData = {
				family_user: {
					first_name: data.firstname,
					last_name: data.lastname,
					email: data.email,
					senior_ids: senior_ids
				}
			};
			http
				.put(`${url.FAMILY_URL}/${affiliateId}?senior_ids=true`, userData)
				.then(() => setShowUpdateModal({ show: false, affiliate: null }))
				.catch(() => setHttpResponse({ error: true, msg: null }));
		} else {
			setHttpResponse({ error: true, msg: null });
		}
	};

	return (
		<div>
			<h2 className="center">{t("user.__updateUserAccount__")}</h2>
			<div className="separator"></div>
			<div className="familySettings">
				<form onSubmit={handleSubmit(onSubmit)}>
					<ErrorContainer
						alert={t("user.__updateUserAccountError__")}
						success={t("user.__updateUserAccountSuccess__")}
						httpResponse={httpResponse}
						setHttpResponse={setHttpResponse}
					/>
					<p className="meta">Identifiant : {affiliateDetails ? affiliateDetails.login : null}</p>
					<div className="pt-5"></div>
					<p>{t("common.__firstname__")}</p>
					<input
						type="text"
						aria-describedby="firstnameHelp"
						{...register("firstname", { required: true })}
						defaultValue={affiliateDetails ? affiliateDetails.first_name : ""}
					/>
					{errors.firstname ? (
						<p className="red">{t("common.__requiredInput__")}</p>
					) : (
						<small id="firstnameHelp" className="meta-input right">
							{t("common.__required__")}
						</small>
					)}
					<div className="pt-5"></div>
					<p>{t("common.__name__")}</p>
					<input
						type="text"
						aria-describedby="lastnameHelp"
						{...register("lastname", { required: true })}
						defaultValue={affiliateDetails ? affiliateDetails.last_name : ""}
					/>
					{errors.lastname ? (
						<p className="red">{t("common.__requiredInput__")}</p>
					) : (
						<small id="lastnameHelp" className="meta-input right">
							{t("common.__required__")}
						</small>
					)}
					<div className="pt-5"></div>
					<p>{t("common.__mail__")}</p>
					<input
						type="email"
						aria-describedby="emailHelp"
						{...register("email", { required: true })}
						defaultValue={affiliateDetails ? affiliateDetails.email : ""}
					/>
					{errors.email ? (
						<p className="red">{t("common.__requiredInput__")}</p>
					) : (
						<small id="emailHelp" className="meta-input right">
							{t("common.__required__")}
						</small>
					)}
					<div className="pt-5"></div>
					{seniorsList.length > 1 ? (
						<ul className="affiliates-list">
							{seniorsList.map(senior => (
								<li key={senior.id}>
									<p className="inline">
										<input type="checkbox" name="senior" onChange={() => handleAddSenior(senior)} value={senior.id} />{" "}
										{senior.first_name} {senior.last_name}
									</p>
								</li>
							))}
							{affiliateSeniors.map(senior => (
								<li key={senior.id}>
									<p className="inline">
										<input type="checkbox" checked name="senior" onChange={() => handleRemoveSenior(senior)} value={senior.id} />{" "}
										{senior.first_name} {senior.last_name}
									</p>
								</li>
							))}
						</ul>
					) : null}
					<div className="pt-5"></div>
					<button type="submit" className="dark-button">
						{t("common.__save__")}
					</button>
				</form>
			</div>
		</div>
	);
};

export default UpdateAffiliate;
