import React, { useContext } from "react";
import { FamilyUserContext } from "../contexts/FamilyUserContext";
import Login from "../pages/Login";
import Footer from "./Footer";
import ResponsiveNav from "../components/Header/ResponsiveNav";

const MainLayout = ({ children }: any) => {
	const { userState } = useContext(FamilyUserContext);

	return userState.isLogged ? (
		<>
			<ResponsiveNav userState={userState} />
			<div className="container">{children}</div>
			<Footer />
		</>
	) : (
		<Login />
	);
};
export default React.memo(MainLayout);
