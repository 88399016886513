import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const MyInputPassword = props => {
	const { t } = useTranslation("common");
	const { register, formState: { errors } } = useFormContext();
	const [viewPassword, setViewPassword] = useState(false);

	const onChange = e => {
		if (props.onChange !== undefined) {
			props.onChange(e);
		}
	};

	return (
		<>
			<label htmlFor={props.name} className="meta">
				{props.label}
			</label>
			<div className="input-password">
				<input
					id={props.name}
					name={props.name}
					type={viewPassword ? "text" : "password"}
					{...register(props.name, {
						required: props.behavior.required,
						pattern: props.behavior.pattern,
						validate: props.behavior.validate
					})}
					onChange={e => onChange(e)}
				/>
				<button
					className="view-password"
					type="button"
					onClick={() => setViewPassword(prevViewPassword => !prevViewPassword)}
				>
					{viewPassword ? <BsEyeSlash style={{ height: "1.5rem" }} /> : <BsEye style={{ height: "1.5rem" }} />}
				</button>
			</div>
			{props.behavior.required ? (
				<>
					<small className="form-text text-muted right" id={`${props.name}Error`}>
						<span className="meta">{t("common.__required__")}</span>
					</small>

					{props.behavior.pattern ? (
						errors[props.name] ? (
							<p className="alert">{t("common.__passwordWarning__")}</p>
						) : null
					) : null}
				</>
			) : (
				<small className="form-text text-muted right" id={`${props.name}Error`}>
					{errors[props.name] ? <span className="error">{t("common.__requiredInput__")}</span> : null}
				</small>
			)}

			{props.behavior.validate ? (
				errors[props.name] ? (
					<p className="alert">{t("common.__confirmPasswordError__")}</p>
				) : null
			) : null}
		</>
	);
};

export default MyInputPassword;
