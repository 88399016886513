import React from "react";
import { BsBackspaceFill, BsFillCircleFill, BsFillSquareFill } from "react-icons/bs";
import Chrono from "./Chrono";

class VoiceRecorder extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			audioURL: null,
			file: null,
			isRecording: false,
			isStopped: true
		};
	}

	async componentDidMount() {
		const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
		// init recording
		this.mediaRecorder = new MediaRecorder(stream);
		// init data storage for video chunks
		this.chunks = [];
		// listen for data from media recorder
		this.mediaRecorder.ondataavailable = e => {
			if (e.data && e.data.size > 0) {
				this.chunks.push(e.data);
			}
		};
	}

	startRecording(e) {
		e.preventDefault();
		// wipe old data chunks
		this.chunks = [];
		// start recorder with 10ms buffer
		this.mediaRecorder.start(10);
		this.setState({ isRecording: true });
		this.setState({ isStopped: false });
	}

	stopRecording(e) {
		e.preventDefault();
		// stop the recorder
		this.mediaRecorder.stop();
		this.setState({ isRecording: false });
		this.setState({ isStopped: true });
		// save the video to memory
		const fileToSave = new File(this.chunks, `e4link_${this.props.index}.mp3`, {
			type: "audio/mp3",
			lastModified: Date.now()
		});
		const audioURL = window.URL.createObjectURL(fileToSave);
		this.setState({
			file: fileToSave,
			audioURL: audioURL
		});
		this.props.setAudioBlob([...this.props.audioBlob, fileToSave]);
	}

	deleteAudio(file) {
		this.props.removeAudioFile(file, this.props.index);
		this.setState({
			audioURL: null,
			file: null
		});
	}

	render() {
		return this.state.audioURL ? (
			<div className="mood-card">
				<BsBackspaceFill
					className="red fake-link right"
					style={{ height: "1.5rem", width: "auto", verticalAlign: "auto", margin: "0 0 1rem 1rem" }}
					role="button"
					onClick={() => this.deleteAudio(this.state.file)}
				/>
				<p>Ecoutez votre enregistrement :</p>
				<audio controls="controls" src={this.state.audioURL || ""} />
			</div>
		) : (
			<div className="mood-card">
				<button
					type="button"
					onClick={e => this.startRecording(e)}
					className="light-button"
					disabled={this.state.isRecording}
				>
					<BsFillCircleFill
						style={
							this.state.isRecording
								? { color: "red", verticalAlign: "middle", marginRight: "0.5rem", height: "1.1rem", width: "auto" }
								: { verticalAlign: "middle", marginRight: "0.5rem", height: "1.1rem", width: "auto" }
						}
					/>
					Démarrer l'enregistrement
				</button>
				<button
					onClick={e => this.stopRecording(e)}
					className="light-button"
					disabled={!this.state.isRecording}
					style={{ marginRight: "1rem" }}
					type="button"
				>
					<BsFillSquareFill style={{ verticalAlign: "middle", marginRight: "0.5rem", height: "1rem", width: "auto" }} />
					Stop
				</button>
				<Chrono isRecording={this.state.isRecording} isStopped={this.state.isStopped} />
			</div>
		);
	}
}
export default VoiceRecorder;
