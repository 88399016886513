import React from "react";
import { useTranslation } from "react-i18next";
import "./Timeline.scss";
import { BsCameraVideo } from "react-icons/bs";
import {
	getActivityTitle,
	getActivityHealthWorkerName,
	getActivityAction,
	getActivityDetail,
	getCategoryClassName,
	getVisitorName
} from "./Activities";
import getActivityMood from "./getActivityMood";
import ActivityInterface from "../../interfaces/ActivityInterface";
import { formatedDateFromString } from "../../utils/Datetime";

interface TimelineProps {
	activities: ActivityInterface[];
	healthWorkerMap: Object;
	visitorMap: Object;
	displayDate: boolean;
	timelineError: boolean;
	userLocale: string;
}

const displayMood = (o: number) => {
	switch (o) {
		case 17:
			return <BsCameraVideo color="#4a4e64" className="mood-emoticon-timeline" />;
		case 13:
			return <img src="/img/qrcode-family.png" className="qrcode-timeline" alt="mood" />;
		default:
			return getActivityMood(o, false);
	}
};

const Timeline = ({ activities, healthWorkerMap, visitorMap, displayDate, timelineError, userLocale }: TimelineProps) => {
	const { t } = useTranslation("common");
	const data = activities.map(function (activity) {
		return {
			...activity,
			did_string: `${getActivityDetail(activity.dval, activity.aid)}`
		};
	});

	const blockToDisplay = (index: number, activity: ActivityInterface, handleDate: boolean, day: string | null) => {
		const datetime = activity.dt.split(" ").join("T");
		return (
			<div key={index} className="timeline-action">
				{displayDate && handleDate && day && <p className="day-timeline">{formatedDateFromString(day, "PPPP", userLocale)}</p>}
				<div className="timeline-block">
					<div className="left-timeline">
						<p className="dt-timeline">{formatedDateFromString(datetime, "p", userLocale)}</p>
						{activity.aid === 17 ? displayMood(17) : displayMood(activity.o)}
					</div>
					<div className={`bullet ${getCategoryClassName(activity.aid)}`}></div>
					<div className="timeline-activity">
						<h2 className="timeline-category">{getActivityTitle(activity.aid)}</h2>
						<p className="timeline-action">{getActivityAction(activity.aid)}</p>

						{healthWorkerMap && activity.hwid ? (
							<p className="timeline-healthworker">
								<strong>{t("timeline.__healthworker__")} : </strong>
								{getActivityHealthWorkerName(activity.hwid, healthWorkerMap)}
							</p>
						) : null}

						{activity.fuid ? (
							visitorMap ? (
								<p className="timeline-healthworker">
									<strong>
										{t("timeline.__visitor__")} : {getVisitorName(activity.fuid, visitorMap)}
									</strong>
								</p>
							) : null
						) : null}
					</div>
				</div>
			</div>
		);
	};

	const extractDate = (dateTime: string) => {
		const dateFormated = dateTime.split(" ");
		return dateFormated[0];
	};

	const display = () => {
		let currentDate = "";
		let result = [];

		for (let i = 0; i < data.length; i++) {
			const activity: ActivityInterface = data[i];
			const dateToCompare = extractDate(activity.dt);
			if (currentDate === dateToCompare) {
				result.push(blockToDisplay(i, activity, false, null));
			} else {
				result.push(blockToDisplay(i, activity, true, dateToCompare));
				currentDate = dateToCompare;
			}
		}
		return result;
	};

	return (
		<div className="timeline-container">
			<div className="timeline">{timelineError ? <p>{t("common.__noData__")}</p> : <div>{display()}</div>}</div>
		</div>
	);
};

export default Timeline;
