import React from "react";
import Rgpd from "../components/UserSettings/Rgpd";
import Footer from "../Layout/Footer";

export default function RgpdContainer() {
	return (
		<div className="calendar-container">
			<Rgpd />
			<Footer />
		</div>
	);
}
