import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { BsExclamationTriangle } from "react-icons/bs";
import { useFormContext } from "react-hook-form";
import MyInputText from "../../utils/form/MyInputText";
import MyInputTextarea from "../../utils/form/MyInputTextarea";
import ErrorContainer from "../../Layout/ErrorContainer";
import * as url from "../../Constants/Url";
import http from "../../Services/Interceptor";
import { UserStateInterface } from "../../interfaces/UserStateInterface";
import { RecipientsInterface } from "../../interfaces/MessagesInterface";
import "./Media.scss";
import HeaderContainer from "../../Layout/HeaderContainer";

interface InputValues {
	subject: string;
	message: string;
}

interface MessageBoxProps {
	userState: UserStateInterface;
}

const MessageBox = ({ userState }: MessageBoxProps) => {
	const { t } = useTranslation("common");
	const { handleSubmit } = useFormContext<InputValues>();
	const history = useHistory();
	const [recipientError, setRecipientError] = useState(false);
	const [myRecipient, setMyRecipient] = useState<string | null>(null);
	const [httpResponse, setHttpResponse] = useState<{ error: true; msg: any } | null>(null);
	const seniorSelectedId = userState.seniorSelectedId;
	const [recipients, setRecipients] = useState<RecipientsInterface[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (seniorSelectedId) {
			setLoading(true);
			http
				.get(`${url.SENIORS}/${seniorSelectedId}/message/recipients`)
				.then(res => {
					const recipients: RecipientsInterface[] = res.data.user_recipients;
					const fullDesignation = recipients.map(recipient => {
						return {
							...recipient,
							name: recipient.designation
								? `${recipient.first_name} ${recipient.last_name}, ${recipient.designation}`
								: `${recipient.first_name} ${recipient.last_name}`
						};
					});
					setRecipients(fullDesignation);
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
					setRecipients([]);
				});
		}
	}, [seniorSelectedId]);

	const onSubmit = (values: InputValues) => {
		setHttpResponse(null);
		if (!myRecipient) {
			setRecipientError(true);
		}
		const data = {
			senior_id: seniorSelectedId,
			user_id: myRecipient,
			subject: values.subject,
			message: values.message
		};
		http
			.post(`${url.HOMES}/messages/new`, data)
			.then(() => history.push("/messages/1"))
			.catch(() => setHttpResponse({ error: true, msg: null }));
	};

	return (
		<>
			<HeaderContainer title={t("mailbox.__sendMsg__")} />
			<div className="alert-info">
				<BsExclamationTriangle className="alert-icon" />
				<span className="meta">
					{t("mailbox.__mailboxInfo2__")}
					<Link to="/profile/1">
						<b>Link</b>
					</Link>
				</span>
			</div>

			{/* <p>{t("mailbox.__yourRecipient__")}</p> */}
			<form onSubmit={handleSubmit(onSubmit)}>
				{recipientError ? <p className="error">{t("mailbox.__pleaseSelectRecipient__")}</p> : <br />}
				<ErrorContainer
					alert={t("link.__msgSentError__")}
					success={t("link.__msgSentSuccess__")}
					httpResponse={httpResponse}
					setHttpResponse={setHttpResponse}
				/>
				{loading ? (
					<br />
				) : recipients.length > 0 ? (
					<select name="recipient" onChange={e => setMyRecipient(e.target.value)}>
						<option>{t("mailbox.__selectRecipient__")}</option>
						{recipients.map(recipient => (
							<option key={recipient.id} value={recipient.id}>
								{recipient.name}
							</option>
						))}
					</select>
				) : (
					<p className="alert">{t("mailbox.__noReferentRegistred__")}</p>
				)}
				<MyInputText label={t("link.__msgObject__")} name="subject" behavior={{ required: true }} />
				<MyInputTextarea label={t("common.__message__")} name="message" behavior={{ required: true }} rows="10" />

				<button type="submit" className="dark-button">
					{t("common.__send__")}
				</button>
			</form>
		</>
	);
};

export default MessageBox;
