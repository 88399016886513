import format from "date-fns/format";
import { sub } from "date-fns";
import HandleLocale from "./HandleLocale";

export const today = () => new Date();

export const lastWeek = sub(new Date(), { days: 7 });

export const formatedDate = (date: Date, style: string, userLocale?: string) => {
	const locale = userLocale ? HandleLocale(userLocale) : undefined;
	return format(date, style, { locale });
};

export const formatedDateFromString = (date: string, style: string, userLocale?: string) => {
	const locale = userLocale ? HandleLocale(userLocale) : undefined;
	const completeDate = date.split(" ").join("T");
	return format(new Date(completeDate), style, { locale });
};

// class FamilyDate extends Date {
//   constructor(value: unknown) {
//     super(value);
//     this.value = value;
//   }
//   between(date: Date) {
//     // Quel est le nombre de jours entre `date` et `this.value`
//   }
//   add(tuple: Tuple): Tuple {
//   }
// }
