import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Row, Col } from "react-grid-system";
import { BsCalendar, BsFillBookmarkFill, BsBoxArrowInDown } from "react-icons/bs";
import { FaRegFilePdf } from "react-icons/fa";
import VideoPlayer from "./VideoPlayer";
import Parse from "html-react-parser";
import Comments from "./Comments/Comments";
import SocialNewsImage from "./SocialNewsImage";
import { SinglePostInterface, MediasInterface } from "../../interfaces/PostInterface";
import {UserStateInterface} from "../../interfaces/UserStateInterface";
import LikeButton from "./LikeButton";
import useAxios from "../../hooks/useAxios";
import * as url from "../../Constants/Url";
import Loader from "../../Layout/Loader";
import NoDataAvailable from "../../Layout/NoDataAvailable";
import { formatedDateFromString } from "../../utils/Datetime";
interface SinglePostProps {
	postId: string;
	seniorSelectedId: number;
	userState: UserStateInterface;
}

export default function SinglePost({ postId, seniorSelectedId, userState }: SinglePostProps) {
	const { t } = useTranslation("common");
	const { loading, data } = useAxios({ url: `${url.BASEURL}/blog_posts/${postId}` });
	const post: SinglePostInterface = data ? data.home_blog_post : null;

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
	}, []);

	const handleMedias = (medias: MediasInterface[]) => {
		if (medias.length > 0) {
			const video = medias.filter(media => media.content_type === "video/mp4");
			const pdfs = medias.filter(media => media.content_type === "application/pdf");
			const pictures = medias.filter(media => media.content_type.split("/")[0] === "image");
			return (
				<>
					{video.length > 0 &&
						video.map(v => (
							<div className="video-container mt-5" key={v.path}>
								<VideoPlayer uriVideo={v.path} contentType={v.content_type} />
							</div>
						))}
					{pdfs.length > 0 ? (
						<div>
							<p className="meta mt-5">.</p>
							<p className="meta">{t("common.__filesToDownload__")} : </p>
							{pdfs.map((elt, index) => (
								<div key={index} className="pdf-file">
									<FaRegFilePdf style={{ marginRight: "1rem" }} className="red" />
									<a href={`/storages/blog_media${elt.path}`} download={`${elt.filename}`}>
										<b>{elt.filename ? elt.filename : t("common.__pdfFile__")}</b>
									</a>
								</div>
							))}
						</div>
					) : null}
					{pictures.length > 0 ? (
						<div className="pictures-container mt-5">
							{pictures.map(pict => (
								<div key={pict.path} className="single-post-pict">
									<SocialNewsImage path={pict.path} display={"full-pict"} />
									<a
										href={`/storages/blog_media${pict.path}`}
										download={`${pict.path_original}`}
										className="single-post-pict-download"
									>
										<div className="single-post-pict-download-content">
											<BsBoxArrowInDown className="single-post-pict-download-icon" /> {t("common.__download__")}
										</div>
									</a>
								</div>
							))}
						</div>
					) : null}
				</>
			);
		}
	};

	return loading ? (
		<Loader />
	) : post ? (
		<>
			<Row className="postcard-page">
				<Col xs={6} sm={6} md={6} lg={6}>
					<h2>
						<BsFillBookmarkFill style={{ verticalAlign: "middle", color: "#00a0e3" }} /> {post.title}
					</h2>
					<div className="underliner"></div>
				</Col>
				<Col xs={6} sm={6} md={6} lg={6}>
					<Link to="/social">
						<button className="light-button right mt-5">{t("common.__blog__")}</button>
					</Link>
				</Col>
				<Col xs={6} sm={6} md={6} lg={6}>
					<p className="meta">
						<BsCalendar color="#828282" className="nav-icon" />
						{formatedDateFromString(post.date, "PPP", userState.locale)}
					</p>
				</Col>
				<Col xs={6} sm={6} md={6} lg={6}>
					<div className="right">
						<LikeButton post={post} />
					</div>
				</Col>
				<Col xs={12} sm={12} md={12} lg={12}>
					<div className="space"></div>
					<div className="tinymce-content">{Parse(post.body)}</div>
				</Col>
				<Col xs={12} sm={12} md={12} lg={12}>
					{post.medias.length === 0 ? null : <div className="mt-5">{handleMedias(post.medias)}</div>}
				</Col>
			</Row>

			<div className="light-card">
				<div className="separator"></div>
				<Comments
					postId={post.id}
					commentsCounter={post.comment_counter}
					userState={userState}
					seniorSelectedId={seniorSelectedId}
				/>
			</div>

			<div className="center">
				<Link to="/social">
					<button className="light-button mt-5">{t("common.__back__")}</button>
				</Link>
			</div>
			<div className="space"></div>
		</>
	) : (
		<NoDataAvailable />
	);
}
